import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp, MessageSquare } from "lucide-react";
import { RentContracts } from "../../../../../../Types";
import CommentsSection from "../../../../../UI/CommentsSection";

interface RentCommentsProps {
  RentContract: RentContracts | null;
}

const RentComments: React.FC<RentCommentsProps> = ({ RentContract }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full flex flex-col min-h-fit">
      <div className=" flex   justify-between   ">
        <div className="  flex items-center gap-4 ">
          <MessageSquare size={20} />
          <span className="overflow-hidden whitespace-nowrap text-ellipsis   flex-auto text-opacity-88 font-semibold text-[16px] leading-6  text-[#d35400]">
            {t("RENT_COMMENTS").toUpperCase()}
          </span>
        </div>

        {isOpen ? (
          <ChevronUp
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        ) : (
          <ChevronDown
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        )}
      </div>
      {isOpen ? (
        <div className="w-full overflow-scroll mt-6">
          <CommentsSection
            comments={RentContract?.comments || []}
            identifierValue={RentContract?.rentContractID || ""}
            identifier="RENT"
          />
        </div>
      ) : null}
    </div>
  );
};

export default RentComments;
