import React, { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Result,
  Row,
  Select,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { Axios } from "../../../../Config";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useGouvernoratsData } from "../../../../Hooks/useGouvernoratsData";

interface InitialValuesT {
  agenceName: string | null;
  agenceAddress: string | null;
  agenceCode: number | null;
  phone: string | null;
  email: string | null;
  latitude: number | null;
  longitude: number | null;
  city: number | null;
  region: number | null;
  postalCode: string | null;
}

export function AddAgenceContent() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);
  const { gouvernorats, isLoading } = useGouvernoratsData();

  const initialValues: InitialValuesT = {
    agenceName: null,
    agenceAddress: null,
    agenceCode: null,
    phone: null,
    email: null,
    latitude: null,
    longitude: null,
    city: null,
    region: null,
    postalCode: null,
  };

  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      setBtnIsLoading(true);
      const data = { ...values };
      await Axios.post("/enterprise/agence", data);
      formikHelpers.setValues(initialValues);
      setIsSuccess(true);
      setBtnIsLoading(false);
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  return (
    <div className="w-full overflow-scroll p-10">
      {!isSuccess ? (
        <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
          {({ values, submitForm, setFieldValue }) => {
            return (
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                disabled={false}
                initialValues={values}
                onValuesChange={(fieldKeyValue) => {
                  const fieldKey = Object.keys(fieldKeyValue)[0];
                  const fieldValue = fieldKeyValue[fieldKey];
                  setFieldValue(fieldKey, fieldValue);
                }}
              >
                <Row gutter={2}>
                  <Col span={12} xs={24} sm={12}>
                    <FormItem name="agenceName" label={t("agenceName")}>
                      <Input placeholder={t("agenceName")} />
                    </FormItem>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <FormItem name="agenceCode" label={t("agenceCode")}>
                      <InputNumber
                        controls={false}
                        min={0}
                        style={{ width: "100%" }}
                        placeholder={t("agenceCode")}
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={2}>
                  <Col span={12} xs={24} sm={12}>
                    <FormItem name="phone" label={t("phone")}>
                      <Input placeholder={t("phone")} />
                    </FormItem>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <FormItem name="email" label={t("auth_email")}>
                      <Input type="email" placeholder={t("auth_email")} />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={2}>
                  <Col span={12} xs={24} sm={12}>
                    <FormItem name="region" label={t("region")}>
                      <Select placeholder={t("region")} loading={isLoading}>
                        {gouvernorats.length &&
                          gouvernorats.map((gouvernorat) => {
                            return (
                              <Select.Option value={gouvernorat.gouvernoratID}>
                                {language === "ar"
                                  ? gouvernorat.govAr
                                  : gouvernorat.govFr}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </FormItem>
                  </Col>{" "}
                  <Col span={12} xs={24} sm={12}>
                    <FormItem name="city" label={t("city")}>
                      <Select disabled={!values.region}>
                        {values.region &&
                          gouvernorats
                            .filter((gouvernorat) => {
                              return (
                                gouvernorat.gouvernoratID === values.region
                              );
                            })[0]
                            .communes.map((commune) => {
                              return (
                                <Select.Option value={commune.communeID}>
                                  {language === "ar"
                                    ? commune.comAR
                                    : commune.comFr}
                                </Select.Option>
                              );
                            })}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={2}>
                  <Col span={12} xs={24} sm={12}>
                    <FormItem name="postalCode" label={t("postal_code")}>
                      <Input placeholder={t("postal_code")} />
                    </FormItem>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <FormItem name="agenceAddress" label={t("agenceAddress")}>
                      <Input placeholder={t("agenceAddress")} />
                    </FormItem>
                  </Col>
                </Row>
                <Divider />
                <Row gutter={2}>
                  <Col span={12} xs={24} sm={12}>
                    <FormItem name="latitude" label={t("latitude")}>
                      <Input
                        style={{ width: "100%" }}
                        placeholder={t("latitude")}
                      />
                    </FormItem>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <FormItem name="longitude" label={t("longitude")}>
                      <Input
                        style={{ width: "100%" }}
                        placeholder={t("longitude")}
                      />
                    </FormItem>
                  </Col>
                </Row>
                <div className="w-full flex justify-center">
                  <Button
                    type="primary"
                    className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
                    onClick={submitForm}
                    loading={isBtnLoading}
                  >
                    {t("add_agence").toUpperCase()}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Result
          status="success"
          title={t("agence_added_success")}
          extra={[
            <Button
              type="primary"
              className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
              onClick={() => setIsSuccess(false)}
            >
              {t("back")}
            </Button>,
          ]}
        />
      )}
    </div>
  );
}
