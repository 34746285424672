import React from "react";
import { Divider, Layout, Space, Typography } from "antd";

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

export function PrivacyPolicy() {
  return (
    <Layout>
      <Content style={{ padding: "0 50px", maxWidth: 800, margin: "0 auto" }}>
        <Space
          direction="vertical"
          size="large"
          style={{ width: "100%", padding: "24px 0" }}
        >
          <Title level={1}>LOCASAFE Privacy Policy</Title>
          <Paragraph>Last updated: {new Date().toLocaleDateString()}</Paragraph>

          <Paragraph>
            LocaSafe ("we", "our", or "us") is committed to protecting your
            privacy. This Privacy Policy explains how your personal information
            is collected, used, and disclosed by LocaSafe.
          </Paragraph>

          <Title level={2}>Information We Collect</Title>
          <Paragraph>
            We collect information that you provide directly to us, such as when
            you create an account, use our services, or communicate with us.
            This may include:
          </Paragraph>
          <ul>
            <li>Name and contact information</li>
            <li>Login credentials</li>
            <li>Payment information</li>
            <li>Information about your car rental agency</li>
            <li>Usage data and preferences within the LocaSafe app</li>
          </ul>

          <Title level={2}>How We Use Your Information</Title>
          <Paragraph>We use the information we collect to:</Paragraph>
          <ul>
            <li>Provide, maintain, and improve the LocaSafe ERP system</li>
            <li>Process transactions and send related information</li>
            <li>
              Send you technical notices, updates, security alerts, and support
              messages
            </li>
            <li>
              Respond to your comments, questions, and customer service requests
            </li>
            <li>Communicate with you about products, services, and events</li>
            <li>
              Monitor and analyze trends, usage, and activities in connection
              with our services
            </li>
          </ul>

          <Title level={2}>Sharing of Information</Title>
          <Paragraph>We may share your information with:</Paragraph>
          <ul>
            <li>Service providers who perform services on our behalf</li>
            <li>
              Professional advisors, such as lawyers, auditors, and insurers
            </li>
            <li>
              Regulatory authorities, government agencies, and law enforcement
            </li>
          </ul>

          <Title level={2}>Data Security</Title>
          <Paragraph>
            We implement appropriate technical and organizational measures to
            protect the security of your personal information. However, please
            note that no method of transmission over the Internet or electronic
            storage is 100% secure.
          </Paragraph>

          <Title level={2}>Your Rights</Title>
          <Paragraph>
            Depending on your location, you may have certain rights regarding
            your personal information, including:
          </Paragraph>
          <ul>
            <li>The right to access your personal information</li>
            <li>The right to rectify inaccurate personal information</li>
            <li>The right to request deletion of your personal information</li>
            <li>
              The right to restrict or object to our processing of your personal
              information
            </li>
          </ul>

          <Title level={2}>Changes to This Privacy Policy</Title>
          <Paragraph>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page
            and updating the "Last updated" date at the top of this Privacy
            Policy.
          </Paragraph>

          <Title level={2}>Contact Us</Title>
          <Paragraph>
            If you have any questions about this Privacy Policy, please contact
            us at:
          </Paragraph>
          <Paragraph>
            <Text strong>Email:</Text> privacy@locasafe.com
            <br />
            <Text strong>Address:</Text> 123 LocaSafe Street, City, State, ZIP
          </Paragraph>

          <Divider />

          <Paragraph type="secondary" style={{ textAlign: "center" }}>
            © {new Date().getFullYear()} LocaSafe. All rights reserved.
          </Paragraph>
        </Space>
      </Content>
    </Layout>
  );
}
