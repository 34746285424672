import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Popconfirm, QRCode } from "antd";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { Axios } from "../../../Config";

export const WTCPrintable = ({ wtc, showPrint = true, mutate }: any) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const print = useRef(null);

  const setWtcPrinted = async () => {
    try {
      if (!wtc.isPrinted) {
        await Axios.get(`/wtc/company/set-printed`, {
          params: { withholdingTaxCertificate: wtc.withholdingTaxCertificate },
        }).then(() => {
          if (mutate) {
            mutate();
          }
        });
      }
    } catch (e: any) {
      return;
    }
  };

  const handlePrint = useReactToPrint({
    content: () => print.current,
    onAfterPrint: () => setWtcPrinted(),
  });

  const payerIdentifier = wtc?.payerIdentifier.split("/");
  const beneficiaryIdentifier = wtc?.beneficiaryIdentifier.split("/");

  return (
    <div className="p-8 !bg-white rounded-xl overflow-x-scroll " dir="ltr">
      {showPrint && (
        <div
          className="flex gap-4 flex-col md:flex-row mb-7 "
          dir={language === "ar" ? "rtl" : "ltr"}
        >
          {!wtc.isPrinted ? (
            <Popconfirm
              title={t("print_WTC_confirm_title")}
              description={t("print_WTC_confirm_description")}
              placement="bottom"
              okText={t("print")}
              cancelText={t("cancel")}
              onConfirm={handlePrint}
              overlayClassName={`!max-w-[300px] ${language === "ar" ? "rtl" : "ltr"}`}
            >
              <Button
                type="primary"
                className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white"
              >
                {t("print_wtc").toUpperCase()}
              </Button>
            </Popconfirm>
          ) : (
            <Button
              type="primary"
              className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white"
              onClick={handlePrint}
            >
              {t("print_wtc").toUpperCase()}
            </Button>
          )}
        </div>
      )}

      <div
        className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10"
        dir="ltr"
      >
        <div ref={print} className="flex flex-col min-w-[900px] ">
          <section className="flex   flex-col p-12 bg-white w-full ">
            <header className="flex flex-col   px-9   text-black max-md:px-5  w-full">
              <div className="flex flex-wrap gap-5 justify-between text-center  ">
                <div className="text-lg leading-5">
                  REPUBLIQUE TUNISIENNE <br />
                  MINISTERE DES FINANCES <br />
                  <strong>DIRECTION GENERALE</strong> <br />
                  <strong>DU CONTROLE FISCAL</strong>
                </div>

                <h1 className="self-stretch h-auto text-lg font-bold leading-5">
                  CERTIFICAT DE RETENUE D'IMPOT <br />
                  SUR LE REVENU <br />
                  OU D'IMPOT SUR LES SOCIETES
                </h1>
              </div>

              <div className="flex gap-8 self-end mt-10  mr-20 text-sm justify-center items-center   ">
                <p className="text-right">
                  Retenue effectuée <br />
                  ou pendant (1)
                </p>

                <p className="   font-bold text-center">
                  {wtc.WithholdingTaxPeriodType === "DATE"
                    ? moment(wtc.WithholdingTaxPeriod).format("DD/MM/YYYY")
                    : null}
                  {wtc.WithholdingTaxPeriodType === "MONTH"
                    ? moment(wtc.WithholdingTaxPeriod)
                        .format("MMMM YYYY")
                        .toUpperCase()
                    : null}
                  {wtc.WithholdingTaxPeriodType === "YEAR"
                    ? moment(wtc.WithholdingTaxPeriod)
                        .format("YYYY")
                        .toUpperCase()
                    : null}
                </p>
              </div>
            </header>

            <section className="flex  mt-6 gap-10   font-bold rounded-none w-full    ">
              <h2 className="text-sm text-black p-4 w-4/5">
                A- PERSONNE OU ORGANISME PAYEUR
              </h2>

              <div className="flex flex-col items-center p-4      ">
                <h3 className="text-sm font-semibold text-gray-700 mb-2">
                  IDENTIFIANT
                </h3>
                <table className="w-full max-w-md table-fixed text-[12px] bg-white border-collapse rounded-lg overflow-hidden  ">
                  <thead className="bg-gray-200 text-gray-700">
                    <tr className="text-[12px]">
                      <th className="border w-[120px] px-3 py-1">
                        Matricule fiscal
                      </th>
                      <th className="border px-3 w-[60px] py-1">Code T.V.A</th>
                      <th className="border px-3 w-[97px] py-1">
                        Code Catégorie (2)
                      </th>
                      <th className="border px-3 w-[89px] py-1">
                        N° Etab. Secondaire
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 text-[12px]">
                    <tr className="odd:bg-gray-100 even:bg-gray-50 text-center">
                      <td className="border  px-3 py-2">
                        {payerIdentifier[0] || ""}
                      </td>
                      <td className="border px-3 py-2">
                        {payerIdentifier[1] || ""}
                      </td>
                      <td className="border px-3 py-2">
                        {payerIdentifier[2] || ""}
                      </td>
                      <td className="border px-3 py-2">
                        {payerIdentifier[3] || ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <div className="w-full flex flex-col text-sm my-6">
              <div className="w-full flex gap-4">
                <p className="w-1/2 text-right">
                  Dénomination de la personne ou de l’organisme payeur :
                </p>
                <p className="w-1/2 font-bold">{wtc.payerName}</p>
              </div>
              <div className="w-full flex gap-4">
                <p className="w-1/2 text-right"> Adresse : </p>
                <p className="w-1/2 font-bold">{wtc.payerAddress}</p>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full text-left text-sm font-light text-surface dark:text-white border-2  borde-[#bdc3c7] rounded-lg">
                      <thead className="border-b-2 borde-[#bdc3c7]  dark:border-white/10">
                        <tr className="bg-gray-200 text-gray-700">
                          <th
                            scope="col"
                            className="pl-6 py-2 border-r borde-[#bdc3c7] font-medium"
                          >
                            B- RETENUES EFFECTUEES SUR :
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-2 border-r borde-[#bdc3c7] text-center"
                          >
                            MONTANT BRUT
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-2 border-r borde-[#bdc3c7]  text-center"
                          >
                            RETENUE
                          </th>
                          <th scope="col" className="px-6 py-2  text-center">
                            MONTANT NET
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-b borde-[#bdc3c7] text-[12px]">
                          <th
                            scope="col"
                            className="px-6 py-2 border-r borde-[#bdc3c7] font-normal"
                          >
                            Honoraires (Personnes soumises au régime réel) 3%
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-2 border-r borde-[#bdc3c7]  text-center "
                          >
                            {wtc.withholdingTaxPercentage === 3
                              ? Number(wtc.grossAmount).toFixed(3) + " TND"
                              : "-"}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-2 border-r borde-[#bdc3c7]  text-center"
                          >
                            {wtc.withholdingTaxPercentage === 3
                              ? Number(wtc.withholdingAmount).toFixed(3) +
                                " TND"
                              : "-"}
                          </th>
                          <th scope="col" className="px-6 py-2  text-center">
                            {wtc.withholdingTaxPercentage === 3
                              ? Number(wtc.payableAmount).toFixed(3) + " TND"
                              : "-"}
                          </th>
                        </tr>
                        <tr className="border-b borde-[#bdc3c7] text-[12px]">
                          <th
                            scope="col"
                            className="px-6 py-2 border-r borde-[#bdc3c7] font-normal"
                          >
                            Retenue à la source au titre des paiements {">"}
                            1000DT{" "}
                            {wtc.withholdingTaxPercentage === 0.5
                              ? "- taux spécial"
                              : null}
                            {wtc.withholdingTaxPercentage === 1 ||
                            wtc.withholdingTaxPercentage === 0.5
                              ? wtc.withholdingTaxPercentage + "%"
                              : "1%"}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-2 border-r borde-[#bdc3c7]  text-center "
                          >
                            {wtc.withholdingTaxPercentage === 1 ||
                            wtc.withholdingTaxPercentage === 0.5
                              ? Number(wtc.grossAmount).toFixed(3) + " TND"
                              : "-"}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-2 border-r borde-[#bdc3c7]  text-center"
                          >
                            {wtc.withholdingTaxPercentage === 1 ||
                            wtc.withholdingTaxPercentage === 0.5
                              ? Number(wtc.withholdingAmount).toFixed(3) +
                                " TND"
                              : "-"}
                          </th>
                          <th scope="col" className="px-6 py-2  text-center">
                            {wtc.withholdingTaxPercentage === 1 ||
                            wtc.withholdingTaxPercentage === 0.5
                              ? Number(wtc.payableAmount).toFixed(3) + " TND"
                              : "-"}
                          </th>
                        </tr>
                        {wtc.withholdingTaxPercentage === 15 ||
                        Boolean(
                          wtc.withholdingTaxPercentage === 1 ||
                            wtc.withholdingTaxPercentage === 0.5 ||
                            wtc.withholdingTaxPercentage === 3,
                        ) ? (
                          <tr className="border-b borde-[#bdc3c7] text-[12px]">
                            <th
                              scope="col"
                              className="px-6 py-2 border-r borde-[#bdc3c7] font-normal"
                            >
                              {" "}
                              Honoraires, commissions et loyers 15%
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-2 border-r borde-[#bdc3c7]  text-center "
                            >
                              {wtc.withholdingTaxPercentage === 15
                                ? Number(wtc.grossAmount).toFixed(3) + " TND"
                                : "-"}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-2 border-r borde-[#bdc3c7]  text-center"
                            >
                              {wtc.withholdingTaxPercentage === 15
                                ? Number(wtc.withholdingAmount).toFixed(3) +
                                  " TND"
                                : "-"}
                            </th>
                            <th scope="col" className="px-6 py-2  text-center">
                              {wtc.withholdingTaxPercentage === 15
                                ? Number(wtc.payableAmount).toFixed(3) + " TND"
                                : "-"}
                            </th>
                          </tr>
                        ) : (
                          <tr className="border-b borde-[#bdc3c7] text-[12px]">
                            <th
                              scope="col"
                              className="px-6 py-2 border-r borde-[#bdc3c7] font-normal"
                            >
                              {t("wtcs") +
                                " " +
                                wtc.withholdingTaxPercentage +
                                "%"}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-2 border-r borde-[#bdc3c7]  text-center "
                            >
                              {Number(wtc.grossAmount).toFixed(3) + " TND"}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-2 border-r borde-[#bdc3c7]  text-center"
                            >
                              {Number(wtc.withholdingAmount).toFixed(3) +
                                " TND"}
                            </th>
                            <th scope="col" className="px-6 py-2  text-center">
                              {Number(wtc.payableAmount).toFixed(3) + " TND"}
                            </th>
                          </tr>
                        )}

                        <tr className="border-t-2 borde-[#bdc3c7] ">
                          <th
                            scope="col"
                            className="px-6 py-2 border-r borde-[#bdc3c7]  "
                          >
                            TOTAL GENERAL
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-2 border-r borde-[#bdc3c7]  text-center"
                          >
                            {Number(wtc.grossAmount).toFixed(3) + " TND"}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-2 border-r borde-[#bdc3c7]  text-center"
                          >
                            {Number(wtc.withholdingAmount).toFixed(3) + " TND"}
                          </th>
                          <th scope="col" className="px-6 py-2  text-center">
                            {Number(wtc.payableAmount).toFixed(3) + " TND"}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <section className="flex  mt-2 gap-10   font-bold rounded-none w-full    ">
              <div className=" p-4 w-4/5 flex flex-col">
                <h2 className="text-sm text-black mb-2">C - BENEFICIAIRE</h2>
                <table className="w-[260px] max-w-md table-fixed text-[10px] bg-white border-collapse rounded-lg overflow-hidden ml-6 ">
                  <thead className="bg-gray-200 text-gray-700">
                    <tr className="text-[10px]">
                      <th className="border  px-3 py-1">
                        N° de la carte d’identité <br />
                        Ou de séjour pour les étrangers
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 text-[10px] ">
                    <tr className="  text-center ">
                      <td className="border  px-3 py-4"> -</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="flex flex-col items-center p-4      ">
                <h3 className="text-sm font-semibold text-gray-700 mb-2">
                  IDENTIFIANT
                </h3>
                <table className="w-full max-w-md table-fixed text-[12px] bg-white border-collapse rounded-lg overflow-hidden  ">
                  <thead className="bg-gray-200 text-gray-700">
                    <tr className="text-[12px]">
                      <th className="border w-[120px] px-3 py-1">
                        Matricule fiscal
                      </th>
                      <th className="border px-3 w-[60px] py-1">Code T.V.A</th>
                      <th className="border px-3 w-[97px] py-1">
                        Code Catégorie (2)
                      </th>
                      <th className="border px-3 w-[89px] py-1">
                        N° Etab. Secondaire
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 text-[12px]">
                    <tr className="odd:bg-gray-100 even:bg-gray-50 text-center">
                      <td className="border  px-3 py-2">
                        {beneficiaryIdentifier[0] || ""}
                      </td>
                      <td className="border px-3 py-2">
                        {beneficiaryIdentifier[1] || ""}
                      </td>
                      <td className="border px-3 py-2">
                        {beneficiaryIdentifier[2] || ""}
                      </td>
                      <td className="border px-3 py-2">
                        {beneficiaryIdentifier[3] || ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>

            <div className="w-full flex flex-col text-sm m-6">
              <div className="w-full flex gap-4">
                <p className="w-1/3 text-right">
                  Nom et Prénom ou raison sociale :
                </p>
                <p className="w-2/3 font-bold">{wtc.beneficiaryName}</p>
              </div>
              <div className="w-full flex gap-4">
                <p className="w-1/3 text-right">Adresse professionnelle :</p>
                <p className="w-2/3 font-bold">{wtc.beneficiaryAddress}</p>
              </div>
              <div className="w-full flex gap-4 mt-4">
                <p className="w-1/3 text-right">Objet de retenue :</p>
                <p className="w-2/3 font-bold">
                  {t(wtc.withholdingType)} : {wtc.withholdingObject}
                </p>
              </div>
            </div>
            <section className="flex flex-col items-center text-xs text-center text-black rounded-none w-full ">
              <p className="z-10 self-stretch w-full">
                Je soussigné, certifie exacts les renseignements figurant sur le
                présent certificat
                <br />
                et m'expose aux sanctions prévues par la loi pour toutes
                inexactitude.
              </p>

              <p className="mt-1.5 font-medium">
                Tunis, le {moment(wtc.createdAt).format("DD/MM/YYYY")}
              </p>

              <p className="mt-2  font-bold">Cachet et signature du payeur</p>
            </section>
            <div className="w-full flex ml-5 mb-auto">
              <QRCode
                value={wtc.withholdingTaxCertificate}
                bordered={false}
                size={60}
              />
            </div>
            <footer className="self-stretch pr-9 mt-12 ml-5 text-[9px] text-black   max-md:max-w-full">
              1) Le certificat est délivré à l'occasion de chaque paiement.
              Toutefois, pour des opérations répétitives, le certificat peut
              être délivré trimestriellement. <br />
              (2) Code catégorie : M. personnes morales - C. personnes
              physiques, industrie et commerce - P. professions libérales - N.
              employeurs non soumis à l'impôt sur le revenu ou sur les sociétés
              (administrations et établissements publics) - E. établissements
              secondaires.
            </footer>
          </section>
        </div>
      </div>
    </div>
  );
};
