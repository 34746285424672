import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./calander.css";
import moment from "moment";
import i18n from "i18next";
import ar_EG from "rsuite/locales/ar_EG";
import en_US from "rsuite/locales/en_US";
import fr_FR from "rsuite/locales/fr_FR";
import { Calendar, CustomProvider } from "rsuite";
import { useState } from "react";
import { Modal, Table } from "antd";
import { RootReducer } from "../../../../Store/reducers";
import { Users } from "../../../../Types";
import { useCompanyRentContractsData } from "../../../../Hooks/useCompanyRentContractsData";

export const CompanyDashboardCalender = () => {
  const { t } = useTranslation();
  const { language } = i18n;

  const user = useSelector(
    (state: RootReducer) => state?.authentication?.user,
  ) as Users;

  const { contracts } = useCompanyRentContractsData(
    1000,
    user.accountType === "ENTREPRISE",
  );

  function getTodoList(date: any) {
    const day = moment(date);
    return contracts
      .filter(
        (rent) =>
          moment(day).diff(moment(rent.startDate), "days") > 0 &&
          moment(day).diff(moment(rent.endDate), "days") < 0,
      )
      .map((rent) => {
        return {
          label: (
            <p
              dir={language === "ar" ? "rtl" : "ltr"}
              className="flex justify-center items-center"
            >
              {rent.rentedCar.registrationNumber.replace("/", ` ${t("TU")} `)}
            </p>
          ),
          startDate: rent.startDate,
          endDate: rent.endDate,
          client: rent.client.fullName || rent.client.denomination,
        };
      });
  }

  function RenderCell(date: any) {
    const [isOpen, setIsOpen] = useState(false);

    const list = getTodoList(date);

    if (list.length) {
      const moreCount = list.length;
      const moreItem = (
        <li>
          <Modal
            open={isOpen}
            closable={true}
            onCancel={() => setIsOpen(false)}
            footer={null}
            className=" !w-2/3"
          >
            <Table
              rootClassName=" p-4"
              columns={[
                {
                  title: t("car_number"),
                  dataIndex: "label",
                  key: "label",
                },
                {
                  title: t("start_date"),
                  dataIndex: "startDate",
                  key: "startDate",
                  render: (value) => moment(value).format("DD/MM/YYYY HH:MM"),
                },
                {
                  title: t("end_date"),
                  dataIndex: "endDate",
                  key: "endDate",
                  render: (value) => moment(value).format("DD/MM/YYYY HH:MM"),
                },
                {
                  title: t("client_name"),
                  dataIndex: "client",
                  key: "client",
                },
              ]}
              dataSource={list}
            />
          </Modal>

          <p onClick={() => setIsOpen(true)}>{moreCount} more</p>
        </li>
      );

      return (
        <ul className="calendar-todo-list">{moreCount ? moreItem : null}</ul>
      );
    }

    return null;
  }

  return (
    <div className=" w-full flex  h-fit bg-white rounded-2xl   ">
      <CustomProvider
        locale={language === "ar" ? ar_EG : language === "fr" ? fr_FR : en_US}
      >
        <Calendar bordered renderCell={RenderCell} compact />
      </CustomProvider>
    </div>
  );
};
