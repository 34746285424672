import { ColumnGroupType } from "antd/es/table";
import { Insurances } from "../../../../../../Types";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { Tag } from "antd";

export const FleetInsuranceColumns: (
  t: any,
) => (ColumnGroupType<Insurances> | ColumnType<Insurances>)[] = (t) => {
  return [
    {
      title: t("num_contract"),
      dataIndex: "num_contract",
      key: "num_contract",
      align: "center",
    },
    {
      title: t("num_policy"),
      dataIndex: "num_policy",
      key: "num_policy",
      align: "center",
    },
    {
      title: t("valid_from"),
      dataIndex: "valid_from",
      key: "valid_from",
      align: "center",
      render: (date: Date) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: t("valid_to"),
      dataIndex: "valid_to",
      key: "valid_to",
      align: "center",
      render: (date: Date) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: t("insurer_name"),
      dataIndex: "insurer_name",
      key: "insurer_name",
      align: "center",
    },
    {
      title: t("status"),
      dataIndex: "assuranceID",
      key: "status",
      align: "center",
      render: (id: string, record: Insurances) => {
        const { contract_cancelled, valid_to, valid_from } = record;
        const today = moment();

        if (contract_cancelled) {
          return (
            <div className="w-full flex justify-center items-center">
              <Tag color="red">{t("CANCELLED").toUpperCase()}</Tag>
            </div>
          );
        } else if (moment(valid_to).isBefore(today)) {
          return (
            <div className="w-full flex justify-center items-center">
              <Tag color="yellow">{t("EXPIRED").toUpperCase()}</Tag>
            </div>
          );
        } else if (
          moment(valid_from).isBefore(today) &&
          moment(valid_to).isAfter(today)
        ) {
          return (
            <div className="w-full flex justify-center items-center">
              <Tag color="green">{t("ACTIVE").toUpperCase()}</Tag>
            </div>
          );
        } else if (moment(valid_from).isAfter(today)) {
          return (
            <div className="w-full flex justify-center items-center">
              <Tag color="blue">{t("PLANNED").toUpperCase()}</Tag>
            </div>
          );
        }
      },
    },
  ];
};
