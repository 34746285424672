import React, { ChangeEvent, useMemo, useRef } from "react";
import { Axios } from "../../../../Config";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Badge, Image } from "antd";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { useMyCompanyData } from "../../../../Hooks";
import { useSelector } from "react-redux";

export function CompanySideMenueContent() {
  const { company, mutate } = useMyCompanyData();
  const { t } = useTranslation();
  const logoInput = useRef<HTMLInputElement>(null);

  const user = useSelector((state: any) => state.authentication.user);

  const companyLogo = useMemo(
    () =>
      (company?.logo &&
        (process.env.REACT_APP_API_ENDPOINT_BASE_URL as string) +
          (process.env.REACT_APP_API_ENDPOINT_PREFIX as string) +
          "/media/" +
          company.logo.name) ||
      "",
    [company?.logo],
  );

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      let logoFile = new FormData();
      if (e.target?.files) {
        logoFile.append("logo", e.target?.files?.[0]);
        const avatarRes = await Axios.put("/enterprise/logo", logoFile, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        mutate().then(() => {
          toast.success(t(avatarRes.data.logo.message["RESPONSE_CODE"]));
        });
      }
    } catch (e: any) {
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  return (
    <div className="  flex-none w-full md:w-1/3    p-4">
      <input
        type="file"
        className="hidden"
        onChange={handleChange}
        ref={logoInput}
        accept="image/*"
      />
      <div className="w-full my-10   md:mt-0 flex justify-center items-center">
        {user.AgencesUsers.length ? (
          <Image
            preview={false}
            width={250}
            src={companyLogo}
            crossOrigin="anonymous"
          />
        ) : (
          <Badge
            count={
              <ChangeCircleIcon
                style={{ color: "#1E232C" }}
                fontSize="large"
                className="cursor-pointer"
                onClick={() => logoInput?.current?.click()}
              />
            }
          >
            <Image
              preview={false}
              width={250}
              src={companyLogo}
              crossOrigin="anonymous"
            />
          </Badge>
        )}
      </div>
    </div>
  );
}
