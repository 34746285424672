import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Button,
  DatePicker,
  Descriptions,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Spin,
} from "antd";
import Search from "antd/es/input/Search";
import axios from "axios";
import FormItem from "antd/es/form/FormItem";
import { enumsToSelectOptions } from "../../../../../Utils";
import { useEnumsData } from "../../../../../Hooks";
import { useMediaQuery } from "@mui/material";
import moment from "moment";
import { Axios } from "../../../../../Config";
import dayjs from "dayjs";
import { WTCPrintable } from "../../../../UI/Printables/WTCPrintable";

export function AddNewWtcContent() {
  const { t } = useTranslation();
  const [rneSearch, setRneSearch] = useState<any | null>(null);
  const [type, setType] = useState<string>("pm");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);

  const wtcContentInitialValues = {
    WithholdingTaxPeriodType: null,
    WithholdingTaxPeriod: null,
    withholdingTaxPercentage: null,
    grossAmount: null,
    beneficiaryAddress: null,
    withholdingObject: null,
    withholdingType: null,
  };

  const [wtcContent, setWtcContent] = useState(wtcContentInitialValues);
  const [wtc, setWtc] = useState<any>();

  const { enums, isLoading: isEnumsLoading } = useEnumsData();

  const searchCompanyHandler = async (mf: string) => {
    try {
      if (mf === "") {
        setRneSearch(null);
        return;
      }

      setIsLoading(true);
      setRneSearch(null);
      const rneResult = await axios
        .get(`https://api-tej.finances.gov.tn/v0/tax_file/infos?identify=${mf}`)
        .then((res) => res.data);

      if (rneResult?.dossier.length === 0) {
        setRneSearch([]);
      } else {
        setRneSearch(rneResult?.dossier[0]);
      }
      setIsLoading(false);
    } catch (e: any) {
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
      setIsLoading(false);
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const withholdingTaxPercentage = [
    { label: `${t("wtcs").toUpperCase()} 0,5%`, value: "0.5" },
    { label: `${t("wtcs").toUpperCase()} 1%`, value: "1" },
    { label: `${t("wtcs").toUpperCase()} 1,5%`, value: "1.5" },
    { label: `${t("wtcs").toUpperCase()} 2,5%`, value: "2.5" },
    { label: `${t("wtcs").toUpperCase()} 3%`, value: "3" },
    { label: `${t("wtcs").toUpperCase()} 5%`, value: "5" },
    { label: `${t("wtcs").toUpperCase()} 10%`, value: "10" },
    { label: `${t("wtcs").toUpperCase()} 15%`, value: "15" },
    { label: `${t("wtcs").toUpperCase()} 25%`, value: "25" },
  ];
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const handleSetFormData = (key: string, value: any) => {
    setWtcContent((prevState) => {
      return { ...prevState, [key]: value };
    });
  };
  const asyncToast = useRef<any>();

  const handleCreateWtc = async () => {
    try {
      setBtnIsLoading(true);
      asyncToast.current = toast.loading(t("CREATE_WTC_ONGOING_MESSAGE"));

      const data = {
        ...wtcContent,
        withholdingTaxPercentage: Number(wtcContent.withholdingTaxPercentage),
        beneficiaryIdentifier: `${rneSearch.matfis}/${rneSearch.tvacode}/${rneSearch.categ}/000`,
        beneficiaryType: type.toUpperCase(),
        beneficiaryName: rneSearch.raison,
      };

      const savedWTC = await Axios.post("/wtc/", data).then(
        (res) => res.data.message,
      );

      setWtc(savedWTC);
      toast.update(asyncToast.current, {
        render: t("CREATE_WTC_SUCCESS_MESSAGE"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      setWtcContent(wtcContentInitialValues);
      setBtnIsLoading(false);
    } catch (e: any) {
      setBtnIsLoading(false);

      toast.update(asyncToast.current, {
        render: t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  return (
    <div className="w-full flex flex-col items-center  ">
      {wtc ? (
        <Modal
          open={wtc}
          width={isSmallDevice ? "100% " : "80%"}
          onClose={() => setWtc(null)}
          onCancel={() => setWtc(null)}
          footer={null}
        >
          <WTCPrintable wtc={wtc} />
        </Modal>
      ) : null}
      <div className="mb-8">
        <Radio.Group
          defaultValue={type}
          buttonStyle="outline"
          onChange={(value) => {
            setType(value.target.value);
          }}
        >
          <Radio.Button value="pm">{t("pm")}</Radio.Button>
          <Radio.Button value="pp">{t("pp")}</Radio.Button>
        </Radio.Group>
      </div>
      <div className="w-full md:w-1/2  flex flex-col md:flex-row items-center justify-evenly mb-8">
        <Search
          placeholder={`${t("matricule_fiscal")} (0000000X)`}
          allowClear
          onSearch={(mf) => searchCompanyHandler(mf)}
          style={{ width: 304 }}
        />
      </div>

      {isLoading ? <Spin className="my-16" /> : null}

      {Array.isArray(rneSearch) && !rneSearch.length ? (
        <Empty description={t("no_data")} />
      ) : null}

      {rneSearch && (
        <div>
          <Descriptions
            bordered
            rootClassName="!mb-6"
            size="small"
            column={2}
            layout={isSmallDevice ? "vertical" : "horizontal"}
            title=" BENEFICIAIRE"
          >
            <Descriptions.Item label={t("nomCommercialFr")}>
              {rneSearch.raison}
            </Descriptions.Item>
            <Descriptions.Item label={t("VAT")}>
              {`${rneSearch.matfis}/${rneSearch.tvacode}/${rneSearch.categ}/000`}
            </Descriptions.Item>
            <Descriptions.Item label={t("categorie")}>
              {rneSearch.activitep}
            </Descriptions.Item>
          </Descriptions>
          <div className="flex-col gap-6 w-full">
            <div className="flex justify-between flex-wrap gap-6">
              <Form.Item label={t("beneficiaryAddress")} rootClassName="w-full">
                <Input
                  value={wtcContent.beneficiaryAddress}
                  placeholder={t("beneficiaryAddress")}
                  onChange={(e) => {
                    handleSetFormData("beneficiaryAddress", e.target.value);
                  }}
                />
              </Form.Item>
            </div>
            <div className="flex flex-col  w-full justify-between   gap-2">
              <FormItem
                label={t("WithholdingTaxPeriodType")}
                rootClassName="w-full "
              >
                <Select
                  showSearch
                  placeholder={t("WithholdingTaxPeriodType")}
                  optionFilterProp="children"
                  value={wtcContent.WithholdingTaxPeriodType}
                  filterOption={filterOption}
                  style={{ width: "100%" }}
                  loading={isEnumsLoading}
                  onChange={(value) => {
                    handleSetFormData("WithholdingTaxPeriodType", value);
                  }}
                  options={enumsToSelectOptions(
                    enums?.["WithholdingTaxPeriodType"],
                  )}
                />
              </FormItem>

              <Form.Item label={t("date_of_invoice")} rootClassName="flex-1">
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                  value={
                    wtcContent.WithholdingTaxPeriod
                      ? dayjs(wtcContent.WithholdingTaxPeriod)
                      : null
                  }
                  onChange={(date) => {
                    handleSetFormData(
                      "WithholdingTaxPeriod",
                      date
                        ? moment(date.toDate(), "DD/MM/YYYY").toISOString()
                        : null,
                    );
                  }}
                />
              </Form.Item>
            </div>
            <Divider />
            <div className="flex flex-col w-full justify-between   gap-2">
              <FormItem label={t("WithholdingType")} rootClassName="w-full">
                <Select
                  showSearch
                  placeholder={t("WithholdingType")}
                  optionFilterProp="children"
                  value={wtcContent.withholdingType}
                  filterOption={filterOption}
                  style={{ width: "100%" }}
                  loading={isEnumsLoading}
                  onChange={(value) => {
                    handleSetFormData("withholdingType", value);
                  }}
                  options={enumsToSelectOptions(enums?.["WithholdingType"])}
                />
              </FormItem>

              <Form.Item label={t("withholdingObject")} rootClassName="flex-1">
                <Input
                  value={wtcContent.withholdingObject}
                  placeholder={t("withholdingObject")}
                  onChange={(e) => {
                    handleSetFormData("withholdingObject", e.target.value);
                  }}
                />
              </Form.Item>
            </div>
            <Divider />
            <div className="flex flex-col w-full justify-between   gap-2">
              <FormItem label={t("type")} rootClassName="w-full">
                <Select
                  showSearch
                  placeholder={t("type")}
                  optionFilterProp="children"
                  value={wtcContent.withholdingTaxPercentage}
                  filterOption={filterOption}
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    handleSetFormData("withholdingTaxPercentage", value);
                  }}
                  loading={isEnumsLoading}
                  options={withholdingTaxPercentage}
                />
              </FormItem>

              <Form.Item label={t("grossAmount")} rootClassName="w-full">
                <InputNumber
                  value={wtcContent.grossAmount}
                  controls={false}
                  addonAfter={t("TND")}
                  decimalSeparator={","}
                  onChange={(value) => {
                    handleSetFormData("grossAmount", value);
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      )}

      <Button
        type="primary"
        className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4"
        disabled={!Object.values(wtcContent).every((value) => value)}
        loading={isBtnLoading}
        onClick={() => handleCreateWtc()}
      >
        {t("add").toUpperCase()}
      </Button>
    </div>
  );
}
