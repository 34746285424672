import React, { useMemo, useState } from "react";
import { Comments } from "../../Types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Avatar, Empty, Spin } from "antd";
import { Axios } from "../../Config";
import { mutate } from "swr";
import { toast } from "react-toastify";

type Identifiers = "CLAIM" | "FLEET" | "CLIENT" | "RESERVATION" | "RENT";

function CommentsSection({
  comments,
  identifier,
  identifierValue,
}: {
  comments: Comments[];
  identifier: Identifiers;
  identifierValue: string;
}) {
  const { t } = useTranslation();

  const identifiersList = {
    CLAIM: {
      target: "insuranceClaimes",
      identifier: "insuranceClaimeID",
      mutate: "/enterprise/fleet",
    },
    FLEET: {
      target: "Fleet",
      identifier: "fleetID",
      mutate: "/enterprise/fleet",
    },
    CLIENT: {
      target: "Clients",
      identifier: "clientID",
      mutate: "/clients",
    },
    RESERVATION: {
      target: "RentReservation",
      identifier: "RentReservationId",
      mutate: "/reservation",
    },
    RENT: {
      target: "RentContracts",
      identifier: "rentContractID",
      mutate: "/rent/contracts",
    },
  };

  const currentIdentifier = useMemo(() => {
    return identifiersList[identifier];
  }, [identifier]);

  const [btnIsLoading, setBtnIsLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");

  const addComment = async (e: any) => {
    try {
      e.preventDefault();
      setBtnIsLoading(true);
      const data = {
        ...currentIdentifier,
        mutate: undefined,
        value: identifierValue,
        content: comment,
      };

      await Axios.post("/comments", data);
      await mutate(currentIdentifier.mutate).then(() => {
        setComment("");
        setBtnIsLoading(false);
      });
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };
  return (
    <div className="mx-auto  w-full antialiased">
      <div className="mx-auto max-w-2xl px-4">
        <div className="mb-6 flex items-center justify-between">
          <h2 className="text-sm font-bold text-gray-900 ">
            {t("discussion")} ({comments.length})
          </h2>
        </div>
        <form className="mb-6" onSubmit={addComment}>
          <div className="mb-4 rounded-xl   border border-gray-200 bg-white px-4 py-2">
            <label htmlFor="comment" className="sr-only">
              {t("your_comment")}
            </label>
            <textarea
              id="comment"
              disabled={btnIsLoading}
              rows={2}
              value={comment}
              className="w-full border-0 px-0 text-sm focus:outline-none focus:ring-0"
              placeholder={t("write_a_comment")}
              onChange={(event) => {
                setComment(event.target.value);
              }}
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800   items-center rounded-xl bg-slate-600 px-4 py-2.5 text-center text-xs font-medium text-white focus:ring-4 flex gap-4"
          >
            {btnIsLoading ? <Spin size="small" className="text-white" /> : null}
            <span>{t("post_comment")}</span>
          </button>
        </form>
        <div className="w-full max-h-[400px] overflow-auto flex flex-col gap-4">
          {comments.length ? (
            comments.map((comment, index) => {
              return (
                <div className="space-y-4" key={index}>
                  <div className="flex">
                    <div className="flex-1 rounded-xl border border-gray-200 px-4 py-2 leading-relaxed sm:px-6 sm:py-4">
                      <div className="mb-4 mr-3 flex flex-shrink-0 items-end gap-4">
                        {comment.createdBy.avatar ? (
                          <Avatar
                            size="default"
                            crossOrigin="anonymous"
                            src={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/${comment.createdBy.avatar}`}
                          />
                        ) : (
                          <Avatar>
                            {comment.createdBy.name[0] +
                              comment.createdBy.lastName[0]}
                          </Avatar>
                        )}

                        <div className="flex flex-col text-sm">
                          <strong>
                            {comment.createdBy.name}{" "}
                            {comment.createdBy.lastName.toUpperCase()}
                          </strong>{" "}
                          <span className="text-xs text-gray-400 text-justify">
                            {moment(comment.createdAt).format(
                              "DD/MM/YYYY HH:mm",
                            )}
                          </span>
                        </div>
                      </div>
                      <p className="text-sm">{comment.content}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <Empty description={t("no_comment_available")} />
          )}
        </div>
      </div>
    </div>
  );
}

export default CommentsSection;
