import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import { Agences } from "../../../../../Types";

export const AgencesListingColumns: (
  t: any,
  language: any,
) => (ColumnGroupType<Agences> | ColumnType<Agences>)[] = (t, language) => [
  {
    title: t("agenceCode"),
    dataIndex: "agenceCode",
    key: "agenceCode",
    align: "center",
  },
  {
    title: t("agenceName"),
    dataIndex: "agenceName",
    key: "agenceName",
    align: "center",
  },
  {
    title: t("agenceAddress"),
    dataIndex: "agenceAddress",
    key: "agenceAddress",
    align: "center",
  },

  {
    title: t("region"),
    dataIndex: "region",
    key: "region",
    align: "center",
    render: (value) => (language === "ar" ? value.govAr : value.govFr),
  },
  {
    title: t("city"),
    dataIndex: "city",
    key: "city",
    align: "center",
    render: (value) => (language === "ar" ? value.comAr : value.comFr),
  },
  {
    title: t("phone"),
    dataIndex: "phone",
    align: "center",
    key: "phone",
  },
  {
    title: t("auth_email"),
    dataIndex: "Email",
    align: "center",
    key: "Email",
  },
  {
    title: t("rent_contracts"),
    dataIndex: "RentContract",
    align: "center",
    key: "RentContract",
    render: (value) => value.length,
  },
  {
    title: t("invoices"),
    dataIndex: "Invoices",
    align: "center",
    key: "Invoices",
    render: (value) => value.length,
  },
  {
    title: t("bills"),
    dataIndex: "Bills",
    align: "center",
    key: "Bills",
    render: (value) => value.length,
  },
];
