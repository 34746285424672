import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Table } from "antd";
import { RentInitialValues } from "../index";
import { PlusCircleOutlined } from "@ant-design/icons";
import CautionAddModalContent from "./CautionAddModalContent";
import { CautionListingColumns } from "./CautionListingColumns";
import { useFormikContext } from "formik";

function CautionContent() {
  const { t } = useTranslation();
  const {
    values: { caution },
    setFieldValue,
  } = useFormikContext<RentInitialValues>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const columns = CautionListingColumns(t);

  const Header = () => {
    return (
      <div className="w-full  flex justify-between items-center px-4">
        <span className="font-bold">
          {t("caution_management").toUpperCase()}
        </span>
        <PlusCircleOutlined
          className="cursor-pointer"
          onClick={() => setIsModalOpen(true)}
        />
      </div>
    );
  };

  const closeModalHandler = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {isModalOpen ? (
        <Modal
          footer={null}
          open={isModalOpen}
          onClose={closeModalHandler}
          onCancel={closeModalHandler}
          width={400}
        >
          <CautionAddModalContent
            caution={caution}
            setFieldValue={setFieldValue}
            closeModalHandler={closeModalHandler}
          />
        </Modal>
      ) : null}
      <div>
        <Table
          key="RentContractCautionID"
          size="small"
          columns={columns}
          dataSource={caution || []}
          bordered={false}
          title={Header}
          pagination={false}
          scroll={{ x: 500 }}
        />
      </div>
    </>
  );
}

export default CautionContent;
