import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import { Bills } from "../../../../../Types";
import { Tag } from "antd";
import moment from "moment";
import CopyToClipboard from "../../../../UI/CopyToClipboard";

export const CompanyBillsColumns: (
  t: any,
) => (ColumnGroupType<Bills> | ColumnType<Bills>)[] = (t) => {
  return [
    {
      title: t("date_of_creations"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => moment(value).format("DD/MM/YYYY HH:MM"),
    },
    {
      title: t("copy_id"),
      dataIndex: "billID",
      key: "billID",
      render: (value) => (
        <div className="flex justify-center items-center">
          <CopyToClipboard input={value} showInput={false} showIcon={true} />{" "}
        </div>
      ),
    },
    {
      title: t("reference"),
      dataIndex: "reference",
      key: "reference",
      render: (value) => (
        <div className="flex justify-center items-center">
          <CopyToClipboard input={value} showIcon={true} />
        </div>
      ),
    },
    {
      title: t("amount"),
      dataIndex: "amount",
      key: "amount",
      render: (value) => `${value} ${t("TND")}`,
    },

    {
      title: t("dueDate"),
      dataIndex: "dueDate",
      key: "dueDate",
      render: (value) => moment(value).format("DD/MM/YYYY"),
    },
    {
      title: t("client_name"),
      dataIndex: "client",
      key: "rsLatin",
      render: (value) =>
        value?.fullName?.toUpperCase() || value?.denomination?.toUpperCase(),
    },
    {
      title: t("matricule_fiscal"),
      dataIndex: "client",
      key: "VAT",
      render: (value) => value.vatNumber || value.idNumber,
    },
    {
      title: t("comment"),
      dataIndex: "note",
      key: "note",
    },
    {
      title: t("created_by"),
      dataIndex: "createdBy",
      key: "createdBy",
      render: (value) => `${value.name} ${value.lastName}`,
    },
    {
      title: t("agenceName"),
      dataIndex: "agence",
      key: "agenceName",
      render: (value) => {
        return value ? value.agenceName : "-";
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (value) => {
        return value === "PENDING" ? (
          <Tag color="orange">{t("PENDING")}</Tag>
        ) : value === "VALIDATED" ? (
          <Tag color="green">{t("VALIDATED")}</Tag>
        ) : value === "REFUSED" ? (
          <Tag color="red">{t("REFUSED")}</Tag>
        ) : (
          <Tag color="purple">{t("ON_HOLD")}</Tag>
        );
      },
    },
  ];
};
