import React from "react";
import { useTranslation } from "react-i18next";
import { Descriptions, DescriptionsProps, Rate } from "antd";
import { useMediaQuery } from "@mui/material";
import { RentContracts } from "../../../../../../Types";
import { IterationCw } from "lucide-react";

interface RentReturnInfosProps {
  RentContract: RentContracts;
}

const RentReturnInfos: React.FC<RentReturnInfosProps> = ({ RentContract }) => {
  const { t } = useTranslation();

  const { returnedBy, returnLocation, rate, commentOnReturn } = RentContract;
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: `${t("return_location")}`,
      children: returnLocation,
      span: 4,
    },
    {
      key: "2",
      label: `${t("COMMENT_ON_RETURN_RENT")}`,
      children: commentOnReturn,
      span: 4,
    },
    {
      key: "3",
      label: `${t("returned_by")}`,
      children: returnedBy,
      span: 4,
    },
    {
      key: "4",
      label: `${t("rent_rate")}`,
      children: <Rate defaultValue={rate} disabled={true} />,
      span: 4,
    },
  ];

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <Descriptions
      title={
        <div className=" flex items-center justify-between  !text-[16px] gap-4 text-[#d35400]">
          <div className="gap-4 flex items-center">
            <IterationCw size={20} color="#000000" />
            <span> {t("RENT_RETURN_INFORMATION").toUpperCase()}</span>
          </div>
        </div>
      }
      layout={"vertical"}
      items={items}
      size="small"
      column={isSmallDevice ? 4 : 8}
    />
  );
};

export default RentReturnInfos;
