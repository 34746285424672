import React, { useMemo } from "react";
import { CarMaintenance } from "../../../../../../Types";
import { Card, Col, Row, Statistic, StatisticProps } from "antd";
import { HandCoins } from "lucide-react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

function MaintenanceInNumbers({
  maintenance,
}: {
  maintenance: CarMaintenance[] | undefined;
}) {
  const { t } = useTranslation();
  const formatter: StatisticProps["formatter"] = (value) => (
    <CountUp end={value as number} separator=" " />
  );

  const formatterWithDecimal: StatisticProps["formatter"] = (value) => (
    <CountUp end={value as number} separator=" " decimals={3} decimal={","} />
  );

  const maintenanceCost = useMemo(() => {
    return maintenance?.reduce((prev, curr) => {
      return prev + curr.cost;
    }, 0);
  }, [maintenance]);

  return (
    <Row gutter={[16, 16]} wrap={true} align="stretch" justify="center">
      <Col md={12} lg={12} sm={24} xs={24}>
        <Card
          loading={!maintenance}
          className="h-full flex flex-col justify-between"
        >
          <Statistic
            title={t("number_of_completed_maintenance")}
            value={maintenance?.length}
            formatter={formatter}
          />
        </Card>
      </Col>
      <Col md={12} lg={12} sm={24} xs={24}>
        <Card
          loading={!maintenance}
          className="h-full flex flex-col justify-between"
        >
          <Statistic
            title={t("total_maintenance_cost")}
            loading={typeof maintenanceCost !== "number"}
            value={maintenanceCost}
            prefix={<HandCoins size={20} />}
            formatter={formatterWithDecimal}
            suffix={t("TND")}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default MaintenanceInNumbers;
