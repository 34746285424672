import React, { useState } from "react";
import { CarMaintenance, Fleet } from "../../../../../../Types";
import { useTranslation } from "react-i18next";
import { Bolt, ChevronDown, ChevronUp, Wrench } from "lucide-react";
import { Empty, Image, Table } from "antd";
import { FleetMaintenanceColumns } from "./FleetMaintenanceColumns";
import AddMaintenanceForm from "./AddMaintenance";
import MaintenanceInNumbers from "./MaintenanceInNumbers";
import { PdfViewer } from "../../../../../UI/PdfViewer/pdfViewer";

interface FleetMaintenanceProps {
  fleet: Fleet | null;
}

const FleetMaintenance: React.FC<FleetMaintenanceProps> = ({ fleet }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const columns = FleetMaintenanceColumns(t);

  const expandedRowRender = (record: CarMaintenance) => {
    const { bill_copy } = record;

    if (!bill_copy) {
      return <Empty description={t("no_maintenance_bill_available")} />;
    }
    return (
      <div className="flex flex-col justify-evenly lg:flex-row lg:flex-wrap gap-4 my-6 ">
        {bill_copy.originalName.includes("pdf") ? (
          <PdfViewer
            title={t("maintenance_bill_optional")}
            fileName={bill_copy.originalName}
            size={bill_copy.size}
            pdfUrl={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/media/${bill_copy.name}`}
          />
        ) : (
          <Image
            width={400}
            height={250}
            title={t("maintenance_bill_optional")}
            src={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/media/${bill_copy.name}`}
          />
        )}
      </div>
    );
  };
  return (
    <div className="w-full flex flex-col min-h-fit">
      <div className=" flex   justify-between   ">
        <div className="  flex items-center gap-4 ">
          <Wrench size={20} />
          <span className="overflow-hidden whitespace-nowrap text-ellipsis   flex-auto text-opacity-88 font-semibold text-[16px] leading-6  text-[#d35400]">
            {t("maintenance").toUpperCase()}
          </span>
        </div>

        {isOpen ? (
          <ChevronUp
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        ) : (
          <ChevronDown
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        )}
      </div>
      {isOpen ? (
        <div className="w-full overflow-scroll mt-6">
          <MaintenanceInNumbers maintenance={fleet?.CarMaintenance} />

          <Table
            title={() => (
              <div className="flex justify-between items-center py-4">
                <div className="flex gap-4">
                  <Bolt />
                  <span className="uppercase font-bold text-sm">
                    {t("completed_maintenance_list")}
                  </span>
                </div>
                <AddMaintenanceForm fleetID={fleet?.fleetID} />
              </div>
            )}
            rowKey="carMaintenanceID"
            size="small"
            loading={!fleet}
            rootClassName="w-full"
            columns={columns}
            expandable={{
              expandedRowRender,
            }}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20", "30"],
            }}
            dataSource={fleet?.CarMaintenance}
            bordered={false}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FleetMaintenance;
