import React, { useState } from "react";
import { Fleet, Insurances } from "../../../../../../Types";
import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp, Shield } from "lucide-react";
import { Descriptions, DescriptionsProps, Image, Table } from "antd";
import { FleetInsuranceColumns } from "./FleetInsuranceColumns";
import { useMediaQuery } from "@mui/material";
import { PdfViewer } from "../../../../../UI/PdfViewer/pdfViewer";
import InsuranceForm from "./AddInsurance";
import CancelInsurance from "./cancelInsurance";
import moment from "moment";

interface FleetInsuranceProps {
  fleet: Fleet | null;
}

const FleetInsurance: React.FC<FleetInsuranceProps> = ({ fleet }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const columns = FleetInsuranceColumns(t);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const expandedRowRender = (record: Insurances) => {
    const items: DescriptionsProps["items"] = [
      {
        key: "1",
        label: t("policy_class"),
        children: record?.class,
        span: 2,
      },
      {
        key: "2",
        label: t("intermediate"),
        children: record?.intermediate,
        span: 2,
      },
      {
        key: "8",
        label: t("policy_price"),
        children: (
          <span>
            {record?.policy_price} {t("TND")}
          </span>
        ),
        span: 2,
      },
      {
        key: "3",
        label: t("insurer_phone"),
        children: record?.insurer_phone,
        span: 2,
      },
      {
        key: "4",
        label: t("insurer_email"),
        children: record?.insurer_email,
        span: 2,
      },
      {
        key: "5",
        label: t("insurer_agency"),
        children: record?.insurer_agency,
        span: 2,
      },
      {
        key: "6",
        label: t("policy_type"),
        children: t(record?.policy_type.toLowerCase()).toUpperCase(),
        span: 2,
      },
      {
        key: "7",
        label: t("product"),
        children: t(record?.product.toLowerCase()).toUpperCase(),
        span: 2,
      },

      {
        key: "12",
        label: t("insurance_contract_type"),
        children: t(
          record?.insurance_contract_type.toLowerCase(),
        ).toUpperCase(),
        span: 2,
      },

      {
        key: "10",
        label: t("cancellation_date"),
        children: record?.contract_cancelled
          ? moment(record?.cancellation_date).format("DD/MM/YYYY")
          : t("na"),
        span: 2,
      },
      {
        key: "11",
        label: t("cancellation_reason"),
        children: record?.contract_cancelled
          ? record?.cancellation_reason
          : t("na"),
        span: 3,
      },
      ...(!Boolean(
        record?.contract_cancelled ||
          moment(record.valid_to).isBefore(moment()),
      )
        ? [
            {
              key: "14",
              label: "",
              children: <CancelInsurance assuranceID={record.assuranceID} />,
              span: 1,
            },
          ]
        : []),

      {
        key: "13",
        label: t("insurance_policy_file_copy"),
        children: (
          <div className="w-full flex flex-row justify-center">
            {record?.policy ? (
              record?.policy?.originalName.includes("pdf") ? (
                <PdfViewer
                  title={t("insurance_policy_file_copy")}
                  fileName={record?.policy?.originalName}
                  size={record?.policy?.size}
                  pdfUrl={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/media/${record?.policy?.name}`}
                />
              ) : (
                <Image
                  width={400}
                  height={250}
                  title={t("insurance_policy_file_copy")}
                  src={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/media/${record?.policy?.name}`}
                />
              )
            ) : null}
          </div>
        ),
        span: 6,
      },
    ];

    return (
      <div className="w-full flex flex-col p-4">
        <Descriptions
          title={null}
          layout={"vertical"}
          items={items}
          size="small"
          column={isSmallDevice ? 3 : 6}
        />
      </div>
    );
  };

  const expandable = {
    expandedRowRender,
  };

  return (
    <div className="w-full flex flex-col min-h-fit">
      <div className=" flex   justify-between   ">
        <div className="  flex items-center gap-4 ">
          <Shield size={20} />
          <span className="overflow-hidden whitespace-nowrap text-ellipsis   flex-auto text-opacity-88 font-semibold text-[16px] leading-6  text-[#d35400]">
            {t("insurance").toUpperCase()}
          </span>
        </div>

        {isOpen ? (
          <ChevronUp
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        ) : (
          <ChevronDown
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        )}
      </div>

      {isOpen ? (
        <div className="w-full overflow-scroll mt-6">
          <InsuranceForm fleetID={fleet?.fleetID} />

          <Table
            rowKey="assuranceID"
            size="small"
            loading={!fleet}
            rootClassName="w-full"
            columns={columns}
            dataSource={fleet?.Insurances}
            bordered={false}
            expandable={expandable}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FleetInsurance;
