import React, { useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import { Field, Form as FormikForm, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import { Axios } from "../../../../../../Config";
import { mutate } from "swr";
import { toast } from "react-toastify";
import UploadWithDrag from "../../../../../UI/UploadWithDrag";

const { Option } = Select;

const initialValues = {
  num_contract: null,
  num_policy: null,
  valid_from: null,
  valid_to: null,
  insurer_name: null,
  policy_class: null,
  intermediate: null,
  insurer_phone: null,
  insurer_email: null,
  insurer_agency: null,
  policy_type: null,
  policyFileID: null,
  product: null,
  policy_price: null,
  insurance_contract_type: null,
};

const policyTypes = [
  "CIVIL_LIABILITY",
  "DAMAGE_AND_COLLISION",
  "ALL_RISKS",
  "CEILING",
  "DEDUCTIBLE",
];

const policyProductTypes = ["COMPANY", "INDIVIDUAL"];
const policyContractTypes = ["INDEVIDUAL", "FLEET"];

const InsuranceForm = ({ fleetID }: { fleetID?: string }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const [btnIsLoading, setBtnIsLoading] = useState<boolean>(false);

  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      setBtnIsLoading(true);
      const data = { ...values, insured_car: fleetID };
      await Axios.post("/fleet-insurance", data);
      mutate("/enterprise/fleet").then(() => {
        formikHelpers.setValues(initialValues);
        toast.success(t("insurance_record_added_successfully"));
        setBtnIsLoading(false);
        setOpenModal(false);
      });
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  return (
    <>
      <div className="w-full flex gap-4 flex-col md:flex-row mb-8 justify-end">
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          onClick={() => {
            setOpenModal(true);
          }}
        >
          {t("add_insurance").toUpperCase()}
        </Button>
      </div>
      {openModal ? (
        <Modal
          width={isSmallDevice ? "100% " : "60%"}
          title={t("add_insurance").toUpperCase()}
          footer={null}
          open={openModal}
          style={{ top: 20 }}
          destroyOnClose={true}
          onClose={() => {
            setOpenModal(false);
          }}
          onCancel={() => {
            setOpenModal(false);
          }}
        >
          <div className="p-12 h-[600px] max-h-[600px] overflow-auto">
            <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
              {({ setFieldValue, handleSubmit }) => (
                <Form
                  layout="vertical"
                  onFinish={handleSubmit}
                  component={FormikForm}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label={t("num_contract")} name="num_contract">
                        <Field
                          name="num_contract"
                          as={Input}
                          onChange={(value: any) =>
                            setFieldValue("num_contract", value.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item label={t("valid_from")} name="valid_from">
                        <DatePicker
                          value={moment(initialValues.valid_from)}
                          className="w-full"
                          format="DD/MM/YYYY"
                          onChange={(date) => setFieldValue("valid_from", date)}
                        />
                      </Form.Item>

                      <Form.Item label={t("insurer_name")} name="insurer_name">
                        <Field
                          name="insurer_name"
                          as={Input}
                          onChange={(value: any) =>
                            setFieldValue("insurer_name", value.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("insurer_phone")}
                        name="insurer_phone"
                      >
                        <Field
                          name="insurer_phone"
                          as={Input}
                          onChange={(value: any) =>
                            setFieldValue("insurer_phone", value.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item label={t("policy_class")} name="policy_class">
                        <Field
                          name="policy_class"
                          as={InputNumber}
                          rootClassName="w-full"
                          onChange={(value: any) =>
                            setFieldValue("policy_class", value)
                          }
                        />
                      </Form.Item>

                      <Form.Item label={t("policy_type")} name="policy_type">
                        <Field
                          name="policy_type"
                          as={Select}
                          onChange={(value: any) =>
                            setFieldValue("policy_type", value)
                          }
                        >
                          {policyTypes.map((type) => (
                            <Option value={type} key={type}>
                              {t(type.toLowerCase()).toUpperCase()}
                            </Option>
                          ))}
                        </Field>
                      </Form.Item>

                      <Form.Item label={t("product")} name="product">
                        <Field
                          name="product"
                          as={Select}
                          onChange={(value: any) =>
                            setFieldValue("product", value)
                          }
                        >
                          {policyProductTypes.map((product) => (
                            <Option value={product} key={product}>
                              {t(product.toLowerCase()).toUpperCase()}
                            </Option>
                          ))}
                        </Field>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item label={t("num_policy")} name="num_policy">
                        <Field
                          name="num_policy"
                          as={Input}
                          onChange={(value: any) =>
                            setFieldValue("num_policy", value.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item label={t("valid_to")} name="valid_to">
                        <DatePicker
                          className="w-full"
                          format="DD/MM/YYYY"
                          value={moment(initialValues.valid_to)}
                          onChange={(date) => setFieldValue("valid_to", date)}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("insurer_agency")}
                        name="insurer_agency"
                      >
                        <Field
                          name="insurer_agency"
                          as={Input}
                          onChange={(value: any) =>
                            setFieldValue("insurer_agency", value.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        label={t("insurer_email")}
                        name="insurer_email"
                      >
                        <Field
                          name="insurer_email"
                          as={Input}
                          type="email"
                          onChange={(value: any) =>
                            setFieldValue("insurer_email", value.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item label={t("intermediate")} name="intermediate">
                        <Field
                          name="intermediate"
                          as={InputNumber}
                          className="w-full"
                          onChange={(value: any) =>
                            setFieldValue("intermediate", value)
                          }
                        />
                      </Form.Item>

                      <Form.Item label={t("policy_price")} name="policy_price">
                        <Field
                          name="policy_price"
                          as={InputNumber}
                          min={0}
                          onChange={(value: any) =>
                            setFieldValue("policy_price", value)
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        label={t("insurance_contract_type")}
                        name="insurance_contract_type"
                      >
                        <Field
                          name="insurance_contract_type"
                          as={Select}
                          onChange={(value: any) =>
                            setFieldValue("insurance_contract_type", value)
                          }
                        >
                          {policyContractTypes.map((type) => (
                            <Option value={type} key={type}>
                              {t(type.toLowerCase()).toUpperCase()}
                            </Option>
                          ))}
                        </Field>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    label={t("insurance_policy_file_copy")}
                    name="maintenance_bill_copy"
                  >
                    <UploadWithDrag
                      maxCount={1}
                      setFileValue={(file) =>
                        setFieldValue("policyFileID", file.fileID)
                      }
                      uploadText={t("insurance_policy_file_copy").toUpperCase()}
                      hint={t("add_insurance_policy_copy")}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      loading={btnIsLoading}
                      type="primary"
                      htmlType="submit"
                      className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white w-full "
                    >
                      {t("add_insurance")}
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default InsuranceForm;
