import React, { useRef } from "react";
import { Card, Divider, Drawer, Tooltip } from "antd";
import { DrawerProps } from "antd/es/drawer";
import { useTranslation } from "react-i18next";
import { SquareX, TicketPlus } from "lucide-react";
import moment from "moment/moment";
import { RentContracts } from "../../../../../../Types";
import RentGeneralInfos from "./RentGeneralInfos";
import RentReturnInfos from "./RentReturnInfos";
import { toast } from "react-toastify";
import { Axios } from "../../../../../../Config";
import { mutate } from "swr";
import RentComments from "./RentComments";
import RentPayement from "./RentPayement";

interface RentDisplayDrawerProps extends DrawerProps {
  contract: RentContracts;
}

const RentDisplayDrawer: React.FC<RentDisplayDrawerProps> = ({
  contract,
  ...props
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const asyncToast = useRef<any>();

  const billFromContractHandler = async () => {
    try {
      asyncToast.current = toast.loading(
        t("BILL_FROM_CONTRACT_ONGOING_MESSAGE"),
      );

      await Axios.post("/companyBills/from-contract", {
        rentContractID: contract.rentContractID,
      }).then((res) => res.data.message);

      await mutate("/companyBills");
      toast.update(asyncToast.current, {
        render: t("BILL_FROM_CONTRACT_SUCCESS_MESSAGE"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error: any) {
      toast.update(asyncToast.current, {
        render: t(
          error?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const Title = () => {
    return (
      <div className="w-full flex   !text-[16px]  items-center justify-between ">
        <span>{t("RENT_CONTRACT_SHEET")}</span>
        <div className="flex gap-4">
          <span> {String(contract.referance).padStart(8, "0")}</span>
          <Tooltip title={t("BILLED_CONTRACT")}>
            <TicketPlus
              className="cursor-pointer"
              onClick={() => billFromContractHandler()}
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <Drawer {...props} title={<Title />} closeIcon={<SquareX size={20} />}>
      <div
        className="w-full flex flex-col    min-h-fit"
        dir={language === "ar" ? "rtl" : "ltr"}
      >
        <RentGeneralInfos RentContract={contract} />

        {contract.isReturned ? (
          <>
            <Divider />
            <RentReturnInfos RentContract={contract} />
          </>
        ) : null}
        <Divider />
        <RentPayement RentContract={contract} />
        <Divider />
        <RentComments RentContract={contract} />
        <Divider />
        <Card className="flex flex-col">
          <p>
            {t("created_by")} : {contract?.createdBy.name}{" "}
            {contract?.createdBy.lastName}
          </p>
          <p>
            {t("date_of_creations")} :{" "}
            {moment(contract?.createdAt).format("DD/MM/YYYY HH:MM")}
          </p>
        </Card>
      </div>
    </Drawer>
  );
};

export default RentDisplayDrawer;
