import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import {useMyCompanyData} from "../../../../Hooks";
import {Col, Descriptions, DescriptionsProps, Row, Skeleton, Statistic, Switch,} from "antd";
import {
  AuditOutlined,
  BookOutlined,
  CheckOutlined,
  CloseOutlined,
  FileProtectOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import i18n from "i18next";
import moment from "moment";
import {useSelector} from "react-redux";
import FormItem from "antd/es/form/FormItem";
import {Axios} from "../../../../Config";
import {toast} from "react-toastify";

export function CompanyInfosContent() {
  const { t } = useTranslation();
  const { company, isLoading, mutate } = useMyCompanyData();
  const { language } = i18n;
  const user = useSelector((state: any) => state.authentication.user);

  const items: DescriptionsProps["items"] = [
    {
      key: "0",
      label: t("client_since"),
      children:`${moment(company?.createdAt ).fromNow(true) }    |    ${moment(company?.createdAt ).format("DD/MM/YYYY")}` ,
      labelStyle: { justifyContent: "center", display: "flex" },
      contentStyle: { justifyContent: "center", display: "flex" },
      span : 6
    },
    {
      key: "1",
      label: t("categorie"),
      children: t(company?.companyType?.toLowerCase() || ""),
      labelStyle: { justifyContent: "center", display: "flex" },
      contentStyle: { justifyContent: "center", display: "flex" },
    },
    {
      key: "2",
      label: t("matricule_fiscal"),
      children: company?.VAT || "",
      labelStyle: { justifyContent: "center", display: "flex" },
      contentStyle: { justifyContent: "center", display: "flex" },
    },
    {
      key: "3",
      label: t("identifiantUnique"),
      children: company?.rneID || "",
      labelStyle: { justifyContent: "center", display: "flex" },
      contentStyle: { justifyContent: "center", display: "flex" },
    },
    {
      key: "4",
      label: t("capital"),
      children: company?.capital || "",
      labelStyle: { justifyContent: "center", display: "flex" },
      contentStyle: { justifyContent: "center", display: "flex" },
    },
    {
      key: "5",
      label: t("auth_email"),
      children: company?.email || "",
      labelStyle: { justifyContent: "center", display: "flex" },
      contentStyle: { justifyContent: "center", display: "flex" },
    },
    {
      key: "6",
      label: t("dateOfEst"),
      children: company?.dateOfEst
        ? moment(company?.dateOfEst).format("DD/MM/YYYY")
        : "",
      labelStyle: { justifyContent: "center", display: "flex" },
      contentStyle: { justifyContent: "center", display: "flex" },
    },
    {
      key: "7",
      label: t("validUntil"),
      children: company?.validUntil
        ? moment(company?.validUntil).format("DD/MM/YYYY")
        : "",
      labelStyle: { justifyContent: "center", display: "flex" },
      contentStyle: { justifyContent: "center", display: "flex" },
    },
  ];

  const asyncToast = useRef<any>();

  const viewLimitationHandler = async () => {
    try {
      asyncToast.current = toast.loading(t("VIEW_LIMITATION_ONGOING_MESSAGE"));

      await Axios.put("/enterprise/isViewLimited", {
        isViewLimited: !company?.isViewLimited,
      });

      await mutate();

      toast.update(asyncToast.current, {
        render: t("VIEW_LIMITATION_SUCCESS_MESSAGE"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error: any) {
      toast.update(asyncToast.current, {
        render: t(
          error?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  return (
    <div className="  flex-1   p-4">
      <Row gutter={[24, 35]} wrap={true}>
        <Col span={24}>
          <Statistic
            title={t(language === "ar" ? "nomCommercialAr" : "nomCommercialFr")}
            value={language === "ar" ? company?.rsArabic : company?.rsLatin}
            loading={isLoading}
          />
          <span className="!text-gray-400">{company?.companyID}</span>
        </Col>
        {user.AgencesUsers.length ? null : (
          <>
            <Col span={6} xs={24} sm={12} md={6}>
              <Statistic
                title={t("active_users")}
                value={company?.CompaniesUsers.length}
                loading={isLoading}
                prefix={<UserSwitchOutlined />}
              />
            </Col>
            <Col span={6} xs={24} sm={12} md={6}>
              <Statistic
                title={t("rent_contracts")}
                value={company?.RentContract.length}
                loading={isLoading}
                prefix={<BookOutlined />}
              />
            </Col>
            <Col span={6} xs={24} sm={12} md={6}>
              <Statistic
                title={t("subscriptions")}
                value={company?.Subscription.length}
                loading={isLoading}
                prefix={<FileProtectOutlined />}
              />
            </Col>
            <Col span={6} xs={24} sm={12} md={6}>
              <Statistic
                title={t("bills")}
                value={company?.PlatformBills.length}
                loading={isLoading}
                prefix={<AuditOutlined />}
              />
            </Col>
            <Col span={24} xs={24} sm={24} md={24}>
              <div className="flex items-center w-full ">
                <FormItem label={t("isViewLimited")}>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={!company?.isViewLimited}
                    loading={!Boolean(company) || isLoading}
                    onChange={viewLimitationHandler}
                  />
                </FormItem>
              </div>
            </Col>
          </>
        )}
        <Col span={24} xs={24} md={24}>
          {company ? (
            <Descriptions
              layout="vertical"
              bordered
              items={items}
              size="small"
            />
          ) : (
            <Skeleton />
          )}
        </Col>
      </Row>
    </div>
  );
}
