import React, { Dispatch, SetStateAction, useState } from "react";
import { useFormikContext } from "formik";
import { RentInitialValues } from "./index";
import { useFleetData } from "../../../../../Hooks";
import { Button, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useTranslation } from "react-i18next";
import { Fleet } from "../../../../../Types";
import { SelectedCarDescription } from "./SelectedCarDescription";

function SelectCarToRent({
  setCurrentStepper,
}: {
  setCurrentStepper: Dispatch<SetStateAction<number>>;
}) {
  const { values, setFieldValue } = useFormikContext<RentInitialValues>();
  const { t } = useTranslation();
  const { fleet, isLoading } = useFleetData();

  const [selectedCar, setSelectedCar] = useState<Fleet | null>(null);

  const convertToGroupedSelectOptions = (vehicles) => {
    const groupedOptions = vehicles
      .filter((car) => car.status === "AVAILABLE")
      .reduce((acc, vehicle) => {
        const agenceName =
          vehicle.agence?.agenceName || t("company_car").toUpperCase();
        const option = {
          label: `${vehicle.manufacturer.replaceAll("_", " ")} ${
            vehicle.modal
          }   |   ${vehicle.registrationNumber.replace("/", ` ${t("TU")} `)}  `,
          value: vehicle.fleetID,
          car: vehicle,
        };

        if (!acc[agenceName]) {
          acc[agenceName] = {
            label: <span>{agenceName}</span>,
            title: agenceName,
            options: [],
          };
        }

        acc[agenceName].options.push(option);
        return acc;
      }, {});

    return Object.values(groupedOptions);
  };

  const fleetToOptionss = fleet ? convertToGroupedSelectOptions(fleet) : [];

  return (
    <div className="w-full h-fit flex flex-col gap-4 items-center">
      <FormItem
        label={t("car_number")}
        name="idType"
        className="w-full md:w-2/3"
      >
        <Select
          allowClear
          showSearch
          filterOption={(input, option): boolean => {
            if (!option?.car) {
              return false;
            }

            const lowerInput = input.toLowerCase();
            const { car } = option;

            return (
              car?.registrationNumber?.toLowerCase().includes(lowerInput) ||
              car?.manufacturer.toLowerCase().includes(lowerInput) ||
              car?.modal.toLowerCase().includes(lowerInput) ||
              car?.vin?.toLowerCase().includes(lowerInput) ||
              false
            );
          }}
          key={"fleetID"}
          placeholder={t("car_number")}
          optionFilterProp="children"
          value={values.fleetID || ""}
          style={{ width: "100%" }}
          options={fleetToOptionss}
          disabled={isLoading}
          loading={isLoading}
          onChange={(value) => {
            const selectedCar =
              fleet?.filter((car) => car.fleetID === value)[0] || null;
            setSelectedCar(selectedCar);
          }}
        />
      </FormItem>

      {selectedCar ? (
        <SelectedCarDescription selectedCar={selectedCar} />
      ) : null}
      <div className="flex gap-4 flex-col md:flex-row">
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          onClick={async () => {
            await setFieldValue(
              "contract.dayPrice",
              selectedCar?.price_per_day,
            );
            await setFieldValue("contract.kmCount", selectedCar?.kilometrage);
            await setFieldValue("fleetID", selectedCar?.fleetID).then(() => {
              setCurrentStepper((prevState) => prevState + 1);
            });
          }}
          disabled={!selectedCar}
        >
          {t("select_car").toUpperCase()}
        </Button>
      </div>
    </div>
  );
}

export default SelectCarToRent;
