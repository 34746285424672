import React from "react";
import { Route } from "react-router-dom";
import { RenderRoutes } from "../Config";
import {
  AddBillPage,
  AddCompanyPage,
  AddInvoicePage,
  BillsListingPage,
  BillsPayementsPage,
  CompaniesListingPage,
  CompaniesManagementPage,
  InvoicesDashboard,
  InvoicesListingPage,
  RneLookUPage,
  SubscriptionListingPage,
  SubscriptionPayementsPage,
  SubscriptionPricesPage,
} from "../Pages";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";
import { PlatformUsersManagementPage } from "../Pages/PlatformPages/PlatformUsersManagementPage";

export function PlatformManagementRoutes() {
  return (
    <RenderRoutes>
      <Route
        path="/locasafe_management/companies_management"
        element={
          <PrivateRoute>
            <CompaniesManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/users_management/users"
        element={
          <PrivateRoute>
            <PlatformUsersManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/locasafe_management/add_company"
        element={
          <PrivateRoute>
            <AddCompanyPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/locasafe_management/company_listing"
        element={
          <PrivateRoute>
            <CompaniesListingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/invoices_management"
        element={
          <PrivateRoute>
            <InvoicesDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/invoices_management/invoices_listing"
        element={
          <PrivateRoute>
            <InvoicesListingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/invoices_management/add_invoices"
        element={
          <PrivateRoute>
            <AddInvoicePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/bills_management/add_bill"
        element={
          <PrivateRoute>
            <AddBillPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/bills_management/bills_listing"
        element={
          <PrivateRoute>
            <BillsListingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/bills_management/bills_payements"
        element={
          <PrivateRoute>
            <BillsPayementsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/subscription_management/subscription_prices"
        element={
          <PrivateRoute>
            <SubscriptionPricesPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/subscription_management/subscription_listing"
        element={
          <PrivateRoute>
            <SubscriptionListingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/subscription_management/subscription_payements"
        element={
          <PrivateRoute>
            <SubscriptionPayementsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/rne_lookup"
        element={
          <PrivateRoute>
            <RneLookUPage />
          </PrivateRoute>
        }
      />
    </RenderRoutes>
  );
}
