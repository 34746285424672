import React from "react";
import { t } from "i18next";
import { ColumnGroupType } from "antd/es/table";
import { RentSearchHistory } from "../../../../Types/types";
import { ColumnType } from "antd/lib/table";

import moment from "moment";

export const searchHistoryColumns: (
  | ColumnGroupType<RentSearchHistory>
  | ColumnType<RentSearchHistory>
)[] = [
  {
    title: t("car_number"),
    dataIndex: "registrationNumber",
    key: "registrationNumber",
    render: (value) => (
      <span className="font-bold"> {value.replace("/", " TU ")}</span>
    ),
  },

  {
    title: t("start_date"),
    dataIndex: "startPeriod",
    key: "startPeriod",
    render: (value) => {
      return moment(new Date(value)).format("DD/MM/YYYY");
    },
  },
  {
    title: t("end_date"),
    dataIndex: "endPeriod",
    key: "endPeriod",
    render: (value) => moment(new Date(value)).format("DD/MM/YYYY"),
  },
  {
    title: t("search_date"),
    dataIndex: "searcedAt",
    key: "searcedAt",
    render: (value) => moment(new Date(value)).format("DD/MM/YYYY hh:mm"),
  },
  {
    title: t("created_by"),
    dataIndex: "searchedBy",
    key: "searchedBy",
    render: (value) => `${value.name} ${value.lastName}`,
  },
];
