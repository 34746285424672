import { Clients } from "../../Types";
import React from "react";
import { useTranslation } from "react-i18next";
import { PdfViewer } from "./PdfViewer/pdfViewer";
import { Image } from "antd";

interface ClientFilesProps {
  client: Clients | null;
}

export const ClientFilesContent: React.FC<ClientFilesProps> = ({ client }) => {
  const { t } = useTranslation();
  if (!client) return null;
  const { idRecto, idVerso } = client;
  return (
    <div className="flex flex-col justify-evenly lg:flex-row lg:flex-wrap gap-4 my-6 ">
      {idRecto ? (
        idRecto.originalName.includes("pdf") ? (
          <PdfViewer
            title={
              client.nationality !== "TUNISIENNE"
                ? t("PASSPORT")
                : t("copie_recto")
            }
            fileName={idRecto.originalName}
            size={idRecto.size}
            pdfUrl={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/media/${idRecto.name}`}
          />
        ) : (
          <Image
            width={400}
            height={250}
            title={
              client.nationality !== "TUNISIENNE"
                ? t("PASSPORT")
                : t("copie_recto")
            }
            src={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/media/${idRecto.name}`}
          />
        )
      ) : null}

      {idVerso ? (
        idVerso.originalName.includes("pdf") ? (
          <PdfViewer
            title={
              client.nationality !== "TUNISIENNE"
                ? t("entry_stamp_tunisian_territory")
                : t("copie_verso")
            }
            fileName={idVerso.originalName}
            size={idVerso.size}
            pdfUrl={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/media/${idVerso.name}`}
          />
        ) : (
          <Image
            width={400}
            height={250}
            title={
              client.nationality !== "TUNISIENNE"
                ? t("entry_stamp_tunisian_territory")
                : t("copie_verso")
            }
            src={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/media/${idVerso.name}`}
          />
        )
      ) : null}
    </div>
  );
};
