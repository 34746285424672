import React, { useEffect, useRef } from "react";
import "./Assets/styles/index.scss";
import {
  AppRoutes,
  AuthRoutes,
  CompanyManagementRoutes,
  ConsultantRoutes,
  GeneralRoutes,
  PlatformManagementRoutes,
  SettingsRoutes,
} from "./Routes";
import { toast, ToastContainer } from "react-toastify";
import "moment/locale/fr";
import "moment/locale/ar-tn";
import { useSelector } from "react-redux";
import { RootReducer } from "./Store/reducers";
import { Users } from "./Types";
import { useNetworkState } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";

function App(): JSX.Element {
  const user = useSelector(
    (state: RootReducer) => state?.authentication?.user,
  ) as Users;

  const { t } = useTranslation();
  const { online } = useNetworkState();

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (online) {
      toast.success(t("USER_BACK_ONLINE"));
    } else {
      toast.error(t("USER_GONE_OFFLINE"));
    }
  }, [online]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />

      <AuthRoutes />
      <GeneralRoutes />
      <AppRoutes />
      <SettingsRoutes />

      {user?.accountType === "CONSULTANT" && user?.isConsultantAdmin && (
        <ConsultantRoutes />
      )}
      {user?.accountType === "PLATFORM" && <PlatformManagementRoutes />}
      {user?.accountType === "ENTREPRISE" && <CompanyManagementRoutes />}
    </>
  );
}

export default App;
