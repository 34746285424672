import React, { useMemo, useState } from "react";
import { Modal, Table } from "antd";
import { usePlatformInvoicesData } from "../../../../../Hooks/usePlatformInvoicesData";
import { CompanyListingTableColumns } from "./PlatormInvoicesColumns";
import { useTranslation } from "react-i18next";
import Search from "antd/es/input/Search";
import { PlatformInvoicePrintable } from "../../../../UI/Printables/PlatformInvoicePrintable";
import { useMediaQuery } from "@mui/material";

export function PlatformInvoicesListing() {
  const { t } = useTranslation();

  const { isLoading, platformInvoices } = usePlatformInvoicesData();

  const [open, setOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<null | string>(null);

  const [search, setSearch] = useState("");
  const dataToDisplay = useMemo(() => {
    return search
      ? platformInvoices.filter((invoice) => {
          return Boolean(
            invoice.platformInvoiceID
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
              invoice.company.rsLatin
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              invoice.company.rsArabic
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              invoice.company.VAT?.toLowerCase().includes(
                search.toLowerCase(),
              ) ||
              invoice.reference?.toLowerCase().includes(search.toLowerCase()),
          );
        })
      : platformInvoices;
  }, [platformInvoices, search]);

  const chooseInvoiceHandler = (invoiceID: string | null) => {
    setOpen(true);
    setSelectedInvoice(invoiceID);
  };

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const columns = CompanyListingTableColumns(t, chooseInvoiceHandler);
  return (
    <div className="w-full overflow-scroll p-10 !relative">
      {open && (
        <Modal
          width={isSmallDevice ? "100% " : "60%"}
          open={open}
          footer={null}
          onCancel={() => {
            setOpen(false);
            setSelectedInvoice(null);
          }}
          onClose={() => {
            setOpen(false);
            setSelectedInvoice(null);
          }}
        >
          <PlatformInvoicePrintable
            PlatformInvoice={
              platformInvoices.filter(
                (invoice) => invoice.platformInvoiceID === selectedInvoice,
              )[0]
            }
          />
        </Modal>
      )}
      <div className="flex gap-4 mb-4">
        <Search
          placeholder={t("search")}
          allowClear
          onSearch={(value) => {
            setSearch(value);
          }}
          style={{ width: 304 }}
        />
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={dataToDisplay}
        rowKey="platformInvoiceID"
        size="small"
        tableLayout={"auto"}
        sticky={false}
        rootClassName="h-2/3 min-h-2/3 max-w-full"
        bordered={false}
      />
    </div>
  );
}
