import React, { useMemo, useState } from "react";
import { Clients } from "../../../../../Types";
import { useTranslation } from "react-i18next";
import {
  AlignLeft,
  CalendarDays,
  Caravan,
  ChevronDown,
  ChevronUp,
  CircleArrowOutDownRight,
  CircleArrowOutUpRight,
  CircleCheckBig,
  Clock,
  DollarSign,
  Fuel,
  Gauge,
  Grid2x2X,
  HandCoins,
  Hash,
  ListTree,
  User,
} from "lucide-react";
import { Divider, Empty, Tag, Timeline } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import CopyToClipboard from "../../../../UI/CopyToClipboard";

interface ClientRentContractsProps {
  client: Clients | null;
}

const ClientRentContracts: React.FC<ClientRentContractsProps> = ({
  client,
}) => {
  const { t } = useTranslation();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const contractToTimeLine = useMemo(() => {
    return client?.RentContract.sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt)),
    ).map((contract) => {
      const {
        rentContractID,
        createdAt,
        startDate,
        endDate,
        dayPrice,
        dayCount,
        comment,
        isRentWithDriver,
        extraPrice,
        commentOnReturn,
        rentedCar,
        isReturned,
      } = contract;
      return {
        dot: isReturned ? (
          <CircleCheckBig color="#16a085" size={16} />
        ) : (
          <ClockCircleOutlined style={{ fontSize: "16px" }} />
        ),
        children: (
          <div
            key={rentContractID}
            className="overflow-hidden max-w-md mx-auto"
          >
            <div className="p-6 space-y-4">
              <div className="space-y-2">
                <p className="text-sm text-gray-500  flex   items-center justify-between">
                  <Hash size={15} />
                  <CopyToClipboard input={rentContractID} showInput={true} />
                </p>
              </div>
              <Divider />
              <div className="space-y-2">
                <p className="text-sm text-gray-600 flex gap-2 items-center">
                  <Clock size={15} color="#000000" />

                  <p className="w-full flex justify-between">
                    <span> {t("date_of_creations")}:</span>
                    <span> {moment(createdAt).format("DD/MM/YYYY HH:MM")}</span>
                  </p>
                </p>
                <p className="text-sm text-gray-600 flex gap-2 items-center">
                  <CircleArrowOutUpRight size={15} color="#27ae60" />
                  <p className="w-full flex justify-between">
                    <span> {t("start_date")}:</span>
                    <span> {moment(startDate).format("DD/MM/YYYY HH:MM")}</span>
                  </p>
                </p>
                <p className="text-sm text-gray-600 flex gap-2 items-center">
                  <CircleArrowOutDownRight size={15} color="#2980b9" />
                  <p className="w-full flex justify-between">
                    <span> {t("end_date")}:</span>
                    <span> {moment(endDate).format("DD/MM/YYYY HH:MM")}</span>
                  </p>
                </p>
              </div>
              <Divider />
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <HandCoins size={15} className="text-gray-400" />
                  <span className="text-sm text-gray-600">{t("dayPrice")}</span>
                </div>
                <span className="text-sm font-medium">
                  {Number(dayPrice).toFixed(3)} {t("TND")}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <CalendarDays className="text-gray-400" size={15} />
                  <span className="text-sm text-gray-600 capitalize">
                    {t("duration")}
                  </span>
                </div>
                <span className="text-sm font-medium">
                  {dayCount} {t("days")}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <DollarSign className="text-gray-400" size={16} />
                  <span className="text-sm text-gray-600">
                    {t("total_billed")}
                  </span>
                </div>
                <span className="text-sm font-medium">
                  {Number(
                    dayPrice * dayCount + Number(extraPrice || 0),
                  ).toFixed(3)}{" "}
                  {t("TND")}
                </span>
              </div>
              {comment && (
                <>
                  <Divider />

                  <div className="flex items-center gap-2">
                    <div className="flex items-center space-x-2 min-w-24">
                      <AlignLeft className="text-gray-400" size={16} />
                      <span>{t("comment")} :</span>
                    </div>
                    <span className="text-sm align-bottom">{comment}</span>
                  </div>
                </>
              )}
              <Divider />
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <User className="text-gray-600" size={16} />
                  <span className="text-sm text-gray-600">
                    {t("isRentWithDriver")}
                  </span>
                </div>
                <span className="text-sm font-medium">
                  <Tag color={isRentWithDriver ? "green" : "red"}>
                    {t(isRentWithDriver ? "yes" : "no")}
                  </Tag>
                </span>
              </div>
              {commentOnReturn && (
                <>
                  <Divider />

                  <div className="flex items-center gap-2">
                    <div className="flex items-center space-x-2">
                      <AlignLeft className="text-gray-600" size={16} />
                      <span>{t("return_comment")} :</span>
                    </div>
                    <span className="text-sm font-medium">
                      {commentOnReturn}
                    </span>
                  </div>
                </>
              )}
              <Divider />
              <div className="flex flex-col">
                <h3 className=" mb-2 flex items-center gap-2">
                  <span className="text-md font-semibold text-[#16a085]">
                    {t("rented_car").toUpperCase()}
                  </span>
                </h3>
                <div className=" flex flex-col gap-2 text-sm my-4">
                  <div className="flex justify-between items-center gap-2 text-sm  text-[#2c3e50] font-bold">
                    <span>
                      {rentedCar.manufacturer} {rentedCar.modal}
                    </span>

                    <span>
                      {rentedCar.registrationNumber.replace(
                        "/",
                        ` ${t("TU")} `,
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between items-center gap-2">
                    <div className="flex items-center space-x-2 text-gray-600">
                      <Fuel className="text-gray-400" size={16} />
                      <span>{t("fuel")} :</span>
                    </div>
                    <span className="text-sm font-medium">
                      {t(rentedCar.fuel || "")}
                    </span>
                  </div>
                  <div className="flex justify-between items-center gap-2">
                    <div className="flex items-center space-x-2 text-gray-600">
                      <Gauge className="text-gray-400" size={16} />
                      <span>{t("transmission")} :</span>
                    </div>
                    <span className="text-sm font-medium">
                      {t(rentedCar.transmission || "")}
                    </span>
                  </div>
                  <div className="flex  justify-between items-center gap-2">
                    <div className="flex items-center space-x-2 text-gray-600">
                      <Caravan className="text-gray-400" size={16} />
                      <span>{t("type")} :</span>
                    </div>
                    <span className="text-sm font-medium">
                      {t(rentedCar.type || "")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      };
    });
  }, [client]);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full flex flex-col min-h-fit ">
      <div className="mb-4 flex items-center gap-4 ">
        <ListTree size={20} />
        <span className="overflow-hidden whitespace-nowrap text-ellipsis   flex-auto text-opacity-88 font-semibold text-[16px] leading-6  text-[#d35400]">
          {t("client_rent_contracts").toUpperCase()}
        </span>
        {isOpen ? (
          <ChevronUp
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        ) : (
          <ChevronDown
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        )}
      </div>
      {isOpen ? (
        <div className="mt-8">
          {contractToTimeLine?.length ? (
            <Timeline
              mode={isSmallDevice ? "left" : "alternate"}
              items={contractToTimeLine}
            />
          ) : (
            <div className="w-full flex justify-center flex-col my-10">
              <Empty
                rootClassName="flex flex-col items-center"
                image={
                  <Grid2x2X className="text-gray-400 opacity-50" size={60} />
                }
                description={t("no_rent_contract")}
              ></Empty>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ClientRentContracts;
