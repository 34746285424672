import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import CashTransactionsContent from "../../Components/Features/CompanyComponents/CashManagement/CashTransactionsListing/cashTransactionsContent";

const CashManagementTransactionsPage = () => {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("cash_transaction_listing")}>
        <div className="h-fit    w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl">
          <CashTransactionsContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
};

export default CashManagementTransactionsPage;
