import moment from "moment";
import { Building2, User } from "lucide-react";
import React from "react";
import { Tag, Tooltip } from "antd";
import { Clients } from "../../../../../Types";

export const ClientsListingColumns = (t: any) => [
  {
    title: t("categorie"),
    dataIndex: "clientType",
    align: "center",
    key: "clientType",
    render: (value: any) => {
      return (
        <Tooltip title={t(value).toUpperCase()}>
          <div className="  w-full flex items-center justify-center">
            {value === "PP" ? (
              <User size={16} strokeWidth={2.25} color="#2980b9" />
            ) : (
              <Building2 size={16} strokeWidth={2.25} color="#e67e22" />
            )}
          </div>
        </Tooltip>
      );
    },
  },
  {
    title: `${t("nomCommercialFr")} / ${t("fullName")}`,
    dataIndex: "clientType",
    key: "denomination",
    align: "center",
    render: (value: string, record: Clients) => {
      return value === "PP" ? record.fullName : record.denomination;
    },
  },

  {
    title: t("idType"),
    dataIndex: "idType",
    key: "idType",
    align: "center",
    render: (value: any) => t(value),
  },

  {
    title: t("nationality"),
    dataIndex: "nationality",
    key: "nationality",
    align: "center",
    render: (value: any) => (
      <Tag color={value === "TUNISIENNE" ? "geekblue" : "volcano"}>
        {t(value).toUpperCase()}
      </Tag>
    ),
  },
];

export const ClientsExpendableListingColumns = (t: any) => {
  return [
    {
      title: t("matricule_fiscal"),
      dataIndex: "vatNumber",
      key: "vatNumber",
      render: (value: any) =>
        value || (
          <span className="w-full flex justify-center text-orange-700 font-bold">
            -
          </span>
        ),
    },
    {
      title: t("idNumber"),
      dataIndex: "idNumber",
      key: "idNumber",
      render: (value: any) =>
        value || (
          <span className="w-full flex justify-center text-orange-700 font-bold">
            -
          </span>
        ),
    },
    {
      title: t("deliveredDate"),
      dataIndex: "idDeliveredDate",
      key: "idDeliveredDate",
      render: (value: any) =>
        (value && moment(value).format("DD/MM/YYYY")) || (
          <span className="w-full flex justify-center text-orange-700 font-bold">
            -
          </span>
        ),
    },
    {
      title: t("dateOfBirth"),
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (value: any) =>
        (value && moment(value).format("DD/MM/YYYY")) || (
          <span className="w-full flex justify-center text-orange-700 font-bold">
            -
          </span>
        ),
    },
    {
      title: t("address"),
      dataIndex: "address",
      key: "address",
      width: 300,
      render: (value: any) =>
        value || (
          <span className="w-full flex justify-center text-orange-700 font-bold">
            -
          </span>
        ),
    },
    {
      title: t("city"),
      dataIndex: "city",
      key: "city",
      render: (value: any) =>
        value || (
          <span className="w-full flex justify-center text-orange-700 font-bold">
            -
          </span>
        ),
    },
    {
      title: t("agence"),
      dataIndex: "agence",
      key: "agence",
      render: (value: any) =>
        value?.agenceName || (
          <span className="w-full flex justify-center text-orange-700 font-bold">
            -
          </span>
        ),
    },
  ];
};
