import React, { useState } from "react";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import { Field, Form as FormikForm, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { Axios } from "../../../../../../Config";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { Fleet } from "../../../../../../Types";
import FormItem from "antd/es/form/FormItem";
import { enumsToSelectOptions } from "../../../../../../Utils";
import TextArea from "antd/es/input/TextArea";
import UploadWithDrag from "../../../../../UI/UploadWithDrag";

const initialValues = {
  accident_dateTime: moment.now(),
  accident_site: null,
  circumstances: null,
  circumstances_adverse: null,
  visible_damage_adverse: null,
  visible_damage: null,
  comments: null,
  accidentReportFileID: null,
  rentContractID: null,
  insurancesID: null,
};
const AddClaimForm = ({ fleet }: { fleet: Fleet }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [btnIsLoading, setBtnIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const { RentContract, Insurances } = fleet;
  const circumstancesList = [
    "1_STATIONNEMENT",
    "2_QUITTE_STATIONNEMENT",
    "3_PREND_STATIONNEMENT",
    "4_SORT_PARKING_PRIVÉ",
    "5_ENTRE_PARKING_PRIVÉ",
    "6_ARRÊT",
    "7_FROTTEMENT",
    "8_HEURT_ARRIÈRE",
    "9_MÊME_SENS_DIFF_FILE",
    "10_CHANGE_FILE",
    "11_DOUBLE",
    "12_DROITE",
    "13_GAUCHE",
    "14_RECULE",
    "15_SENS_INVERSE",
    "16_DROITE_CARREFOUR",
    "17_PAS_PRIORITÉ",
  ];
  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      setBtnIsLoading(true);
      const data = {
        ...values,
        fleetID: fleet.fleetID,
        comments: [{ content: values.comments }],
      };

      console.log(data);
      await Axios.post("/fleet-claims", data);
      mutate("/enterprise/fleet").then(() => {
        formikHelpers.setValues(initialValues);
        toast.success(t("claim_record_added_successfully"));
        setBtnIsLoading(false);
        setOpenModal(false);
      });
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  return (
    <>
      <div className="w-full flex gap-4 flex-col md:flex-row   justify-end">
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          onClick={() => {
            setOpenModal(true);
          }}
        >
          {t("add_claim").toUpperCase()}
        </Button>
      </div>
      {openModal ? (
        <Modal
          width={isSmallDevice ? "100% " : "40%"}
          title={t("add_claim").toUpperCase()}
          footer={null}
          open={openModal}
          style={{ top: 20 }}
          destroyOnClose={true}
          onClose={() => {
            setOpenModal(false);
          }}
          onCancel={() => {
            setOpenModal(false);
          }}
        >
          <div className="p-6 h-[600px] max-h-[600px] overflow-auto">
            <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
              {({ setFieldValue, handleSubmit }) => (
                <Form
                  layout="vertical"
                  onFinish={handleSubmit}
                  component={FormikForm}
                >
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        label={t("accident_site")}
                        name="accident_site"
                        className="w-full"
                      >
                        <Field
                          name="accident_site"
                          placeholder={t("accident_site")}
                          as={Input}
                          onChange={(value: any) =>
                            setFieldValue("accident_site", value.target.value)
                          }
                          className="w-full"
                        />
                      </Form.Item>

                      <Form.Item
                        label={t("accident_date_time")}
                        name="accident_dateTime"
                      >
                        <DatePicker
                          value={moment(initialValues.accident_dateTime)}
                          className="w-full"
                          format="DD/MM/YYYY HH:mm"
                          showTime
                          maxDate={dayjs(new Date())}
                          onChange={(date) =>
                            setFieldValue("accident_dateTime", date)
                          }
                        />
                      </Form.Item>

                      <FormItem label={t("circumstances")} name="circumstances">
                        <Select
                          showSearch
                          mode="multiple"
                          placeholder={t("circumstances")}
                          optionFilterProp="children"
                          value={initialValues.circumstances || ""}
                          style={{ width: "100%" }}
                          options={enumsToSelectOptions(circumstancesList)}
                          onChange={(value: any) => {
                            setFieldValue("circumstances", value);
                          }}
                        />
                      </FormItem>
                      <FormItem
                        label={t("circumstances_adverse")}
                        name="circumstances_adverse"
                      >
                        <Select
                          showSearch
                          mode="multiple"
                          placeholder={t("circumstances_adverse")}
                          optionFilterProp="children"
                          value={initialValues.circumstances_adverse || ""}
                          style={{ width: "100%" }}
                          options={enumsToSelectOptions(circumstancesList)}
                          onChange={(value: any) => {
                            setFieldValue("circumstances_adverse", value);
                          }}
                        />
                      </FormItem>
                      <FormItem label={t("rent_contracts")} name="rentContract">
                        <Select
                          placeholder={t("rent_contracts")}
                          optionFilterProp="children"
                          value={initialValues.rentContractID || ""}
                          style={{ width: "100%" }}
                          options={RentContract.map((contract) => {
                            return {
                              value: contract.rentContractID,
                              label: `${contract.client.fullName || contract.client.denomination} | ${moment(contract.startDate).format("DD/MM/YYYY")} => ${moment(contract.endDate).format("DD/MM/YYYY")}`,
                            };
                          })}
                          onChange={(value: any) => {
                            setFieldValue("rentContractID", value);
                          }}
                        />
                      </FormItem>
                      <FormItem label={t("insurance")} name="insurance">
                        <Select
                          placeholder={t("insurance")}
                          optionFilterProp="children"
                          value={initialValues.insurancesID || ""}
                          style={{ width: "100%", height: "fit-content" }}
                          options={Insurances.map((insurance) => {
                            return {
                              value: insurance.assuranceID,
                              label: (
                                <div className="flex flex-col  ">
                                  <span>
                                    {`${t("contract_cancelled")} : ${t(insurance.contract_cancelled ? "yes" : "no")}`}
                                  </span>
                                  <span>
                                    {`${t("num_policy")} : ${insurance.num_policy}`}
                                  </span>
                                  <span>
                                    {`${t("num_contract")} : ${insurance.num_contract}`}
                                  </span>
                                  <span>
                                    {" "}
                                    {`${t("insurer_name")} : ${insurance.insurer_name} `}
                                  </span>
                                </div>
                              ),
                            };
                          })}
                          onChange={(value: any) => {
                            setFieldValue("insurancesID", value);
                          }}
                        />
                      </FormItem>
                      <Form.Item
                        label={t("visible_damage")}
                        name="visible_damage"
                        className="w-full"
                      >
                        <Field
                          name="visible_damage"
                          placeholder={t("visible_damage")}
                          as={TextArea}
                          onChange={(value: any) =>
                            setFieldValue("visible_damage", value.target.value)
                          }
                          className="w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("visible_damage_adverse")}
                        name="visible_damage_adverse"
                        className="w-full"
                      >
                        <Field
                          name="visible_damage_adverse"
                          placeholder={t("visible_damage_adverse")}
                          as={TextArea}
                          onChange={(value: any) =>
                            setFieldValue(
                              "visible_damage_adverse",
                              value.target.value,
                            )
                          }
                          className="w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("comment")}
                        name="comment"
                        className="w-full"
                      >
                        <Field
                          name="comment"
                          placeholder={t("comment")}
                          as={TextArea}
                          onChange={(value: any) =>
                            setFieldValue("comments", value.target.value)
                          }
                          className="w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("accident_report_file_copy")}
                        name="accident_report_file_copy"
                      >
                        <UploadWithDrag
                          maxCount={1}
                          setFileValue={(file) =>
                            setFieldValue("accidentReportFileID", file.fileID)
                          }
                          uploadText={t(
                            "insurance_policy_file_copy",
                          ).toUpperCase()}
                          hint={t("add_accident_report_copy")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <Button
                      loading={btnIsLoading}
                      type="primary"
                      htmlType="submit"
                      className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white w-full "
                    >
                      {t("add_claim")}
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default AddClaimForm;
