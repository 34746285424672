import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { CompanyDriversColumns } from "./CompanyDriversColumns";
import { useCompanyDriversData } from "../../../../../Hooks/useCompanyDriversData";

export function CompanyDriversContent() {
  const { companyDrivers, isLoading, mutate } = useCompanyDriversData();
  const { t } = useTranslation();

  const columns = CompanyDriversColumns(t);

  return (
    <div className="w-full  overflow-scroll p-10">
      <Table
        rowKey="rentContractID"
        size="small"
        loading={isLoading}
        dataSource={(companyDrivers && companyDrivers) || []}
        columns={columns}
        rootClassName="h-2/3 min-h-2/3 "
        bordered={false}
      />
    </div>
  );
}
