import React, { useMemo, useRef, useState } from "react";
import { Modal, Radio, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useCompanyWtcData } from "../../../../../Hooks/useCompanyWtcData";
import { CompanyUserListingColumns } from "./WtcListingColumns";
import { WTCPrintable } from "../../../../UI/Printables/WTCPrintable";
import { useMediaQuery } from "@mui/material";
import Search from "antd/es/input/Search";
import { toast } from "react-toastify";
import { Axios } from "../../../../../Config";

function WtcListingContent() {
  const { t } = useTranslation();
  const { wtc, isLoading, mutate } = useCompanyWtcData();
  const [selectedWtc, setSelectedWtc] = useState(null);
  const [search, setSearch] = useState("");
  const [searchStatus, setSearchStatus] = useState<"ALL" | "pp" | "pm">("ALL");

  const dataToDisplay = useMemo(() => {
    let filterWtcs = wtc || [];

    if (searchStatus === "ALL") {
      filterWtcs = wtc;
    }

    if (searchStatus === "pp") {
      filterWtcs = filterWtcs.filter((certif) => {
        return Boolean(certif.beneficiaryType === "PP");
      });
    }
    if (searchStatus === "pm") {
      filterWtcs = filterWtcs.filter((certif) => {
        return Boolean(certif.beneficiaryType === "PM");
      });
    }

    return search
      ? filterWtcs.filter((certif) => {
          return Boolean(
            certif.beneficiaryName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
              certif.beneficiaryIdentifier
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              certif.withholdingObject
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              certif.withholdingTaxCertificate
                ?.toLowerCase()
                .includes(search.toLowerCase()),
          );
        })
      : filterWtcs;
  }, [wtc, search, searchStatus]);

  const showCertificate = (withholdingTaxCertificate: string) => {
    setSelectedWtc(
      wtc.filter(
        (certificate) =>
          certificate.withholdingTaxCertificate === withholdingTaxCertificate,
      )[0],
    );
  };

  const asyncToast = useRef<any>();

  const deleteCertificate = async (withholdingTaxCertificate: string) => {
    try {
      asyncToast.current = toast.loading(
        t("DELETING_CERTIFICAT_ONGOING_MESSAGE"),
      );

      await Axios.delete(`/wtc/company/${withholdingTaxCertificate}`);
      mutate();
      toast.update(asyncToast.current, {
        render: t("CERTIFICAT_DELETED_SUCCESS_MESSAGE"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (e: any) {
      toast.update(asyncToast.current, {
        render: t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const handleCloseModal = () => {
    setSelectedWtc(null);
  };
  const columns = CompanyUserListingColumns(
    t,
    showCertificate,
    deleteCertificate,
  );
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <div className="w-full  overflow-scroll p-10">
      {selectedWtc ? (
        <Modal
          open={selectedWtc}
          width={isSmallDevice ? "100% " : "80%"}
          onClose={() => handleCloseModal()}
          onCancel={() => handleCloseModal()}
          footer={null}
        >
          <WTCPrintable wtc={selectedWtc} mutate={mutate} />
        </Modal>
      ) : null}
      <div className="flex gap-4 mb-4 justify-between flex-col md:flex-row min-w-fit">
        <div className="flex gap-4">
          <Search
            placeholder={t("search")}
            allowClear
            disabled={isLoading}
            onSearch={(value) => {
              setSearch(value);
            }}
            style={{ width: 304 }}
          />
        </div>
        <Radio.Group
          disabled={isLoading}
          value={searchStatus}
          onChange={(e) => setSearchStatus(e.target.value)}
        >
          <Radio.Button value="ALL">{t("ALL")}</Radio.Button>
          <Radio.Button value="pp">{t("PP")}</Radio.Button>
          <Radio.Button value="pm">{t("PM")}</Radio.Button>
        </Radio.Group>
      </div>
      <Table
        rowKey="withholdingTaxCertificate"
        size="small"
        loading={isLoading}
        dataSource={dataToDisplay}
        columns={columns}
        rootClassName="h-2/3 min-h-2/3 "
        bordered={false}
      />
    </div>
  );
}

export default WtcListingContent;
