import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Users } from "../../../../Types";
import moment from "moment";
import TollIcon from "@mui/icons-material/Toll";
import { useCompaniesStatsData } from "../../../../Hooks/useCompaniesStatsData";
import { Skeleton } from "antd";

function CompanyDashboardGreeting() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const user = useSelector((state: any) => state.authentication.user) as Users;
  const { companiesStats, isLoading: isCompaniesStatsLoading } =
    useCompaniesStatsData();

  const getGreeting = useMemo(() => {
    const currentHour = moment().hours();

    if (currentHour >= 5 && currentHour < 12) {
      return t("good_morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      return t("good_afternoon");
    } else {
      return t("good_evening");
    }
  }, [language, t]);

  return (
    <header className="w-full">
      <div className="mx-auto  px-4 py-8 sm:px-6  lg:px-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div
            className={
              language === "ar"
                ? "text-center sm:text-right"
                : "text-center sm:text-left"
            }
          >
            <p className="mb-4 text-gray-500">
              {moment()
                .format("dddd, DD MMMM YYYY")
                .replace(/\b\w/g, (l) => l.toUpperCase())}
            </p>

            <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
              {getGreeting}, {user?.name} {user?.lastName}
            </h1>

            <p className="mt-1.5  text-gray-700">
              {language === "ar"
                ? user.CompaniesUsers[0].companyID.rsArabic
                : user.CompaniesUsers[0].companyID.rsLatin}
            </p>
            {user.AgencesUsers.length ? (
              <p className="mt-1.5 text-xs text-gray-500">
                {t("agenceName").toUpperCase()} :{" "}
                {user.AgencesUsers[0].agenceID.agenceName}
              </p>
            ) : null}
          </div>

          <div className="mt-4 flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-center">
            <article className="rounded-lg border border-gray-100 p-6 w-full sm:max-w-xs">
              {companiesStats && !isCompaniesStatsLoading ? (
                <div className="w-full flex flex-col items-center justify-center">
                  <p className="text-sm text-gray-500 text-center">
                    {t("account_points")}
                  </p>

                  <p className="text-2xl font-medium text-gray-900 flex items-center">
                    <TollIcon className="mr-2" />
                    {companiesStats ? (
                      String(companiesStats.point)
                    ) : (
                      <Skeleton />
                    )}
                  </p>
                </div>
              ) : null}
            </article>
          </div>
        </div>
      </div>
    </header>
  );
}

export default CompanyDashboardGreeting;
