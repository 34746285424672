import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import ReservationListingContent from "../../Components/Features/CompanyComponents/ReservationManagement/ReservationListing/ReservationListingContent";

const ReservationListingPage = () => {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("reservation_listing")}>
        <div className="h-fit   w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl">
          <ReservationListingContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
};

export default ReservationListingPage;
