import React from "react";

import { useTranslation } from "react-i18next";
import { Descriptions, DescriptionsProps, Tag } from "antd";
import { useMediaQuery } from "@mui/material";
import { InsuranceClaim } from "../../../../../../Types";

interface ClaimsInfosProps {
  InsuranceClaim: InsuranceClaim | null;
}

const ClaimsInfos: React.FC<ClaimsInfosProps> = ({ InsuranceClaim }) => {
  const { t } = useTranslation();

  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: t("circumstances"),
      children: InsuranceClaim?.circumstances.map((item) => {
        return <Tag color="cyan">{t(item)}</Tag>;
      }),
      span: 4,
    },
    {
      key: "2",
      label: t("circumstances_adverse"),
      children: InsuranceClaim?.circumstances_adverse.map((item) => {
        return <Tag color="cyan">{t(item)}</Tag>;
      }),
      span: 4,
    },
    {
      key: "3",
      label: t("visible_damage"),
      children: InsuranceClaim?.visible_damage,
      span: 4,
    },
    {
      key: "4",
      label: t("visible_damage_adverse"),
      children: InsuranceClaim?.visible_damage_adverse,
      span: 4,
    },
  ];

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <Descriptions
      title={null}
      layout={"vertical"}
      items={items}
      size="small"
      bordered={true}
      column={isSmallDevice ? 4 : 8}
    />
  );
};

export default ClaimsInfos;
