import { SVGProps } from "react";

function BerlineSvg(props: SVGProps<any>) {
  return (
    <svg
      {...props}
      viewBox="0 0 1000 227.963"
      enableBackground="new 0 0 1000 227.963"
    >
      <g>
        <path
          fill="#BABABA"
          d="M88.278,152.5c-20.839,0-37.713,16.898-37.713,37.75c0,1.604,0.12,3.183,0.314,4.736   c2.35,18.595,18.181,32.977,37.398,32.977c19.735,0,35.922-15.172,37.578-34.506c0.068-1.061,0.145-2.122,0.145-3.207   C126.001,169.398,109.127,152.5,88.278,152.5L88.278,152.5z M69.385,176.016l7.697,7.716c-0.592,1.018-1.061,2.121-1.381,3.306   H64.867C65.438,182.923,66.992,179.174,69.385,176.016L69.385,176.016z M64.818,193.574H75.75c0.324,1.166,0.765,2.251,1.384,3.263   l-7.725,7.715C67.017,201.419,65.413,197.676,64.818,193.574L64.818,193.574z M85.028,213.686   c-4.102-0.567-7.802-2.177-10.959-4.545l7.675-7.672c1.008,0.598,2.122,1.011,3.284,1.307V213.686z M85.028,177.688   c-1.188,0.32-2.301,0.789-3.337,1.405l-7.69-7.696c3.158-2.393,6.901-3.99,11.027-4.558V177.688z M91.563,166.839   c4.092,0.567,7.845,2.165,11.002,4.558l-7.672,7.696c-1.036-0.616-2.149-1.085-3.33-1.405V166.839z M91.563,213.686v-10.885   c1.156-0.321,2.242-0.734,3.253-1.332l7.673,7.672C99.358,211.509,95.63,213.088,91.563,213.686L91.563,213.686z M107.175,204.576   l-7.743-7.739c0.62-1.012,1.113-2.097,1.409-3.263h10.848C111.104,197.65,109.568,201.443,107.175,204.576L107.175,204.576z    M100.86,187.037c-0.315-1.185-0.784-2.288-1.378-3.306l7.693-7.716c2.394,3.158,3.972,6.907,4.515,11.021H100.86z M100.86,187.037   "
        />
        <g>
          <path
            fill="#BABABA"
            d="M401.558,152.5c-20.821,0-37.72,16.898-37.72,37.75c0,1.604,0.123,3.183,0.346,4.736    c2.318,18.595,18.156,32.977,37.374,32.977c19.76,0,35.943-15.172,37.571-34.506c0.099-1.061,0.173-2.122,0.173-3.207    C439.302,169.398,422.403,152.5,401.558,152.5L401.558,152.5z M382.686,176.016l7.672,7.716c-0.592,1.018-1.061,2.121-1.356,3.306    h-10.854C378.714,182.923,380.293,179.174,382.686,176.016L382.686,176.016z M378.121,193.574h10.929    c0.296,1.166,0.765,2.251,1.357,3.263l-7.697,7.715C380.311,201.419,378.689,197.676,378.121,193.574L378.121,193.574z     M398.301,213.686c-4.095-0.567-7.795-2.177-10.953-4.545l7.672-7.672c1.012,0.598,2.122,1.011,3.281,1.307V213.686z     M398.301,177.688c-1.184,0.32-2.294,0.789-3.33,1.405l-7.697-7.696c3.158-2.393,6.908-3.99,11.027-4.558V177.688z     M404.839,166.839c4.119,0.567,7.869,2.165,11.026,4.558l-7.696,7.672c-1.036-0.592-2.146-1.085-3.33-1.381V166.839z     M404.839,213.686v-10.885c1.159-0.321,2.27-0.734,3.256-1.332l7.697,7.672C412.634,211.509,408.909,213.088,404.839,213.686    L404.839,213.686z M420.454,204.576l-7.722-7.739c0.592-1.012,1.086-2.097,1.406-3.263h10.83    C424.401,197.65,422.848,201.443,420.454,204.576L420.454,204.576z M414.139,187.037c-0.296-1.185-0.765-2.288-1.356-3.306    l7.696-7.716c2.369,3.158,3.947,6.907,4.515,11.021H414.139z M414.139,187.037"
          />
          <path
            fill="#BABABA"
            d="M492.168,132.986v-27.926c0-4.483-3.281-8.313-7.697-9.004c-10.656-1.653-29.529-4.811-37.99-7.426    c-12.162-3.75-59.133-23.634-75.39-27.358c-16.282-3.726-89.624-23.683-159.882,0.69c-11.869,4.126-65.346,29.678-96.803,42.975    c-9.251,0.197-100.919,19.297-105.807,32.391c-4.882,13.1-6.411,19.47-7.302,22.794c-0.916,3.313,0,19.218,6.809,30.294    c6.935,3.947,20.303,6.563,35.746,8.339c-0.173-0.938-0.367-1.869-0.494-2.813c-0.247-2.041-0.392-3.923-0.392-5.692    c0-24.99,20.321-45.324,45.312-45.324s45.321,20.334,45.321,45.324c0,1.283-0.102,2.56-0.198,3.817    c-0.219,2.794-0.736,5.526-1.458,8.141h0.102l224.91-4.532c-0.099-0.567-0.222-1.135-0.296-1.733    c-0.271-2.041-0.395-3.923-0.395-5.692c0-24.966,20.327-45.293,45.293-45.293c24.99,0,45.317,20.327,45.317,45.293    c0,0.093,0,0.166,0,0.271l0.962-0.08l38.435-7.247c0,0,14.729-7.826,14.729-29.412C501,139.406,492.168,132.986,492.168,132.986    L492.168,132.986z M269.059,106.516l-83.996,2.566c1.554-12.582-5.899-15.419-5.899-15.419    c33.378-30.294,100.676-31.33,100.676-31.33L269.059,106.516z M363.32,102.674l-76.549,2.56l6.957-42.9    c39.545-0.518,62.143,8.222,62.143,8.222l11.298,18.249L363.32,102.674z M406.467,102.402h-21.314l-21.314-29.289    c15.986,6.365,28.789,12.656,36.412,16.628C404.888,92.14,407.404,97.265,406.467,102.402L406.467,102.402z M406.467,102.402"
          />
        </g>
      </g>
    </svg>
  );
}

export default BerlineSvg;
