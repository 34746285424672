import { ColumnGroupType, ColumnType } from "antd/es/table";
import { RentReservation } from "./interfaces";
import { Tag } from "antd";
import React from "react";
import moment from "moment";
import { RentReservationAdvances } from "../../../../../Types";

export const RentReservationListingColumns: (
  t: any,
) => (ColumnType<RentReservation> | ColumnGroupType<RentReservation>)[] = (
  t,
) => [
  {
    title: t("start_date"),
    dataIndex: "startDate",
    key: "startDate",
    align: "center",
    render: (value: Date) => moment(value).format("DD/MM/YYYY HH:MM"),
  },
  {
    title: t("end_date"),
    dataIndex: "endDate",
    key: "endDate",
    align: "center",
    render: (value: Date) => moment(value).format("DD/MM/YYYY HH:MM"),
  },
  {
    title: t("totalPrice"),
    dataIndex: "totalPrice",
    key: "totalPrice",
    align: "center",
    render: (value: string) => `${Number(value).toFixed(3)} TND`,
  },
  {
    title: t("pickupLocation"),
    dataIndex: "pickupLocation",
    align: "center",
    key: "pickupLocation",
  },
  {
    title: t("dropOffLocation"),
    dataIndex: "dropOffLocation",
    key: "dropOffLocation",
    align: "center",
  },
  {
    title: t("clientName"),
    dataIndex: "client",
    key: "clientName",
    align: "center",
    render: (value) => value.fullName || value.denomination,
  },
  {
    title: t("additionalNotes"),
    dataIndex: "additionalNotes",
    key: "additionalNotes",
    align: "center",
  },
  {
    title: t("status"),
    dataIndex: "status",
    key: "status",
    align: "center",
    render: (value) => {
      if (value === "CANCELLED") {
        return (
          <div className="w-full flex justify-center items-center">
            <Tag color="red">{t("CANCELED")}</Tag>
          </div>
        );
      }

      if (value === "PENDING") {
        return (
          <div className="w-full flex justify-center items-center">
            <Tag color="yellow">{t("PENDING")}</Tag>
          </div>
        );
      }
      if (value === "CONFIRMED") {
        return (
          <div className="w-full flex justify-center items-center">
            <Tag color="orange">{t("CONFIRMED")}</Tag>
          </div>
        );
      }

      if (value === "COMPLETED") {
        return (
          <div className="w-full flex justify-center items-center">
            <Tag color="green">{t("COMPLETED")}</Tag>
          </div>
        );
      }
    },
  },
];

export const RentReservationAdvancesColumns: (
  t: any,
) => (
  | ColumnGroupType<RentReservationAdvances[]>
  | ColumnType<RentReservationAdvances>
)[] = (t) => [
  {
    title: t("amount"),
    dataIndex: "amount",
    key: "amount",
    render: (value: number) => `${Number(value).toFixed(3)} TND`,
  },
  {
    title: t("Type"),
    dataIndex: "method",
    key: "method",
    render: (value: string) => t(value),
  },
  {
    title: t("status"),
    dataIndex: "status",
    key: "status",
    render: (value: string) => t(value),
  },
];
