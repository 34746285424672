import "../CompanyComponents/DashboardContent/calander.css";
import {Tabs} from "antd";
import {CalculatorOutlined, CalendarOutlined, InsertRowBelowOutlined,} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import CompanyDashboardCalculator from "../CompanyComponents/DashboardContent/CompanyDashboardCalculator";
import CompanyStats from "../CompanyComponents/DashboardContent/CompanyStats";
import {CompanyDashboardPlanning} from "../CompanyComponents/DashboardContent/CompanyDashboardPlanning";
import {CompanyDashboardReturnOnDelay} from "../CompanyComponents/DashboardContent/CompanyDashboardReturnOnDelay";
import {CompanyDashboardCalender} from "../CompanyComponents/DashboardContent/CompanyDashboardCalender";

export const DashboardCompany = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  /*  {
    Component: CompanyDashboardAvailability,
        Icon: CarOutlined,
      label: t("rent_availability"),
  },*/
  const tabs = [
    {
      Component: CompanyDashboardPlanning,
      Icon: InsertRowBelowOutlined,
      label: t("returns_planning").toUpperCase(),
    },
    {
      Component: CompanyDashboardReturnOnDelay,
      Icon: InsertRowBelowOutlined,
      label: t("RETURN_CALENDAR").toUpperCase(),
    },
    {
      Component: CompanyDashboardCalculator,
      Icon: CalculatorOutlined,
      label: t("rent_calculator").toUpperCase(),
    },
    {
      Component: CompanyDashboardCalender,
      Icon: CalendarOutlined,
      label: t("rent_calender").toUpperCase(),
    },
  ];
  return (
    <div className="  w-full flex-col flex max-w-full  h-fit  rounded-2xl p-4 md:p-8">
      <CompanyStats />
      <Tabs
        direction={language === "ar" ? "rtl" : "ltr"}
        defaultActiveKey="0"
        items={tabs.map(({ Icon, label, Component }, i) => {
          return {
            key: String(i),
            label: label,
            children: <Component />,
            icon: <Icon />,
          };
        })}
      />
    </div>
  );
};
