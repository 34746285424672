import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import { InsuranceClaim } from "../../../../../../Types";
import moment from "moment/moment";
import { Tag } from "antd";

export const FleetClaimsColumns: (
  t: any,
) => (ColumnGroupType<InsuranceClaim> | ColumnType<InsuranceClaim>)[] = (t) => {
  return [
    {
      title: t("date_of_creations"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (date: Date) => {
        return moment(date).format("DD/MM/YYYY HH:MM");
      },
    },
    {
      title: t("accident_date_time"),
      dataIndex: "accident_dateTime",
      key: "accident_dateTime",
      align: "center",
      render: (date: Date) => {
        return moment(date).format("DD/MM/YYYY HH:MM");
      },
    },
    {
      title: t("accident_site"),
      dataIndex: "accident_site",
      key: "accident_site",
      align: "justify",
      width: 200,
    },
    {
      title: t("status"),
      dataIndex: "status",
      align: "center",
      key: "status",
      width: 100,
      render: (value) => {
        let color = "blue";
        if (value === "SUBMITTED") {
          color = "green";
        } else if (value === "CLOSED") {
          color = "red";
        }
        return <Tag color={color}>{t(value).toUpperCase()}</Tag>;
      },
    },
  ];
};
