import React from "react";
import { Clients } from "../../../../../Types";
import { useTranslation } from "react-i18next";
import { BookOpenText } from "lucide-react";
import { Descriptions, DescriptionsProps, Tag } from "antd";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import AddDriverFromClientModal from "../../../../UI/addDriverFromClientModal";

interface ClientGeneralInfosProps {
  client: Clients;
}

const ClientGeneralInfos: React.FC<ClientGeneralInfosProps> = ({ client }) => {
  const { t } = useTranslation();

  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: `${t(client?.clientType === "PP" ? "fullName" : "nomCommercialFr")}`,
      children: client?.denomination || client?.fullName,
      span: 3,
    },
    {
      key: "2",
      label: `${t("idType")}`,
      children: client?.idType,
      span: 2,
    },
    {
      key: "3",
      label: `${t(client?.clientType === "PP" ? "idNumber" : "matricule_fiscal")}`,
      children: client?.idNumber || client?.vatNumber,
      span: 2,
    },
    ...(client?.clientType === "PP"
      ? [
          {
            key: "90",
            label: `${t("dateOfBirth")}`,
            children: moment(client?.dateOfBirth).format("DD/MM/YYYY"),
            span: 3,
          },
        ]
      : []),
    {
      key: "4",
      label: `${t("address")}`,
      span: client?.clientType === "PP" ? 5 : 7,
      children: `${client?.address} ${client?.city} `,
    },
    {
      key: "5",
      label: `${t("auth_email")}`,
      children: client?.email ? (
        <a
          href={`mailto:${client?.email}`}
          className="!text-black !focus:text-black !hover:text-black "
        >
          {client?.email}
        </a>
      ) : (
        client?.email
      ),
      span: 3,
    },
    {
      key: "6",
      label: `${t("phone")}`,
      children: client?.phone,
      span: 2,
    },
    {
      key: "7",
      label: `${t("client_since")}`,
      children: moment(client?.createdAt).fromNow(true),
      span: 2,
    },
  ];

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <Descriptions
      title={
        <div className=" flex items-center justify-between  !text-[16px] gap-4 text-[#d35400]">
          <div className="gap-4 flex items-center">
            <BookOpenText size={20} color="#000000" />
            <span> {t("general_information").toUpperCase()}</span>
          </div>
          {client?.isDriver ? (
            <Tag color="green">{t("registered_driver").toUpperCase()}</Tag>
          ) : client?.clientType === "PP" ? (
            <AddDriverFromClientModal client={client} />
          ) : null}
        </div>
      }
      layout={"vertical"}
      items={items}
      size="small"
      column={isSmallDevice ? 3 : 7}
    />
  );
};

export default ClientGeneralInfos;
