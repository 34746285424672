import React, { useMemo, useState } from "react";
import { useReservationsData } from "../../../../../Hooks/useReservationsData";
import { useTranslation } from "react-i18next";
import {
  RentReservationAdvancesColumns,
  RentReservationListingColumns,
} from "./ReservationListingColumns";
import {
  Dropdown,
  InputNumber,
  MenuProps,
  Radio,
  Table,
  Tabs,
  TabsProps,
} from "antd";
import Search from "antd/es/input/Search";
import moment from "moment/moment";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  IssuesCloseOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Axios } from "../../../../../Config";
import { toast } from "react-toastify";
import { RentReservation } from "../../../../../Types";
import CommentsSection from "../../../../UI/CommentsSection";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router";
import { GitBranchPlus } from "lucide-react";

function ReservationListingContent() {
  const { reservations, isLoading, mutate } = useReservationsData();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [searchStatus, setSearchStatus] = useState<
    "ALL" | "TODAY" | "TOMORROW" | "AFTER_TOMORROW"
  >("ALL");

  const columns = RentReservationListingColumns(t);

  const dataToDisplay = useMemo(() => {
    let filteredReservations = reservations || [];
    const tomorrow = moment().add(1, "days").startOf("day");
    const dayAfterTomorrow = moment().add(2, "days").startOf("day");
    if (searchStatus === "ALL") {
      filteredReservations = reservations;
    }
    if (searchStatus === "TODAY") {
      filteredReservations = filteredReservations.filter((reservation) => {
        return moment().isSame(reservation.startDate, "day");
      });
    }

    if (searchStatus === "TOMORROW") {
      filteredReservations = filteredReservations.filter((reservation) => {
        return moment(reservation.startDate).isBetween(
          tomorrow,
          dayAfterTomorrow,
          null,
          "[)",
        );
      });
    }

    if (searchStatus === "AFTER_TOMORROW") {
      filteredReservations = filteredReservations.filter((reservation) => {
        return moment(reservation.startDate).isSameOrAfter(
          dayAfterTomorrow,
          "day",
        );
      });
    }

    return search
      ? filteredReservations.filter((reservation) => {
          return Boolean(
            reservation.pickupLocation
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
              reservation.dropOffLocation
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              reservation.client.denomination
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              reservation.additionalNotes
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              reservation.client.fullName
                ?.toLowerCase()
                .includes(search.toLowerCase()),
          );
        })
      : filteredReservations;
  }, [reservations, search, searchStatus]);

  const changeReservationStatus = async (
    RentReservationId: string,
    status: string,
  ) => {
    try {
      const reservationStatus = await Axios.put("/reservation", {
        RentReservationId,
        status,
      }).then((res) => res.data.message);

      await mutate();

      toast.success(t(reservationStatus["RESPONSE_CODE"]));
    } catch (error: any) {
      toast.error(
        t(
          error?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  const deleteReservation = async (RentReservationId: string) => {
    try {
      const reservationStatus = await Axios.delete("/reservation", {
        params: { RentReservationId },
      }).then((res) => res.data.message);

      await mutate();

      toast.success(t(reservationStatus["RESPONSE_CODE"]));
    } catch (error: any) {
      toast.error(
        t(
          error?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  columns.push({
    title: t("actions"),
    dataIndex: "RentReservationId",
    key: "actions",
    align: "center",
    render: (value: string, record) => {
      let items: MenuProps["items"] = [];

      if (record.status === "PENDING") {
        items = [
          {
            label: (
              <span onClick={() => changeReservationStatus(value, "CONFIRMED")}>
                {t("confirm_reservation")}
              </span>
            ),
            icon: <CheckCircleOutlined />,
            key: "0",
          },
          {
            label: (
              <span onClick={() => changeReservationStatus(value, "COMPLETED")}>
                {t("end_reservation")}
              </span>
            ),
            icon: <MinusCircleOutlined />,
            key: "1",
          },
          {
            label: (
              <span onClick={() => changeReservationStatus(value, "CANCELLED")}>
                {t("cancel_reservation")}
              </span>
            ),
            icon: <CloseCircleOutlined />,
            key: "2",
          },
        ];
      }
      if (record.status === "CONFIRMED") {
        items = [
          {
            label: (
              <span onClick={() => changeReservationStatus(value, "COMPLETED")}>
                {t("end_reservation")}
              </span>
            ),
            icon: <MinusCircleOutlined />,
            key: "1",
          },
          {
            label: (
              <span onClick={() => changeReservationStatus(value, "CANCELLED")}>
                {t("cancel_reservation")}
              </span>
            ),
            icon: <CloseCircleOutlined />,
            key: "2",
          },
        ];
      }

      if (record.status === "COMPLETED") {
        items = [
          {
            label: <span>{t("no_action_to_taken")}</span>,

            key: "5",
          },
        ];
      }

      return (
        <div className="flex mx-auto gap-4 items-center">
          <GitBranchPlus
            size={16}
            className="cursor-pointer"
            onClick={() => {
              const searchParams = new URLSearchParams();
              searchParams.append(
                "RentReservationId",
                record.RentReservationId,
              );
              searchParams.append("startDate", record.startDate);
              searchParams.append("endDate", record.endDate);
              searchParams.append("extraDescription", record.extraDescription);
              searchParams.append("extraPrice", record.extraPrice);
              searchParams.append("ClientID", record.ClientID);

              navigate(
                `/rent_management/add_newRent?${searchParams.toString()}`,
              );
            }}
          />
          <Dropdown menu={{ items }} trigger={["click"]}>
            <IssuesCloseOutlined />
          </Dropdown>
        </div>
      );
    },
  });

  const expendableColumns = RentReservationAdvancesColumns(t);

  function reservationExpendable(record: RentReservation) {
    const items: TabsProps["items"] = [
      {
        key: "1",
        label: t("advances"),
        children: (
          <Table
            columns={expendableColumns}
            dataSource={record.RentReservationAdvances}
            rowKey="RentReservationAdvancesId"
            pagination={false}
            showHeader={true}
          />
        ),
      },
      {
        key: "2",
        label: t("reservation_comments"),
        children: (
          <div className="w-full my-6">
            <CommentsSection
              comments={record.comments}
              identifier="RESERVATION"
              identifierValue={record.RentReservationId}
            />
          </div>
        ),
      },
      ...(record.extraDescription
        ? [
            {
              key: "3",
              label: t("EXTRA_SERVICES"),
              children: (
                <div>
                  <FormItem label={t("extra_description")}>
                    <TextArea
                      className="w-full"
                      disabled
                      placeholder={t("extra_description")}
                      value={record?.extraDescription || ""}
                      rows={4}
                    />
                  </FormItem>
                  <FormItem label={t("extra_price")}>
                    <InputNumber
                      className="w-full"
                      placeholder={t("extra_price")}
                      controls={false}
                      disabled
                      defaultValue={record?.extraPrice || 0}
                      addonAfter={<div>{t("TND")}</div>}
                    />
                  </FormItem>
                </div>
              ),
            },
          ]
        : []),
    ];

    return (
      <div className="p-4">
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    );
  }

  return (
    <div className="w-full  overflow-scroll p-10">
      <div className="flex gap-4 mb-4 justify-between flex-col md:flex-row min-w-fit">
        <div className="flex gap-4">
          <Search
            placeholder={t("search")}
            allowClear
            disabled={isLoading}
            onSearch={(value) => {
              setSearch(value);
            }}
            style={{ width: 304 }}
          />
        </div>
        <Radio.Group
          disabled={isLoading}
          value={searchStatus}
          onChange={(e) => setSearchStatus(e.target.value)}
        >
          <Radio.Button value="ALL">{t("ALL")}</Radio.Button>
          <Radio.Button value="TODAY">{t("reservation_today")}</Radio.Button>
          <Radio.Button value="TOMORROW">
            {t("reservation_tomorrow")}
          </Radio.Button>
          <Radio.Button value="AFTER_TOMORROW">
            {t("reservation_day_after_tomorrow")}
          </Radio.Button>
        </Radio.Group>
      </div>
      <Table
        loading={isLoading}
        rowKey="RentReservationId"
        dataSource={dataToDisplay}
        columns={columns}
        size="small"
        bordered={true}
        expandable={{
          expandedRowRender: reservationExpendable,
        }}
      />
    </div>
  );
}

export default ReservationListingContent;
