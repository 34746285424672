import React, { useRef } from "react";
import { Field, Form as FormikForm, Formik } from "formik";
import * as Yup from "yup";
import { Button, Form, Input, InputNumber, Select } from "antd";

import { useTranslation } from "react-i18next";
import { useEnumsData } from "../../../../../../../Hooks";
import { toast } from "react-toastify";
import { Axios } from "../../../../../../../Config";
import { mutate } from "swr";
import { t } from "i18next";

const initialValues = {
  Type: "",
  Amount: "",
  TRN: "",
  Bank: "",
};

const validationSchema = Yup.object().shape({
  Type: Yup.string().required("Type is required"),
  Amount: Yup.number().required("Amount is required"),
  TRN: Yup.string().nullable(),
});

const { Option } = Select;

const AntdSelect = ({ field, form, ...props }: any) => (
  <Select
    {...field}
    {...props}
    onChange={(value) => form.setFieldValue(field.name, value)}
  >
    {props.children}
  </Select>
);

const AntdInput = ({ field, form, ...props }: any) => (
  <Input
    {...field}
    {...props}
    onChange={(e) => form.setFieldValue(field.name, e.target.value)}
  />
);

const AntdInputNumber = ({ field, form, ...props }: any) => (
  <InputNumber
    {...field}
    {...props}
    min={0}
    controls={false}
    addonAfter={t("TND")}
    decimalSeparator={","}
    onChange={(value) => form.setFieldValue(field.name, value)}
  />
);

const banks = {
  STB: "STB",
  BNA: "BNA",
  BH: "BH",
  BFPME: "BFPME",
  BTS: "BTS",
  BTE: "BTE",
  BTL: "BTL",
  TSB: "TSB",
  Zitouna: "Zitouna",
  "Al Baraka": "Al Baraka",
  Wifak: "Wifak",
  Amen: "Amen",
  "Attijari Bank": "Attijari Bank",
  ATB: "ATB",
  ABC: "ABC",
  BIAT: "BIAT",
  BT: "BT",
  BTK: "BTK",
  BFT: "BFT",
  Citi: "Citi",
  QNB: "QNB",
  UBCI: "UBCI",
  UIB: "UIB",
};

function PayementAddModalContent({
  closeModalHandler,
  rentContractID,
}: {
  closeModalHandler: any;
  rentContractID: string;
}) {
  const { t } = useTranslation();
  const { enums, isLoading } = useEnumsData();

  const asyncToast = useRef<any>();

  const onSubmitHandler = async (values: any) => {
    try {
      asyncToast.current = toast.loading(t("ADDING_PAYEMENT_ONGOING_MESSAGE"));

      await Axios.post("/rent/payement", {
        ...values,
        rentContractID,
      });
      await mutate("/rent/contracts");
      closeModalHandler();
      toast.update(asyncToast.current, {
        render: t("ADDING_PAYEMENT_SUCCESS_MESSAGE"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (e: any) {
      toast.update(asyncToast.current, {
        render: t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  return (
    <div className="p-4 flex flex-col">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {({ submitForm }) => (
          <FormikForm>
            <Form layout="vertical">
              <Form.Item label={t("Type")}>
                <Field name="Type" component={AntdSelect} loading={isLoading}>
                  {enums &&
                    Object.entries(enums["paymentPasserelle"]).map(
                      ([_, value]) => (
                        <Option key={value} value={value}>
                          {t(value)}
                        </Option>
                      ),
                    )}
                </Field>
              </Form.Item>
              <Form.Item label="Amount">
                <Field name="Amount" component={AntdInputNumber} />
              </Form.Item>
              <Form.Item label={t("TRN")}>
                <Field name="TRN" component={AntdInput} />
              </Form.Item>
              <Form.Item label={t("Bank")}>
                <Field
                  name="Bank"
                  component={AntdSelect}
                  loading={isLoading}
                  defaltValue={""}
                >
                  {banks &&
                    Object.entries(banks).map(([_, value]) => (
                      <Option key={value} value={value}>
                        {t(value)}
                      </Option>
                    ))}
                </Field>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  className=" w-full  !h-10 !bg-[#333333] !hover:bg-[#585757] !text-white"
                  onClick={submitForm}
                >
                  {t("add")}
                </Button>
              </Form.Item>
            </Form>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
}

export default PayementAddModalContent;
