import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { NavBar } from "../Components";
import { SideBar } from "../Components/Features/SideBar/SideBar";
import { useTranslation } from "react-i18next";
import { FloatButton } from "antd";
import {
  FileAddOutlined,
  ThunderboltOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useMyCompanyData } from "../Hooks";

export const AppLayout: FC<PropsWithChildren<any>> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const path = window.location.pathname;
  const [isLogoNeeded, setIsLogoNeeded] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const user = useSelector((state: any) => state.authentication.user);
  const { company } = useMyCompanyData(
    10000,
    user.accountType === "ENTREPRISE",
  );
  const onChange = () => {
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (company) {
      setIsLogoNeeded(!Boolean(company?.logo));
    }
  }, [company]);

  const navigate = useNavigate();
  return (
    <main
      className={`w-screen overflow-x-hidden h-screen max-h-screen flex flex-nowrap  relative box-border ${
        language === "ar" ? "right-to-left" : ""
      }`}
    >
      <SideBar collapsed={collapsed} setCollapsed={setCollapsed} />

      <div className="w-full max-w-full box-border h-screen min-h-screen max-h-screen  bg-[#F5F5F5] flex flex-col relative">
        <NavBar collapsed={collapsed} setCollapsed={setCollapsed} />
        {user.accountType === "ENTREPRISE" ? (
          user?.CompaniesUsers?.[0]?.companyID?.CompanyPoints?.[0]
            .currentPoint < 10 && path === "/" ? (
            <div
              className="bg-gray-800 text-white py-10 px-8 font-sans"
              dir={language === "ar" ? "rtl" : "ltr"}
            >
              <div className="container mx-auto flex flex-col md:flex-row justify-between items-center gap-x-6 gap-y-8">
                <div
                  className={`  text-center ${language === "ar" ? "md:text-right" : "md:text-left"}`}
                >
                  <h2 className="text-3xl font-semibold mb-4">
                    {t("UNLOCK_EXPERIENCE_TITLE")}
                  </h2>
                  <p className="text-base text-gray-300">
                    {t("UNLOCK_EXPERIENCE_DESCRIPTION")}
                  </p>
                </div>

                <div className="md:w-1/2 flex justify-center md:justify-end">
                  <button
                    type="button"
                    className="bg-[#f46438] text-white py-3 px-6 font-semibold rounded"
                    onClick={() => {
                      navigate("/points_management/history");
                    }}
                  >
                    {t("UNLOCK_EXPERIENCE_CTA")}
                  </button>
                </div>
              </div>
            </div>
          ) : null
        ) : null}

        {user.accountType === "ENTREPRISE" ? (
          isLogoNeeded && path === "/" ? (
            <div
              className="bg-[#e74c3c] text-white py-4 px-8 font-sans"
              dir={language === "ar" ? "rtl" : "ltr"}
            >
              <div className="container mx-auto flex flex-col md:flex-row justify-between items-center gap-x-6 gap-y-8">
                <div
                  className={`  text-center ${language === "ar" ? "md:text-right" : "md:text-left"}`}
                >
                  <p className="text-base text-white> font-bold">
                    {t("COMPANY_SHOULD_HAVE_LOGO")}
                  </p>
                </div>

                <div className="md:w-1/2 flex justify-center md:justify-end">
                  <button
                    type="button"
                    className="bg-[#34495e] text-sm text-white py-2 px-4  rounded"
                    onClick={() => {
                      navigate("/company");
                    }}
                  >
                    {t("ADD_COMPANY_LOGO")}
                  </button>
                </div>
              </div>
            </div>
          ) : null
        ) : null}
        <div className="w-full max-w-full h-fit  flex flex-col overflow-x-hidden overflow-auto relative ">
          {children}

          {user.accountType === "ENTREPRISE" ? (
            <FloatButton.Group
              open={open}
              trigger="click"
              style={{ right: 24 }}
              onClick={onChange}
              tooltip={t("actions")}
              icon={<ThunderboltOutlined />}
            >
              <FloatButton
                icon={<FileAddOutlined />}
                tooltip={t("add_newRent")}
                onClick={() => navigate("/rent_management/add_newRent")}
              />
              <FloatButton
                icon={<UserAddOutlined />}
                tooltip={t("add_client")}
                onClick={() => navigate("/clients_management/add_client")}
              />
              <FloatButton
                icon={<ReceiptLongIcon />}
                tooltip={t("add_reservation")}
                onClick={() =>
                  navigate("/reservation_management/add_reservation")
                }
              />
            </FloatButton.Group>
          ) : null}
        </div>
      </div>
    </main>
  );
};
