import React, { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Result,
  Select,
  Switch,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { enumsToSelectOptions } from "../../../../Utils";
import { useEnumsData } from "../../../../Hooks";
import UploadWithDrag from "../../../UI/UploadWithDrag";
import moment from "moment";
import { Axios } from "../../../../Config";
import dayjs from "dayjs";

export function AddNewDriverContent() {
  const { t } = useTranslation();
  const { enums } = useEnumsData();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);

  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      setBtnIsLoading(true);
      const data = {
        ...values,
        dateOfBirth: moment(values.dateOfBirth).toISOString(),
        licenceIssueDate: moment(values.licenceIssueDate).toISOString(),
        idIssueDate: moment(values.idIssueDate).toISOString(),
      };

      await Axios.post("/driver", data);
      formikHelpers.setValues(initialValues);
      setIsSuccess(true);

      setBtnIsLoading(false);
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  const initialValues = {
    fullName: null,
    email: null,
    city: null,
    address: null,
    dateOfBirth: null,
    idType: null,
    idRecto: null,
    idNumber: null,
    idVerso: null,
    phone: null,
    licenceIssueDate: null,
    licenceNumber: null,
    licenceRecto: null,
    licenceVerso: null,
    idIssueDate: null,
    isDriver: false,
  };

  return (
    <div className="w-full  overflow-scroll p-10">
      {!isSuccess ? (
        <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
          {({ values, submitForm, setFieldValue }) => {
            return (
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                disabled={false}
                initialValues={values}
                onValuesChange={(fieldKeyValue) => {
                  const fieldKey = Object.keys(fieldKeyValue)[0];
                  const fieldValue = fieldKeyValue[fieldKey];
                  setFieldValue(fieldKey, fieldValue);
                }}
              >
                <FormItem name="isDriver" label={t("isCompanyDriver")}>
                  <Switch
                    checkedChildren={t("yes")}
                    unCheckedChildren={t("no")}
                  />
                </FormItem>

                <Divider />
                <FormItem name="fullName" label={t("fullName")}>
                  <Input placeholder={t("fullName")} />
                </FormItem>
                <FormItem name="email" label={t("auth_email")}>
                  <Input placeholder={t("auth_email")} />
                </FormItem>
                <FormItem name="address" label={t("address")}>
                  <Input placeholder={t("address")} />
                </FormItem>
                <FormItem name="city" label={t("city")}>
                  <Input placeholder={t("city")} />
                </FormItem>
                <FormItem name="phone" label={t("phone")}>
                  <Input placeholder={t("phone")} />
                </FormItem>
                <FormItem label={t("dateOfBirth")}>
                  <DatePicker
                    style={{ width: "100%" }}
                    format="DD/MM/YYYY"
                    maxDate={dayjs(new Date()).subtract(18, "year")}
                    onChange={(_, date) => {
                      setFieldValue(
                        "dateOfBirth",
                        moment(date, "DD/MM/YYYY").toISOString(),
                      );
                    }}
                  />
                </FormItem>

                <Divider />

                <FormItem label={t("idType")} name="idType">
                  <Select
                    showSearch
                    placeholder={t("idType")}
                    optionFilterProp="children"
                    value={values.idType || ""}
                    style={{ width: "100%" }}
                    options={enumsToSelectOptions(enums?.["IDTypes"])}
                  />
                </FormItem>
                <FormItem name="idNumber" label={t("idNumber")}>
                  <Input placeholder={t("idNumber")} />
                </FormItem>

                <FormItem label={t("deliveredDate")}>
                  <DatePicker
                    style={{ width: "100%" }}
                    maxDate={dayjs(new Date())}
                    format="DD/MM/YYYY"
                    onChange={(_, date) => {
                      setFieldValue(
                        "idIssueDate",
                        moment(date, "DD/MM/YYYY").toISOString(),
                      );
                    }}
                  />
                </FormItem>

                <FormItem label={t("copie_recto")}>
                  <UploadWithDrag
                    uploadText={t("idCard")}
                    hint={t("copie_recto")}
                    maxCount={1}
                    accept="image/*"
                    onRemove={() => {
                      setFieldValue("licenceRecto", "");
                    }}
                    setFileValue={(file) => {
                      setFieldValue("licenceRecto", file?.fileID);
                    }}
                  />
                </FormItem>
                <FormItem label={t("copie_verso")}>
                  <UploadWithDrag
                    uploadText={t("idCard")}
                    hint={t("copie_verso")}
                    maxCount={1}
                    accept="image/*"
                    onRemove={() => {
                      setFieldValue("licenceVerso", "");
                    }}
                    setFileValue={(file) => {
                      setFieldValue("licenceVerso", file?.fileID);
                    }}
                  />
                </FormItem>
                <Divider />
                <FormItem name="licenceNumber" label={t("licenceNumber")}>
                  <Input />
                </FormItem>

                <FormItem label={t("deliveredDate")}>
                  <DatePicker
                    style={{ width: "100%" }}
                    maxDate={dayjs(new Date())}
                    format="DD/MM/YYYY"
                    onChange={(_, date) => {
                      setFieldValue(
                        "licenceIssueDate",
                        moment(date, "DD/MM/YYYY").toISOString(),
                      );
                    }}
                  />
                </FormItem>

                <FormItem label={t("copie_recto")}>
                  <UploadWithDrag
                    uploadText={t("licenceCard")}
                    hint={t("copie_recto")}
                    maxCount={1}
                    accept="image/*"
                    onRemove={() => {
                      setFieldValue("idRecto", "");
                    }}
                    setFileValue={(file) => {
                      setFieldValue("idRecto", file?.fileID);
                    }}
                  />
                </FormItem>
                <FormItem label={t("copie_verso")}>
                  <UploadWithDrag
                    uploadText={t("licenceCard")}
                    hint={t("copie_verso")}
                    maxCount={1}
                    accept="image/*"
                    onRemove={() => {
                      setFieldValue("idVerso", "");
                    }}
                    setFileValue={(file) => {
                      setFieldValue("idVerso", file?.fileID);
                    }}
                  />
                </FormItem>

                <div className="w-full flex justify-center">
                  <Button
                    type="primary"
                    className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
                    onClick={submitForm}
                    loading={isBtnLoading}
                  >
                    {t("add_driver").toUpperCase()}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Result
          status="success"
          title={t("driver_added_success")}
          extra={[
            <Button
              type="primary"
              className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
              onClick={() => setIsSuccess(false)}
            >
              {t("back")}
            </Button>,
          ]}
        />
      )}
    </div>
  );
}
