import React, { useMemo, useState } from "react";
import { Fleet } from "../../../../../Types";
import { useTranslation } from "react-i18next";
import { CalendarCheck, ChevronDown, ChevronUp } from "lucide-react";
import FleetScheduler from "../FleetScheduler";
import { useCompanyRentByCarData } from "../../../../../Hooks/useCompanyRentByCarData";

interface FleetPlanningProps {
  fleet: Fleet | null;
}

const FleetPlanning: React.FC<FleetPlanningProps> = ({ fleet }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { contracts } = useCompanyRentByCarData();

  const carScheduleDisplay = useMemo(() => {
    return contracts.filter(
      (contractFleet) => contractFleet.fleetID === fleet?.fleetID,
    );
  }, [fleet?.fleetID, contracts]);
  return (
    <div className="w-full flex flex-col min-h-fit relative h-fit">
      <div className=" flex   justify-between relative  ">
        <div className="  flex items-center gap-4 ">
          <CalendarCheck size={20} />
          <span className="overflow-hidden whitespace-nowrap text-ellipsis   flex-auto text-opacity-88 font-semibold text-[16px] leading-6  text-[#d35400]">
            {t("vehicle_schedule").toUpperCase()}
          </span>
        </div>

        {isOpen ? (
          <ChevronUp
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        ) : (
          <ChevronDown
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        )}
      </div>
      {isOpen ? (
        <div className="w-full h-[200px] max-h-[200px] relative mt-6 fleetPlanning">
          <FleetScheduler
            contracts={carScheduleDisplay}
            maxRecordsPerPage={1}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FleetPlanning;
