import React, { useRef, useState } from "react";
import { Button, Col, DatePicker, Form, Modal, Row } from "antd";
import { Field, Form as FormikForm, Formik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";
import { Axios } from "../../../../../../Config";
import { mutate } from "swr";

const initialValues = {
  cancellation_date: moment.now(),
  cancellation_reason: null,
};

const CancelInsurance = ({ assuranceID }: { assuranceID?: string }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const asyncToast = useRef<any>();

  const handleCancelInsurance = async (values: any) => {
    try {
      const { cancellation_date, cancellation_reason } = values;

      const data = {
        cancellation_date,
        cancellation_reason,
        assuranceID,
      };
      asyncToast.current = toast.loading(t("insurance_canceled_ongoing"));
      await Axios.put("/fleet-insurance/cancel", data);
      mutate("/enterprise/fleet").then(() => {
        toast.update(asyncToast.current, {
          render: t("insurance_canceled_success_message"),
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        setOpenModal(false);
      });
    } catch (e: any) {
      toast.update(asyncToast.current, {
        render: t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <Button
        type="primary"
        className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
        onClick={() => {
          setOpenModal(true);
        }}
      >
        {t("cancel_insurance").toUpperCase()}
      </Button>

      {openModal ? (
        <Modal
          width={isSmallDevice ? "100% " : "40%"}
          title={t("cancel_insurance").toUpperCase()}
          footer={null}
          open={openModal}
          destroyOnClose={true}
          onClose={() => {
            setOpenModal(false);
          }}
          onCancel={() => {
            setOpenModal(false);
          }}
        >
          <div className="p-12   overflow-auto">
            <Formik
              initialValues={initialValues}
              onSubmit={handleCancelInsurance}
            >
              {({ setFieldValue, handleSubmit }) => (
                <Form
                  layout="vertical"
                  onFinish={handleSubmit}
                  component={FormikForm}
                >
                  <Row gutter={16}>
                    <Form.Item
                      label={t("cancellation_date")}
                      name="cancellation_date"
                      className="!w-full"
                    >
                      <DatePicker
                        value={moment(initialValues.cancellation_date)}
                        className="!w-full"
                        onChange={(date) =>
                          setFieldValue("cancellation_date", date)
                        }
                      />
                    </Form.Item>
                    <Col span={24}>
                      <Form.Item
                        label={t("cancellation_reason")}
                        className="!w-full"
                        name="cancellation_reason"
                      >
                        <Field
                          name="cancellation_reason"
                          as={TextArea}
                          className="!w-full"
                          onChange={(e: any) =>
                            setFieldValue("cancellation_reason", e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mx-auto  "
                    >
                      {t("cancel_insurance")}
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default CancelInsurance;
