import { SVGProps } from "react";

function SuvSvg(props: SVGProps<any>) {
  return (
    <svg
      {...props}
      viewBox="0 0 1000 227.963"
      enableBackground="new 0 0 1000 227.963"
    >
      <g>
        <g>
          <path
            fill="#BABABA"
            d="M495.484,155.2l-3.436-0.539c1.227-46.851-6.11-56.299-14.356-84.987h4.54    c2.135,0,3.878-1.746,3.878-3.899v-4.466c0-2.138-1.743-3.877-3.878-3.877H206.666c-4.046,0-8.021,1.074-11.485,3.142    l-83.511,49.323c0,0-83.605,3.387-96.563,16.197c-6.555,6.481-12.466,19.268-14.626,34.016    c-2.163,14.749,3.485,30.479,6.678,33.866c3.208,3.386,8.368,5.006,8.368,5.006l2.034,3.878c1.325,2.479,3.926,4.024,6.749,3.95    l13.424-0.318v-37.105c0-14.381,11.681-26.062,26.086-26.062h35.682c12.617,0,22.851,10.209,22.851,22.847v38.259h0.61    l218.188-3.362l3.141,0.319v-32.001c0-14.381,11.682-26.062,26.087-26.062h35.682c12.638,0,22.847,10.209,22.847,22.847v41.842    l30.111-6.405c1.595-0.344,2.944-1.374,3.681-2.847l2.185-4.368l30.454-4c2.675-0.344,4.663-2.626,4.663-5.301v-24.59    C500,157.851,498.085,155.618,495.484,155.2L495.484,155.2z M233.366,112.301c-0.66,0.712-1.614,1.132-2.601,1.132H164.31    c0,0,4.08-2.085,6.215-5.083c2.129-2.991,0.954-10.04-1.451-12.415c-2.212-2.187-6.653-3.681-6.653-3.681l33.454-20.568    c2.135-1.325,4.586-2.037,7.111-2.037h28.271c0.96,0,1.865,0.39,2.531,1.055c0.659,0.687,1.03,1.598,1.006,2.528l-0.491,36.417    C234.378,110.629,234.031,111.592,233.366,112.301L233.366,112.301z M316.855,109.356c-2.233,2.7-5.546,4.322-9.08,4.39    l-53.179,1.157c-0.957,0.024-1.891-0.347-2.553-1.006c-0.688-0.666-1.08-1.57-1.08-2.524V73.181c0-1.936,1.596-3.506,3.534-3.506    h59.044c1.816,0,3.338,1.377,3.51,3.218l2.38,26.743C320.021,103.098,319.063,106.66,316.855,109.356L316.855,109.356z     M474.208,109.381c-0.295,0.54-0.859,0.859-1.473,0.859l-108.076,0.813c-4.687,0.024-9.03-2.356-11.558-6.31l-11.313-30.304    c-0.417-1.08-0.27-2.313,0.393-3.243c0.662-0.954,1.742-1.521,2.896-1.521H459.41c1.521,0,2.871,0.982,3.362,2.433l11.484,35.559    C474.552,108.206,474.526,108.865,474.208,109.381L474.208,109.381z M474.208,109.381"
          />
        </g>
        <path
          fill="#BABABA"
          d="M80.041,150.856c-21.298,0-38.575,17.252-38.575,38.554c0,1.644,0.144,3.264,0.337,4.834   c2.408,19.019,18.577,33.719,38.209,33.719c20.224,0,36.74-15.485,38.43-35.265c0.102-1.104,0.175-2.185,0.175-3.288   C118.618,168.108,101.342,150.856,80.041,150.856L80.041,150.856z M60.728,174.882l7.877,7.853   c-0.641,1.056-1.129,2.209-1.399,3.411H56.117C56.675,181.925,58.271,178.097,60.728,174.882L60.728,174.882z M56.065,192.821   H67.23c0.319,1.178,0.786,2.307,1.399,3.337l-7.877,7.878C58.322,200.846,56.657,196.993,56.065,192.821L56.065,192.821z    M76.703,213.361c-4.172-0.564-7.979-2.209-11.188-4.613l7.854-7.854c1.003,0.589,2.153,1.031,3.334,1.35V213.361z M76.703,176.575   c-1.199,0.319-2.355,0.81-3.411,1.424l-7.853-7.854c3.218-2.454,7.043-4.073,11.239-4.662v11.092H76.703z M83.375,165.483   c4.221,0.589,8.056,2.208,11.264,4.662l-7.853,7.854c-1.055-0.614-2.205-1.104-3.43-1.399v-11.116H83.375z M83.375,213.361v-11.117   c1.181-0.318,2.313-0.761,3.368-1.374l7.829,7.878C91.354,211.152,87.553,212.797,83.375,213.361L83.375,213.361z M99.351,204.061   l-7.896-7.902c0.607-1.03,1.123-2.135,1.42-3.337h11.092C103.379,196.993,101.808,200.87,99.351,204.061L99.351,204.061z    M92.875,186.146c-0.297-1.202-0.761-2.355-1.377-3.411l7.853-7.853c2.458,3.215,4.053,7.043,4.645,11.264H92.875z M92.875,186.146   "
        />
        <path
          fill="#BABABA"
          d="M386.599,150.856c-21.301,0-38.577,17.252-38.577,38.554c0,1.644,0.147,3.264,0.344,4.834   c2.404,19.019,18.577,33.719,38.233,33.719c20.197,0,36.737-15.485,38.406-35.265c0.098-1.104,0.172-2.185,0.172-3.288   C425.177,168.108,407.925,150.856,386.599,150.856L386.599,150.856z M367.285,174.882l7.878,7.853   c-0.639,1.056-1.104,2.209-1.398,3.411h-11.093C363.236,181.925,364.856,178.097,367.285,174.882L367.285,174.882z    M362.623,192.821h11.166c0.318,1.178,0.785,2.307,1.398,3.337l-7.878,7.878C364.881,200.846,363.212,196.993,362.623,192.821   L362.623,192.821z M383.261,213.361c-4.172-0.564-7.975-2.209-11.189-4.613l7.853-7.854c1.006,0.589,2.159,1.031,3.337,1.35   V213.361z M383.261,176.575c-1.202,0.319-2.355,0.81-3.41,1.424l-7.854-7.854c3.215-2.454,7.043-4.073,11.264-4.662V176.575z    M389.937,165.483c4.221,0.589,8.049,2.208,11.288,4.662l-7.877,7.854c-1.031-0.614-2.185-1.104-3.411-1.399V165.483z    M389.937,213.361v-11.117c1.178-0.318,2.331-0.761,3.361-1.374l7.829,7.878C397.912,211.152,394.133,212.797,389.937,213.361   L389.937,213.361z M405.912,204.061l-7.902-7.902c0.614-1.03,1.129-2.135,1.448-3.337h11.067   C409.937,196.993,408.366,200.87,405.912,204.061L405.912,204.061z M399.458,186.146c-0.295-1.202-0.785-2.355-1.398-3.411   l7.877-7.853c2.43,3.215,4.049,7.043,4.613,11.264H399.458z M399.458,186.146"
        />
      </g>
    </svg>
  );
}

export default SuvSvg;
