import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";

import moment from "moment/moment";
import { RentContracts } from "../../../../../Types";

export const CompanyDriversColumns: (
  t: any,
) => (ColumnGroupType<RentContracts> | ColumnType<RentContracts>)[] = (t) => [
  {
    title: t("date_of_creations"),
    dataIndex: "createdAt",
    key: "createdAt",
    render: (value) => moment(value).format("DD/MM/YYYY HH:MM"),
  },
  {
    title: t("fullName"),
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: t("address"),
    dataIndex: "address",
    key: "address",
  },

  {
    title: t("idType"),
    dataIndex: "idType",
    key: "idType",
  },

  {
    title: t("idNumber"),
    dataIndex: "idNumber",
    key: "idNumber",
  },
  {
    title: t("licenceNumber"),
    dataIndex: "licenceNumber",
    key: "licenceNumber",
  },

  {
    title: t("deliveredDate"),
    dataIndex: "licenceIssueDate",
    key: "licenceIssueDate",
    render: (value) => moment(value).format("DD/MM/YYYY HH:MM"),
  },
  {
    title: t("created_by"),
    dataIndex: "createdBy",
    key: "createdBy",
    render: (value) => {
      return `${value.name} ${value.lastName}`;
    },
  },
];
