import { Axios } from "./axios";
import useSWR, { SWRConfiguration } from "swr";
import { appDispatch, store } from "../Store/store";
import { userLogoutAction } from "../Store/actions";

export const swrConfig: SWRConfiguration = {
  fetcher: (url: string) =>
    Axios.get(url, {
      headers: {
        Authorization:
          window.localStorage.getItem("token") ||
          store.getState().authentication.token ||
          "",
      },
    }).then((res) => res.data),
  revalidateOnMount: true,
  revalidateOnFocus: false,
  onError: (err) => {
    if (err) {
      const errorMessage = err.response?.data?.message?.RESPONSE_CODE;
      if (errorMessage === "TOKEN_EXPIRED") {
        appDispatch(userLogoutAction());
      }
    }
  },
  onErrorRetry: (_, __, ___, ____, { retryCount }) => {
    if (retryCount >= 2) return;
  },
};

export const useSwiftSWR = (key: string | null, options?: SWRConfiguration) => {
  return useSWR(key, { ...options });
};
