import React from "react";
import { Fleet } from "../../../../../Types";
import { Descriptions } from "antd";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";

export function SelectedCarDescription({
  selectedCar,
}: {
  selectedCar: Fleet;
}) {
  const { t } = useTranslation();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  return (
    <div className="w-full md:w-2/3 mx-auto">
      <Descriptions
        bordered
        rootClassName="!mb-6"
        size="small"
        column={2}
        layout={isSmallDevice ? "vertical" : "horizontal"}
      >
        <Descriptions.Item label={t("car_number")}>
          {selectedCar?.registrationNumber?.replace("/", ` ${t("TU")} `)}
        </Descriptions.Item>
        <Descriptions.Item label={t("manufacturer")}>
          {selectedCar?.manufacturer.replaceAll("_", " ")}
        </Descriptions.Item>
        <Descriptions.Item label={t("car_model")}>
          {selectedCar?.modal}
        </Descriptions.Item>
        <Descriptions.Item label={t("VIN")}>
          {selectedCar?.vin}
        </Descriptions.Item>
        <Descriptions.Item label={t("color")}>
          {t(selectedCar?.color)}
        </Descriptions.Item>
        <Descriptions.Item label={t("first_registration")}>
          {moment(selectedCar.firstRegistration).format("DD/MM/YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label={t("nb_places")}>
          {selectedCar.places}
        </Descriptions.Item>
        <Descriptions.Item label={t("horse_power")}>
          {selectedCar.numberHorses}
        </Descriptions.Item>
        <Descriptions.Item label={t("transmission")}>
          {t(selectedCar?.transmission)}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
