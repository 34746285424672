import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp, MessageSquare } from "lucide-react";
import CommentsSection from "../../../../UI/CommentsSection";
import { Clients } from "../../../../../Types";

interface FleetCommentsProps {
  client: Clients | null;
}

const ClientComments: React.FC<FleetCommentsProps> = ({ client }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full flex flex-col min-h-fit">
      <div className=" flex   justify-between   ">
        <div className="  flex items-center gap-4 ">
          <MessageSquare size={20} />
          <span className="overflow-hidden whitespace-nowrap text-ellipsis   flex-auto text-opacity-88 font-semibold text-[16px] leading-6  text-[#d35400]">
            {t("client_comments").toUpperCase()}
          </span>
        </div>

        {isOpen ? (
          <ChevronUp
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        ) : (
          <ChevronDown
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        )}
      </div>
      {isOpen ? (
        <div className="w-full overflow-scroll mt-6">
          <CommentsSection
            comments={client?.comments || []}
            identifierValue={client?.clientID || ""}
            identifier="CLIENT"
          />
        </div>
      ) : null}
    </div>
  );
};

export default ClientComments;
