import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistor, store } from "./Store/store";
import reportWebVitals from "./reportWebVitals";
import { SWRConfig } from "swr";
import { swrConfig } from "./Config";
import "./i18n";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";

import { ConfigProvider } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";
import { LoadingPage } from "./Pages/GeneralPages/LoadingPage";

const container = document.getElementById("locasafe-app")!;
const root = createRoot(container);

document.title = process.env.REACT_APP_NAME || "LOCASAFE";

root.render(
  <Provider store={store}>
    <PersistGate loading={<LoadingPage />} persistor={persistor}>
      <SWRConfig value={swrConfig}>
        <BrowserRouter>
          <ConfigProvider theme={{ token: { fontSize: 13 } }}>
            <StyleProvider hashPriority="high">
              <App />
            </StyleProvider>
          </ConfigProvider>
        </BrowserRouter>
      </SWRConfig>
    </PersistGate>
  </Provider>,
);

reportWebVitals();
