import React from "react";
import { t } from "i18next";

function RegistrationNumberPlate({ value }: any) {
  return (
    <div className="inline-flex rounded items-center justify-center bg-[#3d3d3d]   p-1  w-full max-w-[130px] ">
      <div className=" flex rounded items-center justify-between  w-full border-2 border-gray-200   px-2 py-1  text-gray-200  ">
        <span className="text-xs  mr-1" aria-label="Prefix">
          {value.split("/")[0]}
        </span>
        <span
          className="text-xs font-bold   text-gray-200      "
          aria-label="Region"
        >
          {t("TU")}
        </span>
        <span className="text-xs   ml-1" aria-label="Suffix">
          {value.split("/")[1]}
        </span>
      </div>
    </div>
  );
}

export default RegistrationNumberPlate;
