import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { CautionListingColumns } from "./CautionListingColumns";

function RentCautionContent({ contract }: any) {
  const { t } = useTranslation();

  const columns = CautionListingColumns(t);

  return (
    <div className=" flex justify-center w-full  ">
      <Table
        rootClassName="w-full md:w-2/3"
        key={"RentContractCautionID"}
        size="small"
        columns={columns}
        dataSource={contract.RentContractCaution || []}
        bordered={false}
        pagination={false}
        scroll={{ x: 500 }}
      />
    </div>
  );
}

export default RentCautionContent;
