import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import { Upload, UploadProps } from "antd";
import { baseURL } from "../../Config";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface propsT extends UploadProps<any> {
  setFileValue: (file: any) => any;
  uploadText?: string;
  hint?: string;
}

function UploadWithDrag({
  setFileValue,
  uploadText,
  hint,
  multiple = false,
  maxCount = 1,

  ...props
}: propsT) {
  const user = useSelector((state: any) => state.authentication.user);
  const { t } = useTranslation();
  const headersAuth = {
    Authorization: user.token || window.localStorage.getItem("token") || "",
  };

  return (
    <Dragger
      accept=".png,.jpg,.jpeg,.pdf"
      {...props}
      maxCount={maxCount}
      name={"files"}
      headers={headersAuth}
      beforeUpload={(file) => {
        const isImageOrPdf =
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "application/pdf";

        if (!isImageOrPdf) {
          toast.error(t("file_upload_error"));
        }
        return isImageOrPdf || Upload.LIST_IGNORE;
      }}
      onChange={(files) => {
        if (files && files.file.status !== "removed") {
          if (files?.file?.response) {
            setFileValue(
              multiple
                ? files?.file?.response?.files?.files
                : files?.file?.response?.files?.files[0],
            );
          }
        }
      }}
      action={baseURL + "/files"}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      {uploadText && <p className="ant-upload-text">{uploadText}</p>}
      {hint && <p className="ant-upload-hint">{hint}</p>}
    </Dragger>
  );
}

export default UploadWithDrag;
