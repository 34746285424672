import React from "react";
import { Fleet } from "../../../../../Types";
import { useTranslation } from "react-i18next";
import { BookOpenText } from "lucide-react";
import { Descriptions, DescriptionsProps } from "antd";
import { useMediaQuery } from "@mui/material";
import RegistrationNumberPlate from "../../../../UI/RegistrationNumberPlate";
import moment from "moment";

interface FleetGeneralInfosProps {
  fleet: Fleet | null;
}

const FleetGeneralInfos: React.FC<FleetGeneralInfosProps> = ({ fleet }) => {
  const { t } = useTranslation();

  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: t("car_number"),
      children: <RegistrationNumberPlate value={fleet?.registrationNumber} />,
      span: 2,
    },
    {
      key: "2",
      label: t("manufacturer"),
      children: fleet?.manufacturer,
      span: 2,
    },
    {
      key: "3",
      label: t("car_model"),
      children: fleet?.modal,
      span: 2,
    },
    {
      key: "4",
      label: t("VIN"),
      span: 2,
      children: fleet?.vin,
    },
    {
      key: "5",
      label: t("first_registration"),
      children: moment(fleet?.firstRegistration).format("DD/MM/YYYY"),
      span: 2,
    },
    {
      key: "6",
      label: t("power"),
      children: fleet?.numberHorses,
      span: 2,
    },
    {
      key: "7",
      label: t("type"),
      children: t(fleet?.type || ""),
      span: 2,
    },

    {
      key: "8",
      label: t("fuel"),
      children: t(fleet?.fuel || ""),
      span: 2,
    },
    {
      key: "9",
      label: t("transmission"),
      children: t(fleet?.transmission || ""),
      span: 2,
    },
    {
      key: "10",
      label: t("nb_places"),
      children: fleet?.places,
      span: 2,
    },

    {
      key: "11",
      label: t("color"),
      children: t(fleet?.color || ""),
      span: 2,
    },

    {
      key: "12",
      label: t("section"),
      children: t(fleet?.section || ""),
      span: 2,
    },
    {
      key: "13",
      label: t("purchase_price"),
      children: `${Number(fleet?.purchase_price).toFixed(3)} ${t("TND")}`,
      span: 2,
    },

    {
      key: "14",
      label: t("price_per_day"),
      children: `${Number(fleet?.price_per_day).toFixed(3)} ${t("TND")}`,
      span: 2,
    },
    {
      key: "15",
      label: t("current_mileage"),
      children: `${fleet?.kilometrage}  ${t("KM")}`,
      span: 2,
    },
  ];

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <Descriptions
      title={
        <div className=" flex items-center gap-4 text-[#d35400] !text-[16px]">
          <BookOpenText size={20} color="#000000" />
          <span> {t("vehicle_information").toUpperCase()}</span>
        </div>
      }
      layout={"vertical"}
      items={items}
      size="small"
      column={isSmallDevice ? 4 : 8}
    />
  );
};

export default FleetGeneralInfos;
