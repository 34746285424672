import React, { useState } from "react";
import { Clients } from "../../Types";
import { Button, DatePicker, Form, Input, Modal, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import FormItem from "antd/es/form/FormItem";
import dayjs from "dayjs";
import moment from "moment/moment";
import UploadWithDrag from "./UploadWithDrag";
import { Formik, FormikHelpers } from "formik";
import { Axios } from "../../Config";
import { toast } from "react-toastify";
import { Merge } from "lucide-react";
import { useMediaQuery } from "@mui/material";
import { mutate } from "swr";

const initialValues = {
  licenceIssueDate: null,
  licenceNumber: null,
  licenceRecto: null,
  licenceVerso: null,
};

function AddDriverFromClientModal({
  client,
  showButton = false,
}: {
  client: Clients;
  showButton?: boolean;
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);

  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      setBtnIsLoading(true);
      const data = {
        ...values,
        clientID: client.clientID,
        licenceIssueDate: moment(values.licenceIssueDate).toISOString(),
      };
      await Axios.post("/driver/from-client", data);
      await mutate("/clients");
      formikHelpers.setValues(initialValues);
      setIsModalOpen(false);
      setBtnIsLoading(false);
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <>
      {showButton ? (
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          {t("CLIENT_TO_DRIVER").toUpperCase()}
        </Button>
      ) : (
        <Tooltip title={t("CLIENT_TO_DRIVER").toUpperCase()}>
          <Merge
            color="#2c3e50"
            className="cursor-pointer"
            onClick={() => {
              setIsModalOpen(true);
            }}
          />
        </Tooltip>
      )}

      <Modal
        open={isModalOpen}
        destroyOnClose
        style={{ top: 10 }}
        title={t("ADD_DRIVER_FROM_CLIENT").toUpperCase()}
        onClose={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={null}
        width={isSmallDevice ? "100%" : "50%"}
      >
        <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
          {({ values, submitForm, setFieldValue }) => {
            return (
              <Form
                style={{ padding: 10 }}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                disabled={false}
                initialValues={values}
                onValuesChange={(fieldKeyValue) => {
                  const fieldKey = Object.keys(fieldKeyValue)[0];
                  const fieldValue = fieldKeyValue[fieldKey];
                  setFieldValue(fieldKey, fieldValue);
                }}
              >
                <FormItem name="licenceNumber" label={t("licenceNumber")}>
                  <Input />
                </FormItem>

                <FormItem label={t("deliveredDate")}>
                  <DatePicker
                    style={{ width: "100%" }}
                    maxDate={dayjs(new Date())}
                    format="DD/MM/YYYY"
                    onChange={(_, date) => {
                      setFieldValue(
                        "licenceIssueDate",
                        moment(date, "DD/MM/YYYY").toISOString(),
                      );
                    }}
                  />
                </FormItem>

                <FormItem label={t("copie_recto")}>
                  <UploadWithDrag
                    uploadText={t("licenceCard")}
                    hint={t("copie_recto")}
                    maxCount={1}
                    accept="image/*"
                    onRemove={() => {
                      setFieldValue("licenceRecto", "");
                    }}
                    setFileValue={(file) => {
                      setFieldValue("licenceRecto", file?.fileID);
                    }}
                  />
                </FormItem>
                <FormItem label={t("copie_verso")}>
                  <UploadWithDrag
                    uploadText={t("licenceCard")}
                    hint={t("copie_verso")}
                    maxCount={1}
                    accept="image/*"
                    onRemove={() => {
                      setFieldValue("licenceVerso", "");
                    }}
                    setFileValue={(file) => {
                      setFieldValue("licenceVerso", file?.fileID);
                    }}
                  />
                </FormItem>

                <div className="w-full flex justify-center">
                  <Button
                    type="primary"
                    className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
                    onClick={submitForm}
                    disabled={
                      !values.licenceIssueDate ||
                      !values.licenceNumber ||
                      !values.licenceRecto ||
                      !values.licenceVerso
                    }
                    loading={isBtnLoading}
                  >
                    {t("add_driver").toUpperCase()}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}

export default AddDriverFromClientModal;
