import { Axios } from "../Config";
import { toast } from "react-toastify";
import { t } from "i18next";
import { mutate } from "swr";

export const userChangeStatusHandler = async (
  userID: string,
  isActive: boolean,
) => {
  try {
    const status = await Axios.put("/users/status", {
      isActive,
      userID,
    }).then((res) => res.data.message);

    await mutate("/users/platform", undefined, false);
    await mutate("/users/company", undefined, false);
    await mutate("/users/consultant", undefined, false);
    toast.success(t(status["RESPONSE_CODE"]));
  } catch (error: any) {
    toast.error(
      t(
        error?.response?.data?.message["RESPONSE_CODE"] ||
          "SERVICE_UNAVAILABLE_MESSAGE",
      ),
    );
  }
};

export const userDeleteHandler = async (userID: string) => {
  try {
    const status = await Axios.delete(`/users/${userID}`).then(
      (res) => res.data.message,
    );

    await mutate("/users/platform", undefined, false);
    await mutate("/users/company", undefined, false);
    await mutate("/users/consultant", undefined, false);
    toast.success(t(status["RESPONSE_CODE"]));
  } catch (error: any) {
    toast.error(
      t(
        error?.response?.data?.message["RESPONSE_CODE"] ||
          "SERVICE_UNAVAILABLE_MESSAGE",
      ),
    );
  }
};

export const JwtREGEX = "^[A-Za-z0-9-_]+\\.[A-Za-z0-9-_]+\\.[A-Za-z0-9-_]+$";
