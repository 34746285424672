import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import WtcListingContent from "../../Components/Features/CompanyComponents/WtcManagement/WtcListing/WtcListingContent";

const WtcListingPage = () => {
  const { t } = useTranslation();

  return (
    <AppLayout>
      <PageLayout title={t("wtc_listing")}>
        <div className="h-fit   w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl">
          <WtcListingContent />
        </div>
      </PageLayout>
    </AppLayout>
  );
};

export default WtcListingPage;
