export const CautionListingColumns = (t: any) => [
  {
    title: t("Type"),
    dataIndex: "Type",
    key: "type",
    align: "center",
    render: (type: string) => {
      return t(type);
    },
  },
  {
    title: t("Amount"),
    dataIndex: "Amount",
    key: "amount",
    align: "center",
  },
  {
    title: t("TRN"),
    dataIndex: "TRN",
    key: "trn",
    render: (trn: string) => (trn ? trn : "N/A"),
    align: "center",
  },
  {
    title: t("Bank"),
    dataIndex: "Bank",
    key: "bank",
    render: (bank: string) => (bank ? bank : "N/A"),
    align: "center",
  },
  {
    title: t("Deposit"),
    dataIndex: "Deposit",
    key: "deposit",
    align: "center",
  },
];
