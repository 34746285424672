import React, { useState } from "react";
import { Button, Table } from "antd";
import { usePlatformUsersData } from "../../../../../Hooks";
import { PlatformUserListingColumns } from "./PlatformUserListingColumns";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AddUserModal from "../../../../UI/addUserModal";
import UserActivityHistoryModal from "../../../../UI/UserActivityHistoryModal";

export function PlatformUserListingContent() {
  const { platformUsers, isLoading, mutate } = usePlatformUsersData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  const openModalHandler = (userID: string) => {
    setSelectedUser(userID);
  };
  const user = useSelector((state: any) => state.authentication.user);

  const columns = PlatformUserListingColumns(
    t,
    user.userID,
    mutate,
    openModalHandler,
  );

  return (
    <div className="w-full  overflow-scroll p-10">
      <UserActivityHistoryModal
        isOpen={Boolean(selectedUser)}
        setIsOpen={openModalHandler}
        loginActivity={
          selectedUser
            ? platformUsers.filter((user) => user.userID === selectedUser)[0]
                .LoginActivity
            : null
        }
      />
      {isModalOpen ? (
        <AddUserModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          mutate={mutate}
        />
      ) : null}
      <div className="w-full flex gap-4 flex-col md:flex-row mb-8 justify-end">
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          {t("create_user").toUpperCase()}
        </Button>
      </div>
      <Table
        rowKey="userID"
        size="small"
        loading={isLoading}
        dataSource={(platformUsers && platformUsers) || []}
        columns={columns}
        rootClassName="h-2/3 min-h-2/3 "
        bordered={false}
      />
    </div>
  );
}
