import React, { useState } from "react";
import { Clients } from "../../../../../Types";
import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp, Files } from "lucide-react";
import { ClientFilesContent } from "../../../../UI/ClientFilesContent";

interface ClientFilesProps {
  client: Clients | null;
}

const ClientFiles: React.FC<ClientFilesProps> = ({ client }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="w-full flex flex-col min-h-fit">
      <div className=" flex   justify-between   ">
        <div className="  flex items-center gap-4 ">
          <Files size={20} />
          <span className="overflow-hidden whitespace-nowrap text-ellipsis   flex-auto text-opacity-88 font-semibold text-[16px] leading-6  text-[#d35400]">
            {t("client_documents").toUpperCase()}
          </span>
        </div>

        {isOpen ? (
          <ChevronUp
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        ) : (
          <ChevronDown
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        )}
      </div>
      {isOpen ? <ClientFilesContent client={client} /> : null}
    </div>
  );
};

export default ClientFiles;
