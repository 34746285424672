import React, { useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp, FolderCog } from "lucide-react";
import { Dropdown, Empty, Image, MenuProps, Table } from "antd";
import { FleetClaimsColumns } from "./FleetClaims/FleetClaimsColumns";
import { Fleet, InsuranceClaim } from "../../../../../Types";
import { PdfViewer } from "../../../../UI/PdfViewer/pdfViewer";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  IssuesCloseOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import ClaimsInfos from "./FleetClaims/ClaimsInfos";
import CommentsSection from "../../../../UI/CommentsSection";
import AddClaimForm from "./FleetClaims/AddClaim";
import { toast } from "react-toastify";
import { Axios } from "../../../../../Config";
import { mutate } from "swr";

interface FleetMaintenanceProps {
  fleet: Fleet;
}

const FleetClaims: React.FC<FleetMaintenanceProps> = ({ fleet }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const columns = FleetClaimsColumns(t);

  const expandedRowRender = (record: InsuranceClaim) => {
    const { accident_report } = record;

    if (!accident_report) {
      return <Empty description={t("no_accident_report_available")} />;
    }
    return (
      <div className="flex flex-col  gap-4 my-6 ">
        <ClaimsInfos InsuranceClaim={record} />
        <div className="flex flex-col justify-evenly lg:flex-row lg:flex-wrap gap-4 my-6 ">
          {accident_report.originalName.includes("pdf") ? (
            <PdfViewer
              title={t("accident_report")}
              fileName={accident_report.originalName}
              size={accident_report.size}
              pdfUrl={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/media/${accident_report.name}`}
            />
          ) : (
            <Image
              width={400}
              height={250}
              title={t("accident_report")}
              src={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/media/${accident_report.name}`}
            />
          )}
        </div>
        <CommentsSection
          comments={record.Comments}
          identifier="CLAIM"
          identifierValue={record.insuranceClaimeID}
        />
      </div>
    );
  };

  const asyncToast = useRef<any>();

  const handleChangeClaimStatus = async (
    status: any,
    insuranceClaimeID: any,
  ) => {
    try {
      asyncToast.current = toast.loading(t("CHANGE_CLAIM_STATUS_ONGOING"));

      await Axios.put("/fleet-claims/status", {
        status,
        insuranceClaimeID,
      });

      await mutate("/enterprise/fleet");
      toast.update(asyncToast.current, {
        render: t("CHANGE_CLAIM_STATUS_SUCCESS_MESSAGE"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (e: any) {
      toast.update(asyncToast.current, {
        render: t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  columns.push({
    title: t("actions"),
    dataIndex: "insuranceClaimeID",
    key: "actions",
    align: "center",
    render: (value: string, record) => {
      let items: MenuProps["items"] = [];

      if (record.status === "CREATED") {
        items = [
          {
            label: <span>{t("EXPERTISE_DONE")}</span>,
            icon: <CheckCircleOutlined />,
            key: "0",
            onClick: () => {
              handleChangeClaimStatus("EXPERTISE_DONE", value);
            },
          },

          {
            label: <span>{t("UNDER_REPAIR")}</span>,
            icon: <MinusCircleOutlined />,
            key: "1",
            onClick: () => {
              handleChangeClaimStatus("UNDER_REPAIR", value);
            },
          },
          {
            label: <span>{t("CASE_CLOSED")}</span>,
            icon: <CloseCircleOutlined />,
            key: "2",
            onClick: () => {
              handleChangeClaimStatus("CASE_CLOSED", value);
            },
          },
        ];
      } else if (record.status === "EXPERTISE_DONE") {
        items = [
          {
            label: <span>{t("UNDER_REPAIR")}</span>,
            icon: <MinusCircleOutlined />,
            key: "3",
            onClick: () => {
              handleChangeClaimStatus("UNDER_REPAIR", value);
            },
          },
          {
            label: <span>{t("CASE_CLOSED")}</span>,
            icon: <CloseCircleOutlined />,
            key: "4",
            onClick: () => {
              handleChangeClaimStatus("CASE_CLOSED", value);
            },
          },
        ];
      } else if (record.status === "CASE_CLOSED") {
        items = [
          {
            label: <span>{t("no_action_to_taken")}</span>,
            key: "5",
          },
        ];
      } else {
        items = [
          {
            label: <span>{t("CASE_CLOSED")}</span>,
            icon: <CloseCircleOutlined />,
            key: "6",
            onClick: () => {
              handleChangeClaimStatus("CASE_CLOSED", value);
            },
          },
        ];
      }

      return (
        <Dropdown menu={{ items }} trigger={["click"]}>
          <IssuesCloseOutlined />
        </Dropdown>
      );
    },
  });

  return (
    <div className="w-full flex flex-col min-h-fit">
      <div className=" flex   justify-between   ">
        <div className="  flex items-center gap-4 ">
          <FolderCog size={20} />
          <span className="overflow-hidden whitespace-nowrap text-ellipsis   flex-auto text-opacity-88 font-semibold text-[16px] leading-6  text-[#d35400]">
            {t("claims").toUpperCase()}
          </span>
        </div>

        {isOpen ? (
          <ChevronUp
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        ) : (
          <ChevronDown
            color="#7f8c8d"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        )}
      </div>
      {isOpen ? (
        <div className="w-full overflow-scroll mt-6">
          <Table
            title={() => (
              <div className="flex justify-between items-center py-4">
                <div className="flex gap-4 w-full">
                  <span className="uppercase font-bold text-sm">
                    {t("declared_claims_list")}
                  </span>
                </div>
                <AddClaimForm fleet={fleet} />
              </div>
            )}
            rowKey="insuranceClaimeID"
            size="small"
            loading={!fleet}
            rootClassName="w-full"
            columns={columns}
            expandable={{
              expandedRowRender,
            }}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20", "30"],
            }}
            dataSource={fleet?.insuranceClaimes}
            bordered={false}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FleetClaims;
