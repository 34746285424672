import DashboardSquareCard from "../../UI/DashboardSquareCard";
import {AuditOutlined, BarsOutlined, TeamOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useSubscriptionPriceData} from "../../../Hooks/useSubscriptionPriceData";
import {useCompaniesData} from "../../../Hooks/useCompaniesData";
import {useNavigate} from "react-router";
import {usePlatformUsersData} from "../../../Hooks";

export const DashboardInfoCards = () => {
  const { t } = useTranslation();

    const { platformUsers} = usePlatformUsersData();

    const { subscriptionPrices } = useSubscriptionPriceData();
  const { companies } = useCompaniesData();

  const navigate = useNavigate();
  return (
    <div className=" flex flex-col md:flex-row w-full mt-7 md:mt-14 justify-evenly  ">
      <DashboardSquareCard
        Icon={TeamOutlined}
        title={t("users")}
        count={platformUsers.length}
        cb={() => {
            navigate("/users_management/users", {
                replace: true,
            });
        }}
      />
      <DashboardSquareCard
        Icon={AuditOutlined}
        title={t("subscriptions")}
        count={subscriptionPrices.length}
        cb={() => {
          navigate("/subscription_management/subscription_listing", {
            replace: true,
          });
        }}
      />
      <DashboardSquareCard
        Icon={BarsOutlined}
        title={t("companies")}
        count={companies.length}
        cb={() => {
          navigate("/locasafe_management/company_listing", {
            replace: true,
          });
        }}
      />
    </div>
  );
};
