import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import FormItem from "antd/es/form/FormItem";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Slider,
  SliderSingleProps,
  Tag,
} from "antd";
import { useClientsData } from "../../../../../Hooks/useClientsData";
import { useFormikContext } from "formik";
import { RentInitialValues } from "./index";
import TextArea from "antd/es/input/TextArea";
import moment from "moment/moment";
import UploadWithDrag from "../../../../UI/UploadWithDrag";
import { toast } from "react-toastify";
import { Axios } from "../../../../../Config";
import dayjs, { Dayjs } from "dayjs";
import { AddClientContent } from "../../ClientsManagement/addClientContent";
import { useMediaQuery } from "@mui/material";
import CautionContent from "./CautionContent/CautionContent";
import {
  calculateRentalDays,
  enumsToSelectOptions,
} from "../../../../../Utils";
import { useEnumsData } from "../../../../../Hooks";
import { useCompanyRentContractsData } from "../../../../../Hooks/useCompanyRentContractsData";
import ConfirmCreatContractModal from "./ConfirmCreatContractModal";

const { RangePicker } = DatePicker;

function ContractInformations({
  setCurrentStepper,
}: {
  setCurrentStepper: Dispatch<SetStateAction<number>>;
}) {
  const { t } = useTranslation();
  const { clients, isLoading, mutate } = useClientsData();
  const { values, setFieldValue } = useFormikContext<RentInitialValues>();

  const { contracts, isLoading: isContractLoading } =
    useCompanyRentContractsData(100000);

  const { contract } = values;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);
  const { enums, isLoading: isEnumsLoading } = useEnumsData();
  const marks: SliderSingleProps["marks"] = {
    0: "0",
    0.25: "1/4",
    0.5: "1/2",
    0.75: "3/4",
    1: "1",
  };

  const BlockedField = useMemo(() => {
    return contracts.filter((contract) => {
      return (
        contract.rentedCar.fleetID === values.fleetID &&
        moment(contract.endDate).isAfter(moment())
      );
    });
  }, [contracts, values.fleetID]).map(({ startDate, endDate }) => {
    return { startDate, endDate };
  });

  const disabledDate = (currentDate: Dayjs | null) => {
    if (!currentDate) return false;

    return BlockedField.some((blockedRange: any) => {
      const start = dayjs(blockedRange.startDate);
      const end = dayjs(blockedRange.endDate);

      return (
        (currentDate.isAfter(start, "day") &&
          currentDate.isBefore(end, "day")) ||
        currentDate.isSame(start, "day") ||
        currentDate.isSame(end, "day")
      );
    });
  };

  const dateCellRender = (current: any) => {
    const isBlocked = disabledDate(current);

    if (isBlocked) {
      return (
        <div
          style={{
            backgroundColor: "#c0392b",
            color: "white",
          }}
        >
          {current.date()}
        </div>
      );
    }

    return <div className="ant-picker-cell-inner">{current.date()}</div>;
  };

  const clientsToOptions = clients
    ? clients.map((client) => {
        return {
          label:
            client.fullName ||
            client.denomination ||
            client.vatNumber ||
            client.idNumber,
          value: client.clientID,
          client: client,
        };
      })
    : [];

  const handleCreateContract = async () => {
    try {
      setBtnIsLoading(true);
      const updatedComment = contract.isdayPriceChanged
        ? `${contract.comment} | NOTE AUTOMATIQUE : L'utilisateur a modifié le tarif journalier de location.`
        : contract.comment;
      const data = {
        ...values,
        contract: {
          ...contract,
          comment: updatedComment,
          isdayPriceChanged: undefined,
          galleryBefore: contract.galleryBefore,
          galleryAfter: contract.galleryAfter,
        },
      };

      const addedContact = await Axios.post("/rent/contract", data).then(
        (res) => res.data.message,
      );
      setFieldValue("addedContract", addedContact).then(() => {
        mutate("/enterprise/stats");
        setBtnIsLoading(false);
        setCurrentStepper((prevState) => prevState + 1);
      });
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <>
      <Modal
        title={t("add_client")}
        open={isModalOpen}
        width={isSmallDevice ? "100% " : "60%"}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        <div className="w-full md:max-h-[calc(100vh-200px)] overflow-y-auto">
          <AddClientContent
            setAddedClient={(value: any) => {
              mutate().then(() => {
                setFieldValue("contract.clientID", value.clientID);
              });
            }}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      </Modal>
      <div className="w-full h-fit flex flex-col gap-4 items-center">
        <Form
          labelCol={{ span: 6 }}
          labelWrap
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          className="w-full md:w-2/3 "
        >
          <FormItem label={t("clients_lookup")}>
            <Row gutter={8}>
              <Col flex="auto">
                <Select
                  placeholder={t("clients_lookup")}
                  filterOption={(input, option): boolean => {
                    if (!option?.client) {
                      return false;
                    }

                    const lowerInput = input.toLowerCase();
                    const { client } = option;
                    return (
                      client?.idNumber?.toLowerCase().includes(lowerInput) ||
                      client?.phone?.toLowerCase().includes(lowerInput) ||
                      client?.email?.toLowerCase().includes(lowerInput) ||
                      client?.vatNumber?.toLowerCase().includes(lowerInput) ||
                      client?.fullName?.toLowerCase().includes(lowerInput) ||
                      client?.denomination
                        ?.toLowerCase()
                        .includes(lowerInput) ||
                      false
                    );
                  }}
                  allowClear
                  showSearch
                  value={contract.clientID}
                  options={clientsToOptions}
                  disabled={isLoading}
                  loading={isLoading}
                  onChange={(value) =>
                    setFieldValue("contract.clientID", value)
                  }
                  style={{ width: "100%" }}
                />
              </Col>
              <Col flex="none">
                <Button
                  type="primary"
                  className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white"
                  onClick={() => setIsModalOpen(true)}
                >
                  {t("add_client")}
                </Button>
              </Col>
            </Row>
          </FormItem>

          <FormItem label={t("rentPeriod")}>
            <RangePicker
              showTime
              value={[
                contract.startDate ? dayjs(contract.startDate) : null,
                contract.endDate ? dayjs(contract.endDate) : null,
              ]}
              minDate={dayjs()}
              disabledDate={disabledDate}
              dateRender={dateCellRender}
              renderExtraFooter={() => (
                <div className="w-full flex gap-2  justify-center items-center">
                  <Tag color="#c0392b" className="w-6 !h-6" />
                  <span>{t("field_in_red_are_reserved")}</span>
                </div>
              )}
              placement="bottomLeft"
              onChange={(_, date) => {
                const [startDate, endDate] = date;

                setFieldValue(
                  "contract.calculated_days",
                  calculateRentalDays(startDate, endDate),
                );

                setFieldValue(
                  "contract.startDate",
                  moment(startDate).toISOString(),
                );
                setFieldValue(
                  "contract.endDate",
                  moment(endDate).toISOString(),
                );
              }}
            />

            {contract.endDate && contract.startDate && (
              <Tag color="green" className="!ml-4 my-2">
                {`${calculateRentalDays(contract.startDate, contract.endDate)} ${t("days")}`}
              </Tag>
            )}
          </FormItem>
          <FormItem label={t("kmCount")}>
            <InputNumber
              className="w-full"
              addonAfter={t("KM")}
              placeholder={t("kmCount")}
              value={values.contract.kmCount}
              min={0}
              controls={false}
              onChange={(value) => {
                setFieldValue("contract.kmCount", value);
              }}
            />
          </FormItem>
          <FormItem label={t("dayPrice")}>
            <InputNumber
              className="w-full"
              placeholder={t("dayPrice")}
              controls={false}
              defaultValue={values.contract.dayPrice || 0}
              addonAfter={
                <div>
                  {t("TND")}
                  {contract.dayPrice
                    ? contract.endDate &&
                      contract.startDate && (
                        <span>
                          {` = ${Number(
                            calculateRentalDays(
                              contract.startDate,
                              contract.endDate,
                            ) * contract.dayPrice,
                          ).toFixed(3)} ${t("TND")}`}
                        </span>
                      )
                    : null}
                </div>
              }
              onChange={(value) => {
                setFieldValue("contract.isdayPriceChanged", true);
                setFieldValue("contract.dayPrice", value);
              }}
            />
          </FormItem>
          <FormItem label={t("calculated_days")}>
            <InputNumber
              className="w-full"
              placeholder={t("calculated_days")}
              controls={false}
              value={values.contract.calculated_days}
              addonAfter={
                <div>
                  {t("days")}
                  {contract.dayPrice ? (
                    contract.calculated_days ? (
                      <span>
                        {` = ${Number(
                          contract.calculated_days * contract.dayPrice,
                        ).toFixed(3)} ${t("TND")}`}
                      </span>
                    ) : null
                  ) : null}
                </div>
              }
              onChange={(value) => {
                setFieldValue("contract.calculated_days", value);
              }}
            />
          </FormItem>
          <FormItem label={t("FuelCount")}>
            <Slider
              className="w-full"
              marks={marks}
              step={null}
              defaultValue={0.5}
              max={1}
              onChange={(value) => {
                setFieldValue("contract.FuelCount", value);
              }}
            />
          </FormItem>
          <FormItem label={t("comment")}>
            <TextArea
              className="w-full"
              placeholder={t("comment")}
              rows={2}
              onChange={(value) => {
                setFieldValue("contract.comment", value.target.value);
              }}
            />
          </FormItem>
          <Divider />
          <CautionContent />
          <Divider />
          <FormItem label={t("extra_description")}>
            <TextArea
              className="w-full"
              value={contract.extraDescription}
              placeholder={t("extra_description")}
              rows={4}
              onChange={(value) => {
                setFieldValue("contract.extraDescription", value.target.value);
              }}
            />
          </FormItem>
          <FormItem label={t("extra_price")}>
            <InputNumber
              className="w-full"
              placeholder={t("extra_price")}
              controls={false}
              defaultValue={values?.contract.extraPrice || undefined}
              addonAfter={<div>{t("TND")}</div>}
              onChange={(value) => {
                setFieldValue("contract.extraPrice", value);
              }}
            />
          </FormItem>
          <Divider />

          <FormItem name={`contract.paymentMethod`} label={t("paymentMethod")}>
            <Select
              placeholder={t("paymentMethod")}
              value={contract.paymentMethod}
              loading={isEnumsLoading}
              onChange={(value) =>
                setFieldValue(`contract.paymentMethod`, value)
              }
              options={enumsToSelectOptions(enums?.["paymentPasserelle"])}
            />
          </FormItem>

          <Divider />
          <FormItem label={t("galleryBefore")}>
            <UploadWithDrag
              hint={t("galleryBefore")}
              accept="image/*"
              onRemove={(file) => {
                if (file) {
                  const fileName = file.response.files.files[0].name;
                  const filesCleared = contract.galleryBefore.filter((file) =>
                    file.includes(fileName),
                  );
                  setFieldValue("contract.galleryBefore", filesCleared);
                  return true;
                }
              }}
              setFileValue={(file) => {
                setFieldValue("contract.galleryBefore", [
                  ...contract.galleryBefore,
                  `/media/${file.name}`,
                ]);
              }}
              maxCount={10}
            />
          </FormItem>
        </Form>
        <div className="flex gap-4 flex-col md:flex-row">
          <ConfirmCreatContractModal
            isBtnLoading={isBtnLoading}
            handleCreateContract={handleCreateContract}
          />
        </div>
      </div>
    </>
  );
}

export default ContractInformations;
