import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Table } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import PayementAddModalContent from "./PayementAddModalContent";
import { PayementListingColumns } from "./PayementListingColumns";
import { RentContracts } from "../../../../../../../Types";

interface RentPayementProps {
  RentContract: RentContracts | null;
  showAddBtn?: Boolean;
}

const PayementContent: React.FC<RentPayementProps> = ({
  RentContract,
  showAddBtn = true,
}) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const columns = PayementListingColumns(t);

  const Header = () => {
    return (
      <div className="w-full  flex justify-between items-center px-4">
        <span className="font-bold">
          {t("RENT_PAYMENT_MANAGEMENT").toUpperCase()}
        </span>
        {showAddBtn ? (
          <PlusCircleOutlined
            className="cursor-pointer"
            onClick={() => setIsModalOpen(true)}
          />
        ) : null}
      </div>
    );
  };

  const closeModalHandler = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {isModalOpen ? (
        <Modal
          footer={null}
          open={isModalOpen}
          onClose={closeModalHandler}
          onCancel={closeModalHandler}
          width={400}
        >
          <PayementAddModalContent
            closeModalHandler={closeModalHandler}
            rentContractID={RentContract?.rentContractID || ""}
          />
        </Modal>
      ) : null}
      <div>
        <Table
          key="RentContractCautionID"
          size="small"
          title={Header}
          columns={columns as any}
          dataSource={RentContract?.RentContractPayement || []}
          bordered={false}
          scroll={{ x: 500 }}
        />
      </div>
    </>
  );
};

export default PayementContent;
