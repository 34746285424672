import React, { useMemo, useState } from "react";
import {
  Button,
  Card,
  Modal,
  Select,
  Statistic,
  StatisticProps,
  Table,
} from "antd";
import {
  CashAccountsColumns,
  CashTransactionsColumns,
} from "./cashAccountsColumns";
import { useTranslation } from "react-i18next";
import { useCompanyAccountsData } from "../../../../../Hooks/useCompanyAccountsData";
import CountUp from "react-countup";
import { AddCashTransactionContent } from "./AddCashTransaction";
import { useMediaQuery } from "@mui/material";
import DinardIcon from "../../../../UI/DinardIcon";
import { useAccountsStatsData } from "../../../../../Hooks/useAccountsStatsData";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  ForwardOutlined,
} from "@ant-design/icons";
import ExportData from "./exportData";
import { Account } from "../../../../../Types";

function CashTransactionsContent() {
  const { accounts, isLoading, mutate } = useCompanyAccountsData();
  const { t } = useTranslation();
  const columns = CashAccountsColumns(t);
  const [selectedStatFilter, setSelectedStatFilter] = useState("MONTH");
  const {
    accountsStats,
    isLoading: isAccountsDataLoading,
    mutate: mutateStat,
  } = useAccountsStatsData();
  const [open, setOpen] = useState(false);

  const expandedColumns = CashTransactionsColumns(t);
  const expandedRowRender = (record: any) => {
    return (
      <div className="w-full flex flex-col">
        <Table
          rowKey="TransactionId"
          columns={expandedColumns}
          dataSource={record.transactions}
          bordered={false}
        />
      </div>
    );
  };

  const filterKey = useMemo(() => {
    return selectedStatFilter === "MONTH" ? "stats" : "statsToday";
  }, [selectedStatFilter]);

  const availableBalance = useMemo(() => {
    if (!accountsStats) {
      return 0;
    }
    const INCOME = accountsStats[filterKey].some(
      (stat: any) => stat.type === "INCOME",
    )
      ? accountsStats[filterKey].filter(
          (stat: any) => stat.type === "INCOME",
        )?.[0]?._sum?.amount
      : 0;

    const EXPENSE = accountsStats[filterKey].some(
      (stat: any) => stat.type === "EXPENSE",
    )
      ? accountsStats[filterKey].filter(
          (stat: any) => stat.type === "EXPENSE",
        )?.[0]?._sum?.amount
      : 0;

    const TRANSFER = accountsStats[filterKey].some(
      (stat: any) => stat.type === "TRANSFER",
    )
      ? accountsStats[filterKey].filter(
          (stat: any) => stat.type === "TRANSFER",
        )?.[0]?._sum?.amount
      : 0;

    return INCOME - EXPENSE - TRANSFER;
  }, [accountsStats, filterKey]);

  const formatter: StatisticProps["formatter"] = (value) => (
    <CountUp end={value as number} separator="," />
  );

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  columns.push({
    title: t("action").toUpperCase(),
    dataIndex: "AccountId",
    key: "AccountId",
    align: "center",
    render: (value: any, reccord: Account) => <ExportData Account={reccord} />,
  });
  return (
    <div className="w-full  overflow-scroll p-10">
      {open ? (
        <Modal
          footer={null}
          open={open}
          onClose={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          width={isSmallDevice ? "100%" : "50%"}
        >
          <AddCashTransactionContent mutate={mutate} mutateStat={mutateStat} />
        </Modal>
      ) : null}
      <div className="flex flex-col md:flex-row justify-between w-full gap-4 items-center px-6 mb-6">
        <Select
          defaultValue={selectedStatFilter}
          onChange={(value) => setSelectedStatFilter(value)}
          style={{ width: 220 }}
          options={[
            { value: "TODAY", label: t("STATS_TODAY") },
            { value: "MONTH", label: t("STATS_MONTH") },
          ]}
        />{" "}
      </div>
      <div className="flex flex-col md:flex-row justify-between w-full gap-4 items-center px-6 mb-6">
        {accountsStats?.[filterKey] &&
          accountsStats[filterKey].map((stat: any) => {
            return (
              <Card bordered={true}>
                <Statistic
                  loading={isAccountsDataLoading}
                  title={`${t(stat.type.toLowerCase() + "_company_balance")} (${t("TND")}) `}
                  value={stat._sum.amount}
                  valueStyle={{
                    color:
                      stat.type === "INCOME"
                        ? "#16a085"
                        : stat.type === "EXPENSE"
                          ? "#cf1322"
                          : "#f39c12",
                  }}
                  precision={3}
                  formatter={formatter}
                  suffix={
                    stat.type === "INCOME" ? (
                      <CaretUpOutlined />
                    ) : stat.type === "EXPENSE" ? (
                      <CaretDownOutlined />
                    ) : (
                      <ForwardOutlined />
                    )
                  }
                  prefix={
                    <DinardIcon
                      style={{
                        fill:
                          stat.type === "INCOME"
                            ? "#16a085"
                            : stat.type === "EXPENSE"
                              ? "#cf1322"
                              : "#f39c12",
                      }}
                    />
                  }
                />
              </Card>
            );
          })}
        {accountsStats?.stats && (
          <Card bordered={true}>
            <Statistic
              loading={isAccountsDataLoading}
              title={`${t("available_company_balance")} (${t("TND")}) `}
              value={availableBalance}
              precision={3}
              formatter={formatter}
              prefix={
                <DinardIcon
                  style={{
                    fill: "black",
                  }}
                />
              }
            />
          </Card>
        )}
      </div>
      <div className="flex justify-between w-full items-center px-6 mb-6">
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs px-6  md:px-12  !text-white mt-4"
          onClick={() => setOpen(true)}
        >
          {t("add_transaction").toUpperCase()}
        </Button>
      </div>

      <Table
        rowKey="AccountId"
        size="small"
        columns={columns}
        loading={isLoading}
        dataSource={accounts}
        rootClassName="h-2/3 min-h-2/3 "
        expandable={{
          expandedRowRender,
        }}
        scroll={{ x: 500 }}
        bordered={false}
      />
    </div>
  );
}

export default CashTransactionsContent;
