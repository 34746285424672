import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";

export const addBulkFleetColumns: (
  t: any,
) => (ColumnGroupType<any> | ColumnType<any>)[] = (t) => [
  {
    title: t("car_number"),
    dataIndex: "num",
    key: "num",
    render: (value, record) => `${record.serie} ${t("TU")} ${value}`,
  },
  {
    title: "VIN",
    dataIndex: "vin",
    key: "vin",
  },
  {
    title: t("manufacturer"),
    dataIndex: "manufacturer",
    key: "manufacturer",
  },
  {
    title: t("car_model"),
    dataIndex: "modal",
    key: "modal",
  },

  {
    title: t("type"),
    dataIndex: "type",
    render: (value: string) => t(value),
  },
  {
    title: t("color"),
    dataIndex: "color",
    render: (value: string) => t(value),
  },
  {
    title: t("nb_places"),
    dataIndex: "places",
  },
  {
    title: t("power"),
    dataIndex: "numberHorses",
    render: (value: string) => `${value} ${t("horse_power")}`,
  },
  {
    title: t("fuel"),
    dataIndex: "fuel",
    render: (value: string) => t(value),
  },
  {
    title: "First Registration",
    dataIndex: "firstRegistration",
    key: "firstRegistration",
  },
  {
    title: t("transmission"),
    dataIndex: "transmission",
    render: (value) => t(value),
  },
];
