import React, { useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
} from "antd";
import { Field, Form as FormikForm, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import UploadWithDrag from "../../../../../UI/UploadWithDrag";
import dayjs from "dayjs";
import { Axios } from "../../../../../../Config";
import { toast } from "react-toastify";
import { mutate } from "swr";

const initialValues = {
  maintenanceDate: moment.now(),
  serviceType: null,
  description: null,
  quantity: null,
  cost: null,
  billNumber: null,
  kilometrage: null,
  bill_copy: null,
};
const AddMaintenanceForm = ({ fleetID }: { fleetID?: string }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [btnIsLoading, setBtnIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      setBtnIsLoading(true);
      const data = { ...values, fleetID };
      await Axios.post("/fleet-maintenance", data);
      mutate("/enterprise/fleet").then(() => {
        formikHelpers.setValues(initialValues);
        toast.success(t("maintenance_record_added_successfully"));
        setBtnIsLoading(false);
        setOpenModal(false);
      });
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  return (
    <>
      <div className="w-full flex gap-4 flex-col md:flex-row   justify-end">
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          onClick={() => {
            setOpenModal(true);
          }}
        >
          {t("add_maintenance").toUpperCase()}
        </Button>
      </div>
      {openModal ? (
        <Modal
          width={isSmallDevice ? "100% " : "40%"}
          title={t("add_maintenance").toUpperCase()}
          footer={null}
          open={openModal}
          style={{ top: 20 }}
          destroyOnClose={true}
          onClose={() => {
            setOpenModal(false);
          }}
          onCancel={() => {
            setOpenModal(false);
          }}
        >
          <div className="p-6 h-[600px] max-h-[600px] overflow-auto">
            <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
              {({ setFieldValue, handleSubmit }) => (
                <Form
                  layout="vertical"
                  onFinish={handleSubmit}
                  component={FormikForm}
                >
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label={t("service_type")}
                        name="serviceType"
                        className="w-full"
                      >
                        <Field
                          name="serviceType"
                          as={Input}
                          onChange={(value: any) =>
                            setFieldValue("serviceType", value.target.value)
                          }
                          className="w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("description")}
                        name="description"
                        className="w-full"
                      >
                        <Field
                          name="description"
                          as={TextArea}
                          onChange={(value: any) =>
                            setFieldValue("description", value.target.value)
                          }
                          className="w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("quantity")}
                        name="quantity"
                        className="w-full"
                      >
                        <Field
                          name="quantity"
                          as={InputNumber}
                          controls={false}
                          min={0}
                          onChange={(value: any) =>
                            setFieldValue("quantity", value)
                          }
                          className="!w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("cost")}
                        name="cost"
                        className="w-full"
                      >
                        <Field
                          name="cost"
                          as={InputNumber}
                          controls={false}
                          min={0}
                          onChange={(value: any) => {
                            console.log(value);
                            setFieldValue("cost", value);
                          }}
                          addonAfter={t("TND")}
                          className="!w-full"
                        />
                      </Form.Item>

                      <Form.Item
                        label={t("bill_number")}
                        name="billNumber"
                        className="w-full"
                      >
                        <Field
                          name="billNumber"
                          as={Input}
                          className="!w-full"
                          onChange={(value: any) => {
                            setFieldValue("billNumber", value.target.value);
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label={t("kilometrage")}
                        name="kilometrage"
                        className="w-full"
                      >
                        <Field
                          name="kilometrage"
                          as={InputNumber}
                          controls={false}
                          min={0}
                          onChange={(value: any) => {
                            setFieldValue("kilometrage", value);
                          }}
                          addonAfter={t("KM")}
                          className="!w-full"
                        />
                      </Form.Item>

                      <Form.Item
                        label={t("maintenance_date")}
                        name="maintenanceDate"
                      >
                        <DatePicker
                          value={moment(initialValues.maintenanceDate)}
                          className="w-full"
                          format="DD/MM/YYYY"
                          maxDate={dayjs(new Date())}
                          onChange={(date) =>
                            setFieldValue("maintenanceDate", date)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("maintenance_bill_optional")}
                        name="maintenance_bill_copy"
                      >
                        <UploadWithDrag
                          maxCount={1}
                          setFileValue={(file) =>
                            setFieldValue("bill_copy", file.fileID)
                          }
                          uploadText={t("maintenance_bill_copy").toUpperCase()}
                          hint={t("add_maintenance_bill_prompt")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <Button
                      loading={btnIsLoading}
                      type="primary"
                      htmlType="submit"
                      className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white w-full "
                    >
                      {t("add_maintenance")}
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default AddMaintenanceForm;
