import React from "react";
import { Fleet } from "../../../../../Types";
import { Card, Divider, Drawer } from "antd";
import { DrawerProps } from "antd/es/drawer";
import { useTranslation } from "react-i18next";
import { SquareX } from "lucide-react";
import FleetGeneralInfos from "./FleetGeneralInfos";
import FleetInNumbers from "./FleetInNumbers";
import FleetPlanning from "./FleetPlanning";
import FleetInsurance from "./FleetInsurance/FleetInsurance";
import FleetClaims from "./FleetClaims";
import FleetMaintenance from "./FleetMaintenance/FleetMaintenance";
import moment from "moment";
import FleetComments from "./FleetComments";

interface FleetInfosDrawerProps extends DrawerProps {
  fleet: Fleet | null;
}

const FleetInfosDrawer: React.FC<FleetInfosDrawerProps> = ({
  fleet,
  ...props
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const Title = () => {
    return (
      <div className="w-full flex    !text-[16px]  items-center gap-3  ">
        <span>{t("vehicle_sheet").toUpperCase()} </span>
      </div>
    );
  };

  if (!fleet) return null;

  return (
    <Drawer {...props} title={<Title />} closeIcon={<SquareX size={20} />}>
      <div
        className="w-full flex flex-col   min-h-fit"
        dir={language === "ar" ? "rtl" : "ltr"}
      >
        <FleetGeneralInfos fleet={fleet} />
        <Divider />
        <FleetInNumbers fleet={fleet} />
        <Divider />
        <FleetPlanning fleet={fleet} />
        <Divider />
        <FleetInsurance fleet={fleet} />
        <Divider />
        <FleetClaims fleet={fleet} />
        <Divider />
        <FleetMaintenance fleet={fleet} />
        <Divider />
        <FleetComments fleet={fleet} />
        <Divider />
        <Card className="flex flex-col">
          <p>
            {t("created_by")} : {fleet?.createdBy.name}{" "}
            {fleet?.createdBy.lastName}
          </p>
          <p>
            {t("date_of_creations")} :{" "}
            {moment(fleet?.createdAt).format("DD/MM/YYYY HH:MM")}
          </p>
        </Card>
      </div>
    </Drawer>
  );
};

export default FleetInfosDrawer;
