import React from "react";
import { Route } from "react-router-dom";
import { ForgotPasswordPage, LoginPage, OtpVerificationPage } from "../Pages";
import Notfound from "../Pages/AppPages/notfound";
import LandingPage from "../Pages/AppPages/LandingPage";
import { RenderRoutes } from "../Config";
import { UserVerificationPage } from "../Pages/AuthPages/userVerificationPage";

export function AuthRoutes() {
  return (
    <RenderRoutes>
      <Route path="/Notfound" element={<Notfound />} />
      <Route path="/LandingPage" element={<LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot_password" element={<ForgotPasswordPage />} />
      <Route path="/forgot_password/verify" element={<OtpVerificationPage />} />
      <Route path="/account-verify/:token" element={<UserVerificationPage />} />
    </RenderRoutes>
  );
}
