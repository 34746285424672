import React from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../Layouts";
import PageLayout from "../../Layouts/PageLayout";
import FleetScheduler from "../../Components/Features/CompanyComponents/FleetManagement/FleetScheduler";
import { useCompanyRentByCarData } from "../../Hooks/useCompanyRentByCarData";

const FleetSchedulerPage = () => {
  const { t } = useTranslation();
  const { contracts } = useCompanyRentByCarData();

  return (
    <AppLayout>
      <PageLayout title={t("fleet_Scheduler")}>
        <div className="h-fit md:h-screen w-full bg-white rounded-xl shadow-accent-foreground shadow-2xl">
          <div
            className=" w-3/4 md:w-full flex  min-h-96 bg-white rounded-2xl   "
            dir="ltr"
          >
            <FleetScheduler contracts={contracts} />
          </div>
        </div>
      </PageLayout>
    </AppLayout>
  );
};

export default FleetSchedulerPage;
