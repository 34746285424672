import React, { useMemo, useState } from "react";
import { Modal, Skeleton, Table } from "antd";
import { usePointsTransactionsData } from "../../../../Hooks/usePointsTransactionsData";
import { pointsTransactionsColumns } from "./PointsTransactionsColumns";
import { useTranslation } from "react-i18next";
import CsvDownloadButton from "react-json-to-csv";
import TollIcon from "@mui/icons-material/Toll";
import moment from "moment";
import { useCompanyRentContractsData } from "../../../../Hooks/useCompanyRentContractsData";
import { RentPrintableContract } from "../../../UI/Printables/RentPrintableContract";
import { useSelector } from "react-redux";

function PointsTransactionsListing() {
  const { t } = useTranslation();
  const { pointsTransactions, points, isLoading } =
    usePointsTransactionsData(2000);
  const { contracts, isLoading: isContractLoading } =
    useCompanyRentContractsData();
  const user = useSelector((state: any) => state.authentication.user);

  const [selectedContract, setSelectedContract] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const dataToExport = useMemo(() => {
    if (pointsTransactions.length) {
      return pointsTransactions.map((transaction) => {
        return {
          ["date de transaction"]: moment(transaction.createdAt).format(
            "DD/MM/YYYY HH:mm",
          ),
          ["Nom de l'entreprise"]:
            transaction.company.rsLatin || transaction.company.rsArabic,
          ["Type de la transaction"]: transaction.transactionType,
          debit:
            transaction.transactionType === "DEBIT" ? transaction.points : 0,
          credit:
            transaction.transactionType === "CREDIT" ? transaction.points : 0,
          note: transaction.note,
        };
      });
    } else return [];
  }, [pointsTransactions]);

  const selectContractToShow = (contractID: string) => {
    setSelectedContract(contractID);
    setOpen(true);
  };
  const displayContract = useMemo(() => {
    if (selectedContract) {
      return contracts.filter((contract) => {
        return contract.rentContractID == selectedContract;
      });
    } else {
      return null;
    }
  }, [selectedContract, contracts]);

  const columns = pointsTransactionsColumns(t, selectContractToShow);

  if (!user.AgencesUsers.length) {
    columns.push({
      title: t("agenceName"),
      dataIndex: "agence",
      key: "agenceName",
      render: (value) => {
        return value ? value.agenceName : "-";
      },
    });
  }

  return (
    <div className="w-full h-fit overflow-scroll p-10 ">
      {open ? (
        <Modal
          width="60%"
          open={open}
          footer={null}
          onCancel={() => setOpen(false)}
          onClose={() => setOpen(false)}
        >
          {displayContract ? (
            <RentPrintableContract contract={displayContract[0]} />
          ) : null}
        </Modal>
      ) : null}
      <div className="  flex justify-between gap-4 items-center ">
        <CsvDownloadButton
          data={dataToExport}
          filename="export transactions"
          className=" mb-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <svg
            className="fill-current w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
          </svg>
          <span>{t("download_data")}</span>
        </CsvDownloadButton>

        <article className="rounded-lg pb-6">
          {points && !isLoading ? (
            <div className="w-full flex flex-col items-center justify-center">
              <p className="text-sm text-gray-500 ">{t("account_points")}</p>

              <p className="text-2xl font-medium text-gray-900">
                <TollIcon className="mr-4" />
                {points ? String(points) : <Skeleton />}
              </p>
            </div>
          ) : null}
        </article>
      </div>
      <Table
        rowKey="id"
        size="small"
        loading={isLoading}
        dataSource={(pointsTransactions.length && pointsTransactions) || []}
        columns={columns}
        rootClassName="h-2/3 min-h-2/3 "
        bordered={false}
      />
    </div>
  );
}

export default PointsTransactionsListing;
