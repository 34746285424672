import React, { useMemo } from "react";
import { Fleet } from "../../../../../Types";
import { useTranslation } from "react-i18next";
import { ChartPie, HandCoins } from "lucide-react";
import { Card, Col, Row, Statistic, StatisticProps } from "antd";
import CountUp from "react-countup";
import { filterContractsForCurrentMonth } from "../../../../../Utils";

interface FleetInNumbersProps {
  fleet: Fleet | null;
}

const FleetInNumbers: React.FC<FleetInNumbersProps> = ({ fleet }) => {
  const { t } = useTranslation();

  const ContractCount = useMemo(() => {
    if (!fleet) {
      return undefined;
    }
    return fleet.RentContract.length;
  }, [fleet]);

  const thisMonthContacts = useMemo(() => {
    if (!fleet) {
      return undefined;
    }
    return filterContractsForCurrentMonth(fleet.RentContract);
  }, [fleet]);

  const ContractCountThisMonth = useMemo(() => {
    if (!thisMonthContacts) {
      return undefined;
    }
    return thisMonthContacts.length;
  }, [thisMonthContacts]);

  const fleetTunover = useMemo(() => {
    if (!fleet) {
      return undefined;
    }
    return fleet.RentContract.reduce((previousValue, currentValue) => {
      return (
        previousValue +
        Number(
          currentValue.extraPrice
            ? currentValue.extraPrice +
                currentValue.dayPrice * currentValue.dayCount
            : currentValue.dayPrice * currentValue.dayCount,
        )
      );
    }, 0);
  }, [fleet?.fleetID]);

  const fleetTunoverThisMonth = useMemo(() => {
    if (!thisMonthContacts) {
      return undefined;
    }
    return thisMonthContacts.reduce((previousValue, currentValue) => {
      return (
        previousValue +
        Number(
          currentValue.extraPrice
            ? currentValue.extraPrice +
                currentValue.dayPrice * currentValue.dayCount
            : currentValue.dayPrice * currentValue.dayCount,
        )
      );
    }, 0);
  }, [thisMonthContacts]);

  const formatter: StatisticProps["formatter"] = (value) => (
    <CountUp end={value as number} separator=" " />
  );

  const formatterWithDecimal: StatisticProps["formatter"] = (value) => (
    <CountUp end={value as number} separator=" " decimals={3} decimal={","} />
  );

  return (
    <div className="w-full flex flex-col gap-4 min-h-fit">
      <div className=" flex   justify-between   ">
        <div className="  flex items-center gap-4 ">
          <ChartPie size={20} />
          <span className="overflow-hidden whitespace-nowrap text-ellipsis   flex-auto text-opacity-88 font-semibold text-[16px] leading-6  text-[#d35400]">
            {t("car_in_numbers").toUpperCase()}
          </span>
        </div>
      </div>

      <Row gutter={[16, 16]} wrap={true} align="stretch" justify="center">
        <Col md={12} lg={12} sm={24} xs={24}>
          <Card
            loading={!fleet}
            className="h-full flex flex-col justify-between"
          >
            <Statistic
              loading={typeof ContractCount !== "number"}
              title={t("contracts_count")}
              value={ContractCount}
              formatter={formatter}
            />
          </Card>
        </Col>
        <Col md={12} lg={12} sm={24} xs={24}>
          <Card
            loading={!fleet}
            className="h-full flex flex-col justify-between"
          >
            <Statistic
              title={t("turnover")}
              loading={typeof ContractCount !== "number"}
              value={fleetTunover}
              prefix={<HandCoins size={20} />}
              formatter={formatterWithDecimal}
              suffix={t("TND")}
            />
          </Card>
        </Col>
        <Col md={12} lg={12} sm={24} xs={24}>
          <Card
            loading={!fleet}
            className="h-full flex flex-col justify-between"
          >
            <Statistic
              loading={typeof ContractCount !== "number"}
              title={t("contracts_this_month")}
              value={ContractCountThisMonth}
              formatter={formatter}
            />
          </Card>
        </Col>
        <Col md={12} lg={12} sm={24} xs={24}>
          <Card
            loading={!fleet}
            className="h-full flex flex-col justify-between"
          >
            <Statistic
              loading={typeof ContractCount !== "number"}
              title={t("monthly_turnover")}
              value={fleetTunoverThisMonth}
              formatter={formatterWithDecimal}
              prefix={<HandCoins size={20} />}
              suffix={t("TND")}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default FleetInNumbers;
