import React, { useMemo, useRef, useState } from "react";
import { Dropdown, MenuProps, Modal, Table } from "antd";
import { CompanyBillsColumns } from "./CompanyBillsColumns";
import { useTranslation } from "react-i18next";
import Search from "antd/es/input/Search";
import { useCompanyBillsData } from "../../../../../Hooks/useCompanyBillsData";
import { CompanyBillPrintable } from "../../../../UI/Printables/CompanyBillPrintable";
import { useMediaQuery } from "@mui/material";
import {
  CloseCircleOutlined,
  EyeFilled,
  IssuesCloseOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Axios } from "../../../../../Config";
import { toast } from "react-toastify";
import { mutate } from "swr";

export function CompanyBillsListing() {
  const { t } = useTranslation();

  const { isLoading, bills } = useCompanyBillsData();
  const [open, setOpen] = useState(false);
  const [selectedBill, setSelectedBill] = useState<null | string>(null);

  const chooseBillHandler = (BillID: string | null) => {
    setOpen(true);
    setSelectedBill(BillID);
  };
  const columns = CompanyBillsColumns(t);

  const asyncToast = useRef<any>();

  const changeBillStatus = async (billID: string, status: string) => {
    try {
      asyncToast.current = toast.loading(
        t("CHANGE_INVOICE_STATUS_ONGOING_MESSAGE"),
      );
      console.log(billID);
      await Axios.put("/companyBills", {
        billID,
        status,
      }).then((res) => res.data.message);

      await mutate("/companyBills");
      toast.update(asyncToast.current, {
        render: t("CHANGE_INVOICE_STATUS_SUCCESS_MESSAGE"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error: any) {
      toast.update(asyncToast.current, {
        render: t(
          error?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  columns.push({
    title: t("actions"),
    dataIndex: "billID",
    key: "actions",
    render: (value, record) => {
      let items: MenuProps["items"] = [];

      if (record.status === "PENDING") {
        items = [
          {
            label: (
              <span onClick={() => changeBillStatus(value, "VALIDATED")}>
                {t("VALIDATE_INVOICE")}
              </span>
            ),
            icon: <MinusCircleOutlined />,
            key: "1",
          },
          {
            label: (
              <span onClick={() => changeBillStatus(value, "REFUSED")}>
                {t("INVOICE_GOT_REFUSED")}
              </span>
            ),
            icon: <CloseCircleOutlined />,
            key: "2",
          },
          {
            label: (
              <span onClick={() => changeBillStatus(value, "ON_HOLD")}>
                {t("SET_INVOICE_ON_HOLD")}
              </span>
            ),
            icon: <CloseCircleOutlined />,
            key: "3",
          },
        ];
      } else if (record.status === "ON_HOLD") {
        items = [
          {
            label: (
              <span onClick={() => changeBillStatus(value, "VALIDATED")}>
                {t("VALIDATE_INVOICE")}
              </span>
            ),
            icon: <MinusCircleOutlined />,
            key: "1",
          },
          {
            label: (
              <span onClick={() => changeBillStatus(value, "REFUSED")}>
                {t("INVOICE_GOT_REFUSED")}
              </span>
            ),
            icon: <CloseCircleOutlined />,
            key: "2",
          },
        ];
      } else if (record.status === "VALIDATED") {
        items = [
          {
            label: (
              <span onClick={() => changeBillStatus(value, "PAID")}>
                {t("SET_INVOICE_PAID")}
              </span>
            ),
            icon: <MinusCircleOutlined />,
            key: "1",
          },
        ];
      } else {
        items = [
          {
            label: <span>{t("NO_ACTION_TO_TAKEN_ON_INVOICE_STATUS")}</span>,

            key: "5",
          },
        ];
      }

      return (
        <div className="flex justify-center items-center gap-4">
          <EyeFilled
            className="cursor-pointer mx-auto"
            onClick={() => {
              chooseBillHandler(value);
            }}
          />
          <Dropdown menu={{ items }} trigger={["click"]}>
            <IssuesCloseOutlined />
          </Dropdown>
        </div>
      );
    },
  });

  const [search, setSearch] = useState("");
  const dataToDisplay = useMemo(() => {
    return search
      ? bills.filter((bill) => {
          return Boolean(
            bill.billID?.toLowerCase().includes(search.toLowerCase()) ||
              bill.client.fullName
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              bill.client.denomination
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              bill.client.vatNumber
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              bill.client.idNumber
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              bill.reference?.toLowerCase().includes(search.toLowerCase()),
          );
        })
      : bills;
  }, [bills, search]);

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <div className="w-full overflow-scroll p-10 !relative">
      {open && (
        <Modal
          width={isSmallDevice ? "100% " : "60%"}
          open={open}
          footer={null}
          onCancel={() => {
            setOpen(false);
            setSelectedBill(null);
          }}
          onClose={() => {
            setOpen(false);
            setSelectedBill(null);
          }}
        >
          <CompanyBillPrintable
            CompanyBill={
              bills.filter((bill) => bill.billID === selectedBill)[0]
            }
          />
        </Modal>
      )}
      <div className="flex gap-4 mb-4">
        <Search
          placeholder={t("search")}
          allowClear
          onSearch={(value) => {
            setSearch(value);
          }}
          style={{ width: 304 }}
        />
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={dataToDisplay}
        rowKey="billID"
        size="small"
        tableLayout={"auto"}
        sticky={false}
        rootClassName="h-2/3 min-h-2/3 max-w-full"
        bordered={false}
      />
    </div>
  );
}
