import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useClientsData } from "../../../../../Hooks/useClientsData";
import { useTranslation } from "react-i18next";
import { Button, Col, List, Modal, QRCode, Select } from "antd";
import { useFormikContext } from "formik";
import { InvoiceInitialValues } from "./AddCompanyInvoiceContent";
import { AddClientContent } from "../../ClientsManagement/addClientContent";
import { mutate } from "swr";
import { useMediaQuery } from "@mui/material";

interface propsT {
  setCurrentStepper: Dispatch<SetStateAction<number>>;
}

export function SearchClient({ setCurrentStepper }: propsT) {
  const { clients, isLoading } = useClientsData();
  const { values, setFieldValue } = useFormikContext<InvoiceInitialValues>();
  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);

  const client = useMemo(() => {
    if (!selectedClient) {
      return null;
    }
    return clients.filter((clt) => clt.clientID === selectedClient)[0];
  }, [selectedClient]);

  const { t } = useTranslation();
  const clientsToOptions = clients
    ? clients.map((client) => {
        return {
          label:
            client.fullName?.toUpperCase() ||
            client.denomination?.toUpperCase() ||
            client.vatNumber ||
            client.idNumber,
          value: client.clientID,
        };
      })
    : [];

  const nextStepHandler = () => {
    setBtnIsLoading(true);
    setFieldValue("clientID", selectedClient).then((r) =>
      setCurrentStepper((prev) => {
        return prev + 1;
      }),
    );
    setBtnIsLoading(false);
  };
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  return (
    <div className="w-full flex flex-col items-center ">
      <Modal
        title={t("add_client")}
        open={isModalOpen}
        width={isSmallDevice ? "100% " : "60%"}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        <div className="w-full md:max-h-[calc(100vh-200px)] overflow-y-auto">
          <AddClientContent
            setAddedClient={(value: any) => {
              mutate("/clients").then(async () => {
                await setFieldValue("clientID", value.clientID);
              });
            }}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      </Modal>
      <div className="w-full md:w-1/2  flex gap-4 flex-col md:flex-row items-center justify-evenly mb-8">
        <Select
          showSearch
          allowClear
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          placeholder={t("clients_lookup")}
          optionFilterProp="children"
          value={values.clientID || selectedClient}
          style={{ width: "100%" }}
          options={clientsToOptions}
          disabled={isLoading}
          loading={isLoading}
          onChange={(value) => {
            setSelectedClient(value);
          }}
        />
        <Col flex="none">
          <Button
            type="primary"
            className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white"
            onClick={() => setIsModalOpen(true)}
          >
            {t("add_client")}
          </Button>
        </Col>
      </div>

      {client && (
        <List bordered size="default" rootClassName="w-full md:w-2/4">
          <List.Item>
            <List.Item.Meta
              avatar={
                <QRCode
                  value={client?.clientID || "-"}
                  size={80}
                  bordered={false}
                />
              }
              title={
                <span>
                  {client.denomination?.toUpperCase() ||
                    client.fullName?.toUpperCase()}
                </span>
              }
              description={
                <div className="w-full flex flex-col gap-2">
                  <span>{client.address}</span>
                  <span>{client.email}</span>
                  <span>{client.phone}</span>
                </div>
              }
            />
          </List.Item>
        </List>
      )}

      <Button
        type="primary"
        className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4"
        disabled={!selectedClient}
        onClick={nextStepHandler}
        loading={isBtnLoading}
      >
        {t("next").toUpperCase()}
      </Button>
    </div>
  );
}
