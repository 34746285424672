import React, { useMemo, useState } from "react";
import { Radio, Table } from "antd";
import { useClientsData } from "../../../../../Hooks/useClientsData";
import {
  ClientsExpendableListingColumns,
  ClientsListingColumns,
} from "./ClientsListingColumns";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Search from "antd/es/input/Search";
import ClientInfosDrawer from "../ClientInfosDrawer";
import { useMediaQuery } from "@mui/material";
import { EyeOutlined } from "@ant-design/icons";
import { ClientFilesContent } from "../../../../UI/ClientFilesContent";
import { UserRoundPen } from "lucide-react";
import ClientEditModal from "./ClientEditModal";

function ClientsListingContent() {
  const { isLoading, clients } = useClientsData();

  const { t } = useTranslation();
  const columns = ClientsListingColumns(t);
  const user = useSelector((state: any) => state.authentication.user);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [openedClient, setOpenedClient] = useState<string | null>(null);
  const [openEditUser, setOpenEditUser] = useState<string | null>(null);

  if (!user.AgencesUsers.length) {
    columns.push({
      title: t("agenceName"),
      dataIndex: "agence",
      key: "agenceName",
      align: "center",
      render: (value: any) => {
        return value ? value.agenceName : "-";
      },
    });
  }

  const openDrawer = (clientID: string) => {
    setIsDrawerOpen(true);
    setOpenedClient(clientID);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setOpenedClient(null);
  };

  const selectedClient = useMemo(() => {
    if (!openedClient) {
      return null;
    } else {
      return clients.filter((client) => client.clientID === openedClient)[0];
    }
  }, [openedClient, clients]);

  columns.push({
    title: t("actions"),
    dataIndex: "clientID",
    key: "actions",
    align: "center",
    render: (value: string) => {
      return (
        <div className="flex gap-3 items-center mx-auto">
          <EyeOutlined
            className="cursor-pointer"
            onClick={() => {
              openDrawer(value);
            }}
          />
          <UserRoundPen
            size={15}
            className="cursor-pointer"
            onClick={() => {
              setOpenEditUser(value);
            }}
          />
        </div>
      );
    },
  });

  const [search, setSearch] = useState("");
  const [searchStatus, setSearchStatus] = useState<"ALL" | "pp" | "pm">("ALL");

  const dataToDisplay = useMemo(() => {
    let filterClients = clients || [];

    if (searchStatus === "ALL") {
      filterClients = clients;
    }

    if (searchStatus === "pp") {
      filterClients = filterClients.filter((client) => {
        return Boolean(client.clientType === "PP");
      });
    }
    if (searchStatus === "pm") {
      filterClients = filterClients.filter((client) => {
        return Boolean(client.clientType === "PM");
      });
    }

    return search
      ? filterClients.filter((client) => {
          return Boolean(
            client.vatNumber?.toLowerCase().includes(search.toLowerCase()) ||
              client.email?.toLowerCase().includes(search.toLowerCase()) ||
              client.idNumber?.toLowerCase().includes(search.toLowerCase()) ||
              client.fullName?.toLowerCase().includes(search.toLowerCase()) ||
              client.address?.toLowerCase().includes(search.toLowerCase()) ||
              client.phone?.toLowerCase().includes(search.toLowerCase()) ||
              client.userID?.toLowerCase().includes(search.toLowerCase()) ||
              client.clientID?.toLowerCase().includes(search.toLowerCase()) ||
              client.denomination?.toLowerCase().includes(search.toLowerCase()),
          );
        })
      : filterClients;
  }, [clients, search, searchStatus]);

  const expandedColumns = ClientsExpendableListingColumns(t);

  const expandedRowRender = (record: any) => {
    return (
      <div className="w-full flex flex-col">
        <Table
          columns={expandedColumns}
          dataSource={[record]}
          pagination={false}
          bordered={false}
          direction="rtl"
        />
        <ClientFilesContent client={record} />
      </div>
    );
  };
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const expandable = {
    expandedRowRender,
  };
  return (
    <div className="w-full  overflow-scroll p-10">
      {openEditUser ? (
        <ClientEditModal
          closeModal={() => setOpenEditUser(null)}
          client={
            clients.filter((client) => client.clientID === openEditUser)[0]
          }
        />
      ) : null}
      {isDrawerOpen ? (
        <ClientInfosDrawer
          client={selectedClient}
          open={isDrawerOpen}
          onClose={closeDrawer}
          width={isSmallDevice ? "100%" : "55%"}
        />
      ) : null}
      <div className="flex gap-4 mb-4 justify-between flex-col md:flex-row min-w-fit">
        <div className="flex gap-4">
          <Search
            placeholder={t("search")}
            allowClear
            disabled={isLoading}
            onSearch={(value) => {
              setSearch(value);
            }}
            style={{ width: 304 }}
          />
        </div>
        <Radio.Group
          disabled={isLoading}
          value={searchStatus}
          onChange={(e) => setSearchStatus(e.target.value)}
        >
          <Radio.Button value="ALL">{t("ALL")}</Radio.Button>
          <Radio.Button value="pp">{t("PP")}</Radio.Button>
          <Radio.Button value="pm">{t("PM")}</Radio.Button>
        </Radio.Group>
      </div>

      <Table
        rowKey="clientID"
        size="small"
        columns={columns}
        dataSource={dataToDisplay}
        rootClassName="h-2/3 min-h-2/3 "
        bordered={false}
        expandable={expandable}
        loading={isLoading}
      />
    </div>
  );
}

export default ClientsListingContent;
