import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import moment from "moment/moment";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import React from "react";
import { Tag } from "antd";

export const pointsTransactionsColumns: (
  t: any,
  selectContractToShow?: any,
) => (ColumnGroupType<any> | ColumnType<any>)[] = (
  t: any,
  selectContractToShow,
) => [
  {
    title: t("DEBIT").toUpperCase(),
    dataIndex: "transactionType",
    key: "DEBIT",
    align: "center",
    render: (value, record) => {
      return value === "DEBIT" ? (
        <div className="w-full flex justify-center items-center gap-4">
          <ArrowDownOutlined className="text-[#c0392b]" />
          <span className="font-extrabold"> {record.points}</span>
        </div>
      ) : null;
    },
  },
  {
    title: t("CREDIT").toUpperCase(),
    dataIndex: "transactionType",
    key: "CREDIT",
    align: "center",
    render: (value, record) => {
      return value === "CREDIT" ? (
        <div className="w-full flex justify-center items-center gap-4 ">
          <ArrowUpOutlined className="text-[#27ae60]" />
          <span className="font-extrabold"> {record.points}</span>
        </div>
      ) : null;
    },
  },

  {
    title: t("note").toUpperCase(),
    dataIndex: "note",
    key: "note",
  },
  {
    title: t("date_of_creations").toUpperCase(),
    dataIndex: "createdAt",
    align: "center",
    key: "createdAt",
    render: (value) => moment(value).format("DD/MM/YYYY HH:mm"),
  },
  {
    title: t("transactionType").toUpperCase(),
    align: "center",
    dataIndex: "transactionType",
    key: "transactionType",
    render: (value) => {
      return (
        <Tag color={value === "DEBIT" ? "#c0392b" : "#27ae60"}>{t(value)}</Tag>
      );
    },
  },
  {
    title: t("created_by").toUpperCase(),
    dataIndex: "createdBy",
    key: "createdBy",
    align: "center",
    render: (value) => {
      return `${value.name} ${value.lastName}`;
    },
  },

  {
    title: t("rent_contracts").toUpperCase(),
    dataIndex: "rentContract",
    align: "center",
    key: "rentContract",
    render: (value) =>
      value ? (
        <span
          className="cursor-pointer   text-xs font-semibold uppercase mt-1 lg:mt-0 min-h-[24px] inline-flex items-center gap-x-1 py-0.5 px-2 text-gray-800 bg-gray-200/70 hover:bg-gray-200 rounded-md dark:bg-neutral-700 dark:hover:bg-neutral-800 dark:text-neutral-200"
          onClick={() => selectContractToShow(value.rentContractID)}
        >
          {t("voire")}
        </span>
      ) : (
        <span className="font-bold text-[#e67e22]">LOCASAFE </span>
      ),
  },
];
