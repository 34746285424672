import React, { useMemo, useRef, useState } from "react";
import {
  Button,
  Divider,
  Empty,
  Image,
  Input,
  InputNumber,
  Radio,
  Rate,
  Result,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import { useCompanyRentContractsData } from "../../../../../Hooks/useCompanyRentContractsData";
import { useTranslation } from "react-i18next";
import { RentListingColumns } from "./RentListingColumns";
import Search from "antd/es/input/Search";
import { RentPrintableContract } from "../../../../UI/Printables/RentPrintableContract";
import { SelectedCarDescription } from "../RentContractManagement/SelectedCarDescription";
import { fileUrlHandler } from "../../../../../Utils";
import { EyeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ExpandedRowDriver } from "../../../../UI/ExpandedRowDriver";
import UploadWithDrag from "../../../../UI/UploadWithDrag";
import { Axios } from "../../../../../Config";
import { toast } from "react-toastify";
import moment, { now } from "moment";
import TextArea from "antd/es/input/TextArea";
import RentCautionContent from "../RentContractManagement/CautionContent/RentCautionContent";
import FormItem from "antd/es/form/FormItem";
import RentDisplayDrawer from "./RentDisplayDrawer";
import { useMediaQuery } from "@mui/material";
import { RentContracts } from "../../../../../Types";
import { CircleX } from "lucide-react";

export function RentListingContent() {
  const { contracts, isLoading, mutate } = useCompanyRentContractsData();
  const [selectedContract, setSelectedContract] = useState<string | null>(null);
  const { t } = useTranslation();

  const asyncToast = useRef<any>();

  const cancelRentHandler = async (rentContractID: string) => {
    try {
      asyncToast.current = toast.loading(t("CANCELING_RENT_ONGOING_MESSAGE"));

      await Axios.post("/rent/cancel", { rentContractID }).then(() => {
        return mutate("/rent/contracts");
      });

      toast.update(asyncToast.current, {
        render: t("CANCELING_RENT_SUCCESS_MESSAGE"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (e: any) {
      toast.update(asyncToast.current, {
        render: t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const columns = RentListingColumns(t);
  columns.push({
    title: t("actions"),
    dataIndex: "rentContractID",
    key: "actions",
    align: "center",
    render: (value, record: RentContracts) => {
      return (
        <div className="flex gap-3 items-center justify-center  ">
          <EyeOutlined
            className="cursor-pointer"
            onClick={() => {
              setSelectedContract(value);
            }}
          />
          {record.isCanceled ||
          record.isReturned ||
          moment(now()).isAfter(moment(record.startDate)) ? null : (
            <CircleX
              className="cursor-pointer"
              size={15}
              onClick={() => {
                cancelRentHandler(value);
              }}
            />
          )}
        </div>
      );
    },
  });

  const [search, setSearch] = useState("");
  const [searchStatus, setSearchStatus] = useState<
    "ALL" | "PLANED" | "ON_GOING" | "FINISHED"
  >("ALL");

  const dataToDisplay = useMemo(() => {
    let filterContracts = contracts || [];

    if (searchStatus === "ALL") {
      filterContracts = contracts;
    }
    if (searchStatus === "PLANED") {
      filterContracts = filterContracts.filter((contract) => {
        return Boolean(moment(new Date()).diff(moment(contract.startDate)) < 0);
      });
    }
    if (searchStatus === "ON_GOING") {
      filterContracts = filterContracts.filter((contract) => {
        return Boolean(
          moment(new Date()).diff(moment(contract.startDate)) > 0 &&
            moment(new Date()).diff(moment(contract.endDate)) < 0,
        );
      });
    }
    if (searchStatus === "FINISHED") {
      filterContracts = filterContracts.filter((contract) => {
        return Boolean(moment(new Date()).diff(contract.endDate) > 0);
      });
    }

    return search
      ? filterContracts.filter((contract) => {
          return Boolean(
            contract.rentContractID
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
              contract.client.denomination
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              contract.rentedCar.registrationNumber
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              contract.rentedCar.modal
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              contract.ContractDrivers.some((driver) =>
                driver.driver.fullName
                  ?.toLowerCase()
                  .includes(search.toLowerCase()),
              ) ||
              contract.ContractDrivers.some((driver) =>
                driver.driver.idNumber
                  ?.toLowerCase()
                  .includes(search.toLowerCase()),
              ) ||
              contract.client.fullName
                ?.toLowerCase()
                .includes(search.toLowerCase()),
          );
        })
      : filterContracts;
  }, [contracts, search, searchStatus]);

  const ExpandedRowCarGallery = (props: {
    gallery: string[];
    endDate?: string;
    startDate?: string;
    rentContractID?: string;
  }) => {
    const [galleryAfter, setGalleryAfter] = useState<string[] | []>([]);
    const [rentRate, setRentRate] = useState<number>(5);
    const [rentReview, setRentReview] = useState<string | null>("");
    const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);
    const [returnedBy, setReturnedBy] = useState<string>("");
    const [returnLocation, setReturnLocation] = useState<string>("");

    const [kilometrageReturn, setKilometrageReturn] = useState<number | null>(
      null,
    );

    const returnCarHandler = async (rentContractID: string) => {
      try {
        setBtnIsLoading(true);

        await Axios.put("/rent/contracts", {
          galleryAfter,
          rentContractID,
          rentRate,
          rentReview,
          returnedBy,
          returnLocation,
          kilometrageReturn,
        });

        await mutate();
        setBtnIsLoading(false);
      } catch (e: any) {
        setBtnIsLoading(false);
        toast.error(
          t(
            e?.response?.data?.message["RESPONSE_CODE"] ||
              "SERVICE_UNAVAILABLE_MESSAGE",
          ),
        );
      }
    };

    if (
      Number(
        moment(props.endDate).diff(moment(props.startDate), "hours") / 24,
      ) < 0
    ) {
      return (
        <Result title={t("Ouups!")} subTitle={t("RENT HAS BEEN CANCELED")} />
      );
    }
    return (
      <div className="flex  gap-4 items-center w-full flex-wrap justify-evenly">
        {props.rentContractID && props.gallery.length === 0 && (
          <div className="flex flex-col gap-4 w-full items-center">
            {Boolean(moment(new Date()).diff(moment(props.startDate)) > 0) ? (
              <>
                <div className="w-full md:w-1/2">
                  <FormItem label={t("car_photos_after_rent")}>
                    <UploadWithDrag
                      uploadText={t("galleryAfter")}
                      hint={t("car_photos_after_rent")}
                      accept="image/*"
                      onRemove={(file) => {
                        if (file) {
                          const fileName = file.response.files.files[0].name;
                          const filesCleared = galleryAfter.filter(
                            (file: any) => file.includes(fileName),
                          );
                          setGalleryAfter(filesCleared);

                          return true;
                        }
                      }}
                      setFileValue={(file) => {
                        setGalleryAfter((prevState) => [
                          ...prevState,
                          `/media/${file.name}`,
                        ]);
                      }}
                      maxCount={5}
                    />
                  </FormItem>

                  <FormItem label={t("rent_rate")}>
                    <Rate
                      value={rentRate}
                      allowClear={false}
                      onChange={(value) => {
                        setRentRate(value);
                      }}
                    />
                  </FormItem>

                  <FormItem label={t("return_location")}>
                    <Input
                      className="w-full"
                      placeholder={t("return_location")}
                      controls={false}
                      value={returnLocation}
                      onChange={(e) => {
                        setReturnLocation(e.target.value);
                      }}
                    />
                  </FormItem>
                  <FormItem label={t("returned_by")}>
                    <Input
                      className="w-full"
                      placeholder={t("returned_by")}
                      controls={false}
                      value={returnedBy}
                      onChange={(e) => {
                        setReturnedBy(e.target.value);
                      }}
                    />
                  </FormItem>
                  <FormItem label={t("kilometrage_return")}>
                    <InputNumber
                      className="!w-full"
                      placeholder={t("kilometrage_return")}
                      controls={false}
                      value={kilometrageReturn}
                      onChange={(value) => {
                        setKilometrageReturn(value);
                      }}
                    />
                  </FormItem>
                  <TextArea
                    placeholder={t("return_comment_prompt")}
                    allowClear
                    style={{ height: 120, resize: "none" }}
                    onChange={(e) => {
                      console.log(e);
                      setRentReview(e.target.value);
                    }}
                  />
                </div>
                <div className="w-full flex justify-center">
                  <Button
                    type="primary"
                    className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
                    disabled={!galleryAfter.length}
                    onClick={() =>
                      returnCarHandler(props?.rentContractID || "")
                    }
                    loading={isBtnLoading}
                  >
                    {t("update_return").toUpperCase()}
                  </Button>
                </div>
              </>
            ) : (
              <Result title={t("CANT_END_RENT_BEFORE_START")} />
            )}
          </div>
        )}

        {props?.gallery.length ? (
          <Image.PreviewGroup>
            {props?.gallery?.map((picture) => {
              return (
                <Image
                  height={150}
                  key={picture}
                  crossOrigin="anonymous"
                  src={fileUrlHandler(picture.replace("/media/", ""))}
                />
              );
            })}
          </Image.PreviewGroup>
        ) : (
          <>
            <Divider /> <Empty />
          </>
        )}
      </div>
    );
  };

  const expandedRowRender = (record: any) => {
    return (
      <div
        className="w-full overflow-auto flex flex-col p-4"
        key={record.rentContractID}
      >
        <Tabs
          tabBarGutter={16}
          defaultActiveKey="1"
          items={[
            {
              label: t("selected_car"),
              key: "1",
              children: (
                <SelectedCarDescription selectedCar={record.rentedCar} />
              ),
            },
            {
              label: t("rent_divers"),
              key: "2",
              children: <ExpandedRowDriver drivers={record.ContractDrivers} />,
            },

            {
              label: t("rent_contracts"),
              key: "3",
              children: <RentPrintableContract contract={record} />,
            },
            {
              label: t("caution"),
              key: "4",
              children: <RentCautionContent contract={record} />,
            },
            {
              label: t("galleryBefore"),
              key: "5",
              children: JSON.parse(record?.galleryBefore).length ? (
                <ExpandedRowCarGallery
                  gallery={JSON.parse(record?.galleryBefore)}
                />
              ) : (
                <Empty />
              ),
            },
            ...(record.isCanceled
              ? []
              : [
                  {
                    label: t("galleryAfter"),
                    key: "6",
                    children: (
                      <ExpandedRowCarGallery
                        gallery={JSON.parse(record.galleryAfter)}
                        endDate={record.endDate}
                        startDate={record.startDate}
                        rentContractID={record.rentContractID}
                      />
                    ),
                  },
                ]),
          ]}
        />
      </div>
    );
  };
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const selectedContractToDisplay = useMemo(() => {
    const filtredContracts = contracts
      ? contracts.filter(
          (contract) => contract.rentContractID === selectedContract,
        )
      : [];

    return filtredContracts.length ? filtredContracts[0] : null;
  }, [selectedContract, contracts]);
  return (
    <div className="w-full max-w-full overflow-hidden p-10">
      {selectedContract ? (
        <RentDisplayDrawer
          width={isSmallDevice ? "100%" : "55%"}
          contract={selectedContractToDisplay}
          open={Boolean(selectedContract)}
          onClose={() => {
            setSelectedContract(null);
          }}
        />
      ) : null}
      <div className="flex gap-4 mb-4 justify-between flex-col md:flex-row min-w-fit">
        <div className="flex gap-4">
          <Search
            placeholder={t("search")}
            allowClear
            disabled={isLoading}
            onSearch={(value) => {
              setSearch(value);
            }}
            style={{ width: 304 }}
          />
          <Tooltip title={t("plate_search_info")}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <Radio.Group
          disabled={isLoading}
          value={searchStatus}
          onChange={(e) => setSearchStatus(e.target.value)}
        >
          <Radio.Button value="ALL">{t("ALL")}</Radio.Button>
          <Radio.Button value="PLANED">{t("PLANED")}</Radio.Button>
          <Radio.Button value="ON_GOING">{t("ON_GOING")}</Radio.Button>
          <Radio.Button value="FINISHED">{t("FINISHED")}</Radio.Button>
        </Radio.Group>
      </div>

      <Table
        rowKey="rentContractID"
        size="small"
        loading={isLoading}
        dataSource={dataToDisplay}
        columns={columns}
        expandable={{
          expandedRowRender,
        }}
        scroll={{ x: true }}
        rootClassName="h-2/3 min-h-2/3 "
        bordered={false}
      />
    </div>
  );
}
