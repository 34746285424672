import axios from "axios";
import { store } from "../Store/store";

const REACT_APP_API_ENDPOINT_BASE_URL =
  process.env.REACT_APP_API_ENDPOINT_BASE_URL || "http://localhost:3003";
const REACT_APP_API_ENDPOINT_PREFIX =
  process.env.REACT_APP_API_ENDPOINT_PREFIX || "/api/dev";

const getToken = () => store.getState().authentication.token;

export const headersAuth = {
  Authorization: getToken() || window.localStorage.getItem("token") || "",
};

export const baseURL =
  REACT_APP_API_ENDPOINT_BASE_URL + REACT_APP_API_ENDPOINT_PREFIX;

const axiosConfig = {
  mode: "cors",
  baseURL,
  headers: {
    ...headersAuth,
    "Content-type": "application/json",
    Accept: "application/json",
  },
};

const configuredAxios = axios.create(axiosConfig);

const getUserIP = async () => {
  try {
    const response = await axios.get("https://api64.ipify.org?format=json");
    return response.data.ip;
  } catch (error) {
    return "";
  }
};

configuredAxios.interceptors.request.use(async (config) => {
  config.headers["Authorization"] = headersAuth.Authorization;

  if (config.url === "/authn/check" || config.url === "/authn/login") {
    const userIP = await getUserIP();
    if (userIP) {
      config.headers["X-User-IP"] = userIP;
    }
  }

  config.headers.Authorization = getToken() || "";
  return config;
});

export const Axios = configuredAxios;
