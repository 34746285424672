import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { baseURL } from "../../../Config";
import { useState } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { Download, FileBadge } from "lucide-react";
import { useMediaQuery } from "@mui/material";
import { formatBytes } from "../../../Utils";
import { saveAs } from "file-saver";

export const PdfViewer = ({
  pdfUrl,
  fileName,
  size,
  title,
}: {
  pdfUrl: string;
  fileName?: string;
  size?: number | string;
  title?: string;
}) => {
  const WORKER_URL = `${baseURL}/workers/pdf.worker.min.js`;
  console.log(`${baseURL}/workers/pdf.worker.min.js`);
  const { t } = useTranslation();
  const [isModalOpen, serIsModalOpen] = useState(false);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const toggleModal = () => {
    serIsModalOpen(!isModalOpen);
  };

  const sourceFileName = pdfUrl.split("/").reverse()[0];

  const saveFile = () => {
    saveAs(pdfUrl, fileName);
  };

  return (
    <>
      <div className=" group bg-white text-gray-800 border border-gray-300 relative flex items-center rounded overflow-hidden flex-1 box-border hover:bg-gray-50">
        <div className="absolute top-0 left-0 h-full w-0.5 bg-transparent group-hover:w-1 group-hover:bg-[#c0392b] transition-all"></div>

        <div className="min-w-16 flex-1 aspect-square border-r border-gray-300 bg-gray-50 h-full flex items-center justify-center rounded-sm">
          <FileBadge color="#747d8c" />
        </div>

        <div className="flex items-center justify-between px-6 lg:py-4 w-full">
          <div
            className="flex items-center space-x-3 flex-1 cursor-pointer h-full"
            onClick={toggleModal}
          >
            <div className="space-y-1">
              {title ? (
                <h2 className="text-sm font-bold leading-none">
                  {title.toUpperCase()}
                </h2>
              ) : null}
              <p className="text-xs text-gray-500 font-light">
                {fileName || sourceFileName}
              </p>
            </div>
          </div>

          <div className="flex gap-4 items-center">
            <span className="text-xs text-gray-500 font-light">
              {formatBytes(size, 3)}
            </span>

            <Download
              size={16}
              className="cursor-pointer text-[#00a8ff] hover:text-[#0097e6]"
              onClick={saveFile}
            />
          </div>
        </div>
      </div>

      <Modal
        style={{ top: 20 }}
        width={isSmallDevice ? "100% " : "60%"}
        title={t("pdf_viewer")}
        destroyOnClose={true}
        open={isModalOpen}
        onClose={toggleModal}
        onCancel={toggleModal}
        footer={null}
      >
        <Worker workerUrl={WORKER_URL}>
          <div style={{ height: "650px" }}>
            <Viewer fileUrl={pdfUrl} />
          </div>
        </Worker>
      </Modal>
    </>
  );
};
