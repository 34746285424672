import React, { useMemo, useRef, useState } from "react";
import { Modal, Table, Tooltip } from "antd";
import { CompanyListingTableColumns } from "./CompanyInvoicesColumns";
import { useTranslation } from "react-i18next";
import Search from "antd/es/input/Search";
import { useCompanyInvoicesData } from "../../../../../Hooks/useCompanyInvoicesData";
import { CompanyInvoicePrintable } from "../../../../UI/Printables/CompanyInvoicePrintable";
import { useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import { Axios } from "../../../../../Config";
import { EyeFilled } from "@ant-design/icons";
import { SquarePen } from "lucide-react";

export function CompanyInvoicesListing() {
  const { t } = useTranslation();

  const { isLoading, invoices } = useCompanyInvoicesData();

  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<null | string>(null);

  const chooseInvoiceHandler = (invoiceID: string | null) => {
    setOpen(true);
    setSelectedInvoice(invoiceID);
  };

  const dataToDisplay = useMemo(() => {
    return search
      ? invoices.filter((invoice) => {
          return Boolean(
            invoice.invoiceID?.toLowerCase().includes(search.toLowerCase()) ||
              invoice.client.fullName
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              invoice.client.denomination
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              invoice.client.vatNumber
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              invoice.client.idNumber
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              invoice.reference?.toLowerCase().includes(search.toLowerCase()),
          );
        })
      : invoices;
  }, [invoices, search]);

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const asyncToast = useRef<any>();
  const invoiceToBillHandler = async (invoiceID: string) => {
    try {
      asyncToast.current = toast.loading(t("CREATE_INVOICE_ONGOING_MESSAGE"));
      const data = {
        invoiceID,
      };

      const savedInvoice = await Axios.post(
        "/companyBills/from-invoice",
        data,
      ).then((res) => res.data.message);
      console.log(savedInvoice);

      toast.update(asyncToast.current, {
        render: `${t("CREATE_INVOICE_SUCCESS_MESSAGE")} : ${savedInvoice.reference}`,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (e: any) {
      toast.update(asyncToast.current, {
        render: t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  const columns = CompanyListingTableColumns(t, chooseInvoiceHandler);
  columns.push({
    title: t("actions"),
    dataIndex: "invoiceID",
    key: "actions",
    render: (value: any) => (
      <div className="flex justify-center items-center gap-3">
        <EyeFilled
          className="cursor-pointer "
          onClick={() => {
            chooseInvoiceHandler(value);
          }}
        />
        <Tooltip title={t("create_bill_from_invoice")}>
          <SquarePen
            size={15}
            className="cursor-pointer "
            onClick={() => {
              invoiceToBillHandler(value);
            }}
          />
        </Tooltip>
      </div>
    ),
  });

  return (
    <div className="w-full overflow-scroll p-10 !relative">
      {open && (
        <Modal
          width={isSmallDevice ? "100% " : "60%"}
          open={open}
          footer={null}
          onCancel={() => {
            setOpen(false);
            setSelectedInvoice(null);
          }}
          onClose={() => {
            setOpen(false);
            setSelectedInvoice(null);
          }}
        >
          <CompanyInvoicePrintable
            CompanyInvoice={
              invoices.filter(
                (invoice) => invoice.invoiceID === selectedInvoice,
              )[0]
            }
          />
        </Modal>
      )}
      <div className="flex gap-4 mb-4">
        <Search
          placeholder={t("search")}
          allowClear
          onSearch={(value) => {
            setSearch(value);
          }}
          style={{ width: 304 }}
        />
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={dataToDisplay}
        rowKey="invoiceID"
        size="small"
        tableLayout={"auto"}
        sticky={false}
        rootClassName="h-2/3 min-h-2/3 max-w-full"
        bordered={false}
      />
    </div>
  );
}
