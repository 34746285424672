import React, { memo, useState } from "react";
import { ClipboardCheck, Copy } from "lucide-react";

function CopyToClipboard({
  input,
  showInput = true,
  showIcon = true,
}: {
  input: string;
  showInput?: boolean;
  showIcon?: boolean;
}) {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(input);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1500); // Reset the copied status after 1.5 seconds
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  };

  return (
    <div
      onClick={handleCopy}
      className="cursor-pointer flex items-center gap-2"
    >
      {showInput && input}
      {showIcon && (
        <span
          style={{ color: isCopied ? "#1abc9c" : "#34495e" }}
          className="ml-1"
        >
          {isCopied ? <ClipboardCheck size={16} /> : <Copy size={16} />}
        </span>
      )}
    </div>
  );
}

export default memo(CopyToClipboard);
