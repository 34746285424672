import { Button, Form, Input, Modal, Result, Select, Switch } from "antd";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useCompaniesData, useEnumsData } from "../../Hooks";
import { Formik } from "formik";
import FormItem from "antd/es/form/FormItem";
import { enumsToSelectOptions } from "../../Utils";
import { Axios } from "../../Config";
import { toast } from "react-toastify";
import { Users } from "../../Types";
import { useAgencesData } from "../../Hooks/useAgencesData";

interface PropsT {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  mutate: any;
}

interface InitialValuesT {
  name: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  accountType?: "PLATFORM" | "ENTREPRISE" | "CONSULTANT" | "EXTERNAL" | null;
  companyID?: string | null;
  agenceID?: string | null;
  isConsultantAdmin?: boolean;
}

function AddUserModal({ isOpen = false, setIsOpen, mutate }: PropsT) {
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.authentication.user) as Users;
  const { enums, isLoading: isEnumsLoading } = useEnumsData();
  const { agences } = useAgencesData(user.accountType === "ENTREPRISE");
  const { companies } = useCompaniesData(
    10000,
    user.accountType === "PLATFORM",
  );

  const initialValues: InitialValuesT = {
    name: null,
    lastName: null,
    email: null,
    phone: null,
    accountType: user?.accountType !== "PLATFORM" ? user?.accountType : null,
    agenceID:
      user.accountType !== "PLATFORM"
        ? user.accountType === "ENTREPRISE"
          ? user.AgencesUsers.length
            ? user.AgencesUsers[0].agenceID.agenceID
            : null
          : null
        : null,
    companyID:
      user.accountType !== "PLATFORM"
        ? user.accountType === "ENTREPRISE"
          ? user.CompaniesUsers[0].CompanyID
          : null
        : null,
  };

  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const companiesToOptions = companies
    ? companies?.map((company) => {
        return {
          value: company.companyID,
          label: company?.rsLatin || company?.rsArabic || company?.VAT,
        };
      })
    : [];

  const agencesToOptions = agences
    ? agences?.map((agence) => {
        return {
          value: agence.agenceID,
          label: agence?.agenceName || agence?.agenceCode,
        };
      })
    : [];

  const onSubmitHandler = async (values: InitialValuesT) => {
    try {
      setIsBtnLoading(true);
      await Axios.post("/users", { ...values });
      mutate();
      setIsSuccess(true);
      setIsBtnLoading(false);
    } catch (e: any) {
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
      setIsBtnLoading(false);
    }
  };

  return isOpen ? (
    <Modal
      open={isOpen}
      title={t("create_user")}
      footer={null}
      onCancel={() => {
        setIsOpen(false);
      }}
    >
      {!isSuccess ? (
        <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
          {({ values, submitForm, setFieldValue }) => (
            <Form
              labelCol={{ span: 8 }}
              style={{ padding: "14px" }}
              layout="horizontal"
              disabled={false}
              initialValues={values}
              onValuesChange={(fieldKeyValue) => {
                const fieldKey = Object.keys(fieldKeyValue)[0];
                const fieldValue = fieldKeyValue[fieldKey];
                setFieldValue(fieldKey, fieldValue);
              }}
            >
              <FormItem label={t("name")} name="name">
                <Input value={values.name || ""} placeholder={t("name")} />
              </FormItem>
              <FormItem label={t("lastName")} name="lastName">
                <Input
                  placeholder={t("lastName")}
                  value={values.lastName || ""}
                />
              </FormItem>
              <FormItem label={t("auth_email")} name="email">
                <Input placeholder={t("email")} value={values.email || ""} />
              </FormItem>
              <FormItem label={t("phone")} name="phone">
                <Input placeholder={t("phone")} value={values.phone || ""} />
              </FormItem>

              <FormItem label={t("accountType")} name="accountType">
                <Select
                  loading={isEnumsLoading}
                  showSearch
                  placeholder={t("accountType")}
                  optionFilterProp="children"
                  value={values.accountType || ""}
                  style={{ width: "100%" }}
                  options={enumsToSelectOptions(enums?.["AccountTypes"])}
                  disabled={user.accountType !== "PLATFORM"}
                />
              </FormItem>
              {values.accountType === "ENTREPRISE" && (
                <>
                  <FormItem label={t("companyID")} name="companyID">
                    <Select
                      showSearch
                      placeholder={t("companyID")}
                      optionFilterProp="children"
                      value={values.companyID || ""}
                      style={{ width: "100%" }}
                      options={
                        user.accountType !== "PLATFORM" &&
                        user.accountType === "ENTREPRISE"
                          ? [
                              {
                                value: user.CompaniesUsers[0].CompanyID,
                                label:
                                  user?.CompaniesUsers[0].companyID.rsLatin ||
                                  user?.CompaniesUsers[0].companyID.rsArabic ||
                                  user?.CompaniesUsers[0].companyID.VAT,
                              },
                            ]
                          : companiesToOptions
                      }
                      disabled={user.accountType !== "PLATFORM"}
                    />
                  </FormItem>
                  {user.accountType === "ENTREPRISE" ? (
                    <FormItem label={t("agenceName")} name="agenceID">
                      <Select
                        showSearch
                        placeholder={t("agenceName")}
                        optionFilterProp="children"
                        value={values.agenceID || ""}
                        style={{ width: "100%" }}
                        options={
                          user.accountType !== "PLATFORM" &&
                          user.accountType === "ENTREPRISE"
                            ? user.AgencesUsers.length
                              ? [
                                  {
                                    value:
                                      user.AgencesUsers?.[0]?.agenceID.agenceID,
                                    label:
                                      user?.AgencesUsers?.[0]?.agenceID
                                        .agenceName ||
                                      user?.AgencesUsers?.[0]?.agenceID
                                        .agenceCode,
                                  },
                                ]
                              : agencesToOptions
                            : agencesToOptions
                        }
                        disabled={Boolean(user?.AgencesUsers.length)}
                      />
                    </FormItem>
                  ) : null}
                </>
              )}
              {user.accountType === "CONSULTANT" && user.isConsultantAdmin && (
                <FormItem name="isConsultantAdmin" label={t("isAdmin")}>
                  <Switch
                    checkedChildren={t("yes")}
                    unCheckedChildren={t("no")}
                    value={values.isConsultantAdmin}
                    onChange={(checked) => {
                      setFieldValue("isConsultantAdmin", checked);
                    }}
                  />
                </FormItem>
              )}

              <div className="w-full flex justify-center">
                <Button
                  type="primary"
                  className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
                  onClick={submitForm}
                  loading={isBtnLoading}
                  disabled={
                    !values.name ||
                    !values.lastName ||
                    !values.email ||
                    !values.phone ||
                    (values.accountType === "ENTREPRISE" && !values.companyID)
                  }
                >
                  {t("create_user").toUpperCase()}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <Result
          status="success"
          title={t("USER_ADDED_MESSAGE")}
          extra={[
            <Button
              type="primary"
              className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
              onClick={() => setIsSuccess(false)}
            >
              {t("back")}
            </Button>,
          ]}
        />
      )}
    </Modal>
  ) : null;
}

export default AddUserModal;
