import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import {useState} from "react";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import {Button, Input} from "antd";
import {Link} from "react-router-dom";
import {loginValidationSchema} from "../../../Validation";
import {LoginHandlerPropsT} from "../../../Types";
import {LoginHandler} from "../../../Services";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {userLoginAction} from "../../../Store/actions";
import {useNavigate} from "react-router";

export const LoginForm = () => {
  const { t } = useTranslation();
  const initialFormState = { email: "", password: "" };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmitHandler = async (values: LoginHandlerPropsT) => {
    try {
      setIsLoading(true);
      const userResponse = await LoginHandler(values);
      dispatch(userLoginAction(userResponse.data?.user));
      navigate("/");
      toast.dismiss();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(
        t(
          error?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };
  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={loginValidationSchema}
      isInitialValid={false}
      onSubmit={onSubmitHandler}
    >
      {({ errors, isValid, submitForm, handleChange, handleBlur, values }) => {
        return (
          <>
            <Input
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder={t("auth_email")}
              size="large"
              status={errors.email ? "error" : ""}
            />
            <h3 className="mt-2 mb-5 mx-2 w-full text-sm  text-[#ff4d4f]">
              {errors.email && t(errors.email)}
            </h3>
            <Input.Password
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              size="large"
              placeholder={t("auth_password")}
              status={errors.password ? "error" : ""}
              iconRender={(visible) =>
                visible ? (
                  <EyeTwoTone rev={undefined} />
                ) : (
                  <EyeInvisibleOutlined rev={undefined} />
                )
              }
            />
            <h3 className="mt-2 mb-5 mx-2 w-full text-sm text-[#ff4d4f]">
              {errors.password && t(errors.password)}
            </h3>
            <div className="w-full flex justify-end  pb-7">
              <h3 className=" text-xs  ml-3 cursor-pointer   ">
                <Link to="/forgot_password">{t("auth_forgot_password")}</Link>
              </h3>
            </div>
            <div className="w-full flex justify-between flex-col pt-7 mb-auto">
              <Button
                type="primary"
                className="mb-5 !h-10 !bg-[#f46438] !hover:bg-[#E44C4D] !text-white"
                onClick={submitForm}
                disabled={!isValid || isLoading}
                loading={isLoading}
              >
                {t("auth_login_btn")}
              </Button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};
