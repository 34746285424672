import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { RentInitialValues } from "./index";
import { Button, InputNumber, Modal, Spin } from "antd";
import { Axios } from "../../../../../Config";
import { t } from "i18next";
import FormItem from "antd/es/form/FormItem";
import { Tally5 } from "lucide-react";

function ConfirmCreatContractModal({
  isBtnLoading,
  handleCreateContract,
}: {
  isBtnLoading: boolean;
  handleCreateContract: any;
}) {
  const { values, setFieldValue } = useFormikContext<RentInitialValues>();
  const { contract } = values;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [newContractRef, setNewContractRef] = useState<number | null>(null);

  useEffect(() => {
    Axios.get("/rent/contracts/new-ref")
      .then((res) => {
        setFieldValue(
          "contract.contractReferance",
          res.data.message.contractReferance,
        ).then(() => {
          setNewContractRef(res.data.message.contractReferance);
        });
      })
      .catch(() => {
        setNewContractRef(0);
      });
  }, []);
  return (
    <>
      <Button
        type="primary"
        className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white"
        loading={isBtnLoading}
        disabled={Object.entries(contract)
          .filter(([key]) => {
            return !Boolean(
              key === "galleryBefore" ||
                key === "galleryAfter" ||
                key === "isRentWithDriver" ||
                key === "isdayPriceChanged" ||
                key === "extraPrice" ||
                key === "extraDescription" ||
                key === "calculated_days" ||
                key === "FuelCount" ||
                key === "dayPrice",
            );
          })
          .some(([_, value]) => !value)}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        {t("create_contract").toUpperCase()}
      </Button>
      <Modal
        open={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        {typeof newContractRef !== "number" ? (
          <Spin />
        ) : (
          <div className="flex flex-col gap-2 p-6">
            <FormItem label={t("DEFAULT_NEW_CONTRACT_REFERENCE")}>
              <InputNumber
                className="w-full"
                disabled
                placeholder={t("default_new_contract_referance")}
                controls={false}
                defaultValue={newContractRef}
                addonAfter={
                  <div>
                    <Tally5 />
                  </div>
                }
                onChange={(value) =>
                  setFieldValue("contract.extraPrice", value)
                }
              />
            </FormItem>
            <FormItem label={t("NEW_CONTRACT_REFERENCE_EDITABLE")}>
              <InputNumber
                className="w-full"
                placeholder={t("NEW_CONTRACT_REFERENCE_EDITABLE")}
                controls={false}
                defaultValue={contract?.contractReferance || 0}
                addonAfter={
                  <div>
                    <Tally5 />
                  </div>
                }
                onChange={(value) =>
                  setFieldValue("contract.contractReferance", value)
                }
              />
            </FormItem>
            <Button
              type="primary"
              className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white"
              loading={isBtnLoading}
              disabled={Object.entries(contract)
                .filter(([key]) => {
                  return !Boolean(
                    key === "galleryBefore" ||
                      key === "galleryAfter" ||
                      key === "isRentWithDriver" ||
                      key === "isdayPriceChanged" ||
                      key === "extraPrice" ||
                      key === "extraDescription" ||
                      key === "calculated_days" ||
                      key === "FuelCount" ||
                      key === "dayPrice",
                  );
                })
                .some(([_, value]) => !value)}
              onClick={() => {
                setIsModalOpen(false);
                handleCreateContract();
              }}
            >
              {t("create_contract").toUpperCase()}
            </Button>
          </div>
        )}
      </Modal>
    </>
  );
}

export default ConfirmCreatContractModal;
