import React from "react";
import { Clients } from "../../../../../Types";
import { Card, Divider, Drawer } from "antd";
import { DrawerProps } from "antd/es/drawer";
import { useTranslation } from "react-i18next";
import { Building2, SquareX, User } from "lucide-react";
import ClientInNumbers from "./ClientInNumbers";
import ClientRentContracts from "./ClientRentContracts";
import ClientFiles from "./ClientFiles";
import ClientGeneralInfos from "./ClientGeneralInfos";
import ClientInvoices from "./ClientInvoices";
import ClientBills from "./ClientBills";
import moment from "moment/moment";
import ClientComments from "./ClientComments";

interface ClientInfosDrawerProps extends DrawerProps {
  client: Clients;
}

const ClientInfosDrawer: React.FC<ClientInfosDrawerProps> = ({
  client,
  ...props
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const Title = () => {
    return (
      <div className="w-full flex   !text-[16px]  items-center gap-3 ">
        {client?.clientType === "PP" ? (
          <User size={16} strokeWidth={2.25} />
        ) : (
          <Building2 size={16} strokeWidth={2.25} />
        )}
        <span>
          {t("client_sheet").toUpperCase()} :{" "}
          {client?.clientType === "PP"
            ? t("PP").toUpperCase()
            : t("PM").toUpperCase()}
        </span>
      </div>
    );
  };

  return (
    <Drawer {...props} title={<Title />} closeIcon={<SquareX size={20} />}>
      <div
        className="w-full flex flex-col   min-h-fit"
        dir={language === "ar" ? "rtl" : "ltr"}
      >
        <ClientGeneralInfos client={client} />
        <Divider />
        <ClientInNumbers client={client} />
        <Divider />
        <ClientFiles client={client} />
        <Divider />
        <ClientBills client={client} />
        <Divider />
        <ClientInvoices client={client} />
        <Divider />
        <ClientRentContracts client={client} />
        <Divider />
        <ClientComments client={client} />
        <Divider />
        <Card className="flex flex-col">
          <p>
            {t("created_by")} : {client?.createdBy.name}{" "}
            {client?.createdBy.lastName}
          </p>
          <p>
            {t("date_of_creations")} :{" "}
            {moment(client?.createdAt).format("DD/MM/YYYY HH:mm")}
          </p>
        </Card>
      </div>
    </Drawer>
  );
};

export default ClientInfosDrawer;
