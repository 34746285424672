import { AuthLayout } from "../../Layouts";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { JwtREGEX } from "../../Utils/userUtils";
import { useNavigate } from "react-router";
import { Result, Spin } from "antd";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL } from "../../Config";

export const UserVerificationPage = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigation = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(true);

  useEffect(() => {
    const validateUser = async () => {
      try {
        setIsLoading(true);
        const userResponse = await axios
          .post(
            "/users/verification",
            { token },
            {
              baseURL,
              headers: {
                "Content-type": "application/json",
                Accept: "application/json",
              },
            },
          )
          .then((res) => res.data.message);
        setIsLoading(false);
        toast.success(t(userResponse["RESPONSE_CODE"]));
      } catch (error: any) {
        setIsLoading(false);

        toast.error(
          t(
            error?.response?.data?.message["RESPONSE_CODE"] ||
              "SERVICE_UNAVAILABLE_MESSAGE",
          ),
        );
      }
    };

    const regEx = new RegExp(JwtREGEX);
    if (token && !regEx.test(token)) {
      navigation("/");
    } else {
      validateUser().then(() => navigation("/"));
    }
  }, [token]);

  return (
    <AuthLayout>
      <Result icon={<Spin />} title={t("account_being_validated")} />
    </AuthLayout>
  );
};
