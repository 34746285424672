import { SVGProps } from "react";

function CitadineSvg(props: SVGProps<any>) {
  return (
    <svg
      {...props}
      viewBox="0 0 1000 227.963"
      enableBackground="new 0 0 1000 227.963"
    >
      <g>
        <path
          fill="#BABABA"
          d="M385.578,182.896c0,1.903,0.174,3.808,0.396,5.661c2.794,22.226,21.731,39.406,44.699,39.406   c23.608,0,42.94-18.146,44.895-41.234c0.099-1.263,0.196-2.549,0.196-3.857c0-24.896-20.172-45.093-45.091-45.093   C405.776,137.802,385.578,157.976,385.578,182.896L385.578,182.896z M458.657,179.064h-12.954c-0.371-1.436-0.915-2.744-1.633-3.98   l9.196-9.196C456.111,169.67,457.988,174.145,458.657,179.064L458.657,179.064z M453.242,200.028l-9.246-9.246   c0.718-1.236,1.311-2.521,1.682-3.906h12.955C457.938,191.746,456.111,196.271,453.242,200.028L453.242,200.028z M434.577,197.878   c1.385-0.347,2.694-0.891,3.906-1.583l9.173,9.172c-3.76,2.845-8.209,4.746-13.079,5.414V197.878z M434.577,154.91   c4.92,0.692,9.395,2.597,13.178,5.464l-9.198,9.172c-1.233-0.717-2.546-1.285-3.979-1.655L434.577,154.91L434.577,154.91z    M408.101,165.887l9.195,9.196c-0.716,1.236-1.284,2.546-1.631,3.98h-12.979C403.354,174.145,405.232,169.67,408.101,165.887   L408.101,165.887z M426.767,167.89c-1.409,0.37-2.744,0.938-3.979,1.655l-9.173-9.172c3.757-2.867,8.258-4.771,13.153-5.464V167.89   L426.767,167.89z M413.688,205.466l9.173-9.172c1.186,0.717,2.521,1.236,3.905,1.583v13.003   C421.896,210.213,417.445,208.31,413.688,205.466L413.688,205.466z M402.637,186.876h13.053c0.371,1.385,0.915,2.694,1.631,3.906   l-9.195,9.221C405.257,196.22,403.328,191.746,402.637,186.876L402.637,186.876z"
        />
        <g>
          <path
            d="M51.783,182.896c0,1.903,0.172,3.808,0.42,5.661c2.768,22.226,21.707,39.406,44.673,39.406    c23.61,0,42.94-18.146,44.894-41.234c0.099-1.263,0.199-2.549,0.199-3.857c0-24.896-20.174-45.093-45.093-45.093    C71.981,137.802,51.783,157.976,51.783,182.896L51.783,182.896z M124.86,179.064h-12.954c-0.369-1.436-0.915-2.744-1.631-3.98    l9.197-9.196C122.314,169.67,124.193,174.145,124.86,179.064L124.86,179.064z M119.473,200.028l-9.247-9.246    c0.716-1.236,1.284-2.521,1.656-3.906h12.954C124.169,191.746,122.314,196.271,119.473,200.028L119.473,200.028z M100.782,197.878    c1.383-0.347,2.718-0.891,3.906-1.583l9.171,9.172c-3.759,2.845-8.182,4.746-13.077,5.414V197.878z M100.782,154.91    c4.92,0.692,9.395,2.597,13.176,5.464l-9.196,9.172c-1.236-0.717-2.548-1.285-3.979-1.655L100.782,154.91L100.782,154.91z     M74.304,165.887l9.197,9.196c-0.716,1.236-1.286,2.546-1.631,3.98H68.916C69.559,174.145,71.46,169.67,74.304,165.887    L74.304,165.887z M92.97,167.89c-1.41,0.37-2.745,0.938-3.98,1.655l-9.171-9.172c3.781-2.867,8.256-4.771,13.176-5.464v12.979    L92.97,167.89L92.97,167.89z M79.893,205.466l9.171-9.172c1.21,0.717,2.521,1.236,3.906,1.583v13.003    C88.099,210.213,83.648,208.31,79.893,205.466L79.893,205.466z M68.865,186.876h13.054c0.37,1.385,0.915,2.694,1.632,3.906    l-9.223,9.221C71.484,196.22,69.533,191.746,68.865,186.876L68.865,186.876z"
            fill="#BABABA"
          />
          <path
            d="M-0.775,159.137l5.116,27.738c1.163,6.28,6.649,10.854,13.028,10.854h25.91    c-0.693-2.547-1.287-5.143-1.609-7.813c-0.344-2.546-0.492-4.845-0.492-7.021c0-30.729,24.993-55.724,55.698-55.724    c30.729,0,55.722,24.995,55.722,55.724c0,1.581-0.098,3.164-0.246,4.746c-0.297,3.461-0.915,6.823-1.83,10.086h226.552    c-0.691-2.546-1.284-5.142-1.606-7.813c-0.321-2.546-0.494-4.845-0.494-7.021c0-30.703,24.995-55.695,55.7-55.695    c30.721,0,55.723,24.992,55.723,55.695c0,1.607-0.099,3.165-0.248,4.747c-0.295,3.461-0.913,6.849-1.828,10.086h6.576    c0,0,11.198-35.993,7.269-52.359c-3.092-12.732-10.951-17.921-10.951-17.921s0.74-18.714-9.321-38.05    c-2.151-4.102-18.764-26.729-43.782-49.271c-11.425-10.307-20.422-13.521-35.825-13.549    c-43.585-0.117-143.806-0.345-167.491,0.053c-15.748,0.247-24.796,2.695-38.468,10.854c-1.358,0.815-2.076,2.374-1.681,3.905    c0.396,1.505,1.781,2.568,3.362,2.568h0.891L150.67,87.246c0,0-17.479,0.098-23.286,0.321    c-28.951,1.208-80.99,5.488-112.562,29.543c-4.251,3.242-7.021,8.056-7.712,13.349c-0.865,6.897-1.014,14.537-1.014,14.537    C0.953,147.692-1.841,153.427-0.775,159.137L-0.775,159.137z M362.415,43.955h45.686c4.969,0,9.766,1.66,13.672,4.697    c10.702,8.359,19.085,17.556,25.313,25.665c1.286,1.656,1.51,3.858,0.595,5.731c-0.915,1.854-2.794,3.068-4.87,3.097    l-80.396,1.132V43.955z M338.062,43.955h3.116v40.618l-3.116,0.053V43.955z M173.761,86.922l43.883-42.967h99.184v40.943    l-105.167,1.479c0.05-0.519,0.123-1.013,0.123-1.558c0-7.042-5.018-12.75-11.224-12.75s-11.249,5.708-11.249,12.75    c0,0.646,0.073,1.267,0.174,1.879L173.761,86.922z"
            fill="#BABABA"
          />
        </g>
      </g>
    </svg>
  );
}

export default CitadineSvg;
