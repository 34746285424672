import React, { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { Button, Col, Form, InputNumber, Result, Row, Select } from "antd";
import FormItem from "antd/es/form/FormItem";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import { Axios } from "../../../../../Config";

interface InitialValuesT {
  amount: number | null;
  type: "EXPENSE" | "INCOME";
  description: string | null;
}

export function AddCashTransactionContent({
  mutateStat,
  mutate,
}: {
  mutateStat?: any;
  mutate?: any;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);

  const initialValues: InitialValuesT = {
    amount: null,
    type: "INCOME",
    description: null,
  };

  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      setBtnIsLoading(true);
      const data = { ...values };
      await Axios.post("/cash/transaction", data);
      formikHelpers.setValues(initialValues);
      setIsSuccess(true);
      if (mutateStat) {
        mutateStat();
      }
      if (mutate) {
        mutate();
      }
      setBtnIsLoading(false);
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  return (
    <div className="w-full overflow-scroll p-10">
      {!isSuccess ? (
        <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
          {({ values, submitForm, setFieldValue }) => {
            return (
              <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                disabled={false}
                initialValues={values}
                onValuesChange={(fieldKeyValue) => {
                  const fieldKey = Object.keys(fieldKeyValue)[0];
                  const fieldValue = fieldKeyValue[fieldKey];
                  setFieldValue(fieldKey, fieldValue);
                }}
              >
                <Row gutter={2}>
                  <Col span={12} xs={24} sm={12}>
                    <FormItem name="type" label={t("type")}>
                      <Select
                        placeholder={t("type")}
                        options={[
                          { label: t("EXPENSE"), value: "EXPENSE" },
                          { label: t("INCOME"), value: "INCOME" },
                          { label: t("TRANSFER"), value: "TRANSFER" },
                        ]}
                      />
                    </FormItem>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <FormItem name="amount" label={t("amount")}>
                      <InputNumber
                        controls={false}
                        min={0}
                        style={{ width: "100%" }}
                        placeholder={t("amount")}
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={2}>
                  <Col span={24} xs={24} sm={24}>
                    <FormItem name="description" label={t("description")}>
                      <TextArea rows={4} placeholder={t("description")} />
                    </FormItem>
                  </Col>
                </Row>

                <div className="w-full flex justify-center">
                  <Button
                    type="primary"
                    className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
                    onClick={submitForm}
                    loading={isBtnLoading}
                  >
                    {t("add_transaction").toUpperCase()}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Result
          status="success"
          title={t("add_transaction_success")}
          extra={[
            <Button
              type="primary"
              className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
              onClick={() => setIsSuccess(false)}
            >
              {t("back")}
            </Button>,
          ]}
        />
      )}
    </div>
  );
}
