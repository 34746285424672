import React, { useMemo, useState } from "react";
import { Modal, Table } from "antd";
import { useTranslation } from "react-i18next";
import Search from "antd/es/input/Search";
import { usePlatformBillsData } from "../../../../../Hooks/usePlatformBillsData";
import { PlatformBillsColumns } from "./PlatformBillsColumns";
import { PlatformBillPrintable } from "../../../../UI/Printables/PlatformBillPrintable";
import { useMediaQuery } from "@mui/material";

export function PlatformBillsListing() {
  const { t } = useTranslation();

  const { isLoading, platformBills } = usePlatformBillsData();

  const [open, setOpen] = useState(false);
  const [selectedBill, setSelectedBill] = useState<null | string>(null);

  const [search, setSearch] = useState("");

  const dataToDisplay = useMemo(() => {
    return search
      ? platformBills.filter((bill) => {
          return Boolean(
            bill.platformBillID?.toLowerCase().includes(search.toLowerCase()) ||
              bill.company.rsLatin
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              bill.company.rsArabic
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              bill.company.VAT?.toLowerCase().includes(search.toLowerCase()),
            bill.company.companyID
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
              bill.reference?.toLowerCase().includes(search.toLowerCase()),
          );
        })
      : platformBills;
  }, [platformBills, search]);

  const chooseBillHandler = (invoiceID: string | null) => {
    setOpen(true);
    setSelectedBill(invoiceID);
  };

  const columns = PlatformBillsColumns(t, chooseBillHandler);

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <div className="w-full overflow-scroll p-10 !relative">
      {open && (
        <Modal
          width={isSmallDevice ? "100% " : "60%"}
          open={open}
          footer={null}
          onCancel={() => {
            setOpen(false);
            setSelectedBill(null);
          }}
          onClose={() => {
            setOpen(false);
            setSelectedBill(null);
          }}
        >
          <PlatformBillPrintable
            PlatformBill={
              platformBills.filter(
                (invoice) => invoice.platformBillID === selectedBill,
              )[0]
            }
          />
        </Modal>
      )}
      <div className="flex gap-4 mb-4">
        <Search
          placeholder={t("search")}
          allowClear
          onSearch={(value) => {
            setSearch(value);
          }}
          style={{ width: 304 }}
        />
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={dataToDisplay}
        rowKey="platformBillID"
        size="small"
        tableLayout={"auto"}
        sticky={false}
        rootClassName="h-2/3 min-h-2/3 max-w-full"
        bordered={false}
      />
    </div>
  );
}
