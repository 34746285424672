import React, { SVGProps } from "react";

function DinardIcon(props: SVGProps<any>) {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8804_83905)">
        <path d="M18.3745 9.70131C18.3745 9.70131 18.5001 9.58024 18.3745 9.70131L18.5001 8.12741C18.5001 8.12741 18.5001 8.00633 18.3745 8.00633H13.9795C13.854 7.64312 13.7284 7.27992 13.6028 6.91671C12.5983 4.1321 10.4636 1.83177 7.701 0.5H7.57543L7.19871 4.97959V5.10066C8.83113 5.58494 10.338 6.67456 11.3426 8.00633H3.8083C3.8083 8.00633 3.68273 8.00634 3.68273 8.12741V9.58024C3.68273 9.58024 3.68273 9.70131 3.8083 9.70131H12.3471C12.5983 10.1856 12.7238 10.6699 12.8494 11.1542H3.8083C3.8083 11.1542 3.68273 11.1542 3.68273 11.2752V12.7281C3.68273 12.7281 3.68273 12.8491 3.8083 12.8491H13.2261C13.3517 13.9388 13.4773 15.1495 13.4773 16.2391C13.4773 16.3602 13.3517 16.4812 13.2261 16.4812H3.93387C2.67816 16.6023 1.54803 15.8759 1.29689 14.6652H0.669033C0.292321 15.7548 0.166748 16.8444 0.166748 17.9341C0.166748 18.6605 0.669033 19.3869 1.29689 19.7501C2.05031 20.2344 2.92931 20.5976 3.8083 20.4765H14.8585V16.3602C14.8585 15.0284 14.8585 13.9388 14.733 12.7281H18.2489C18.2489 12.7281 18.3745 12.7281 18.3745 12.607V11.1542C18.3745 11.1542 18.3745 11.0331 18.2489 11.0331H14.6074C14.4818 10.5488 14.4818 10.0645 14.3563 9.58024H18.3745V9.70131Z" />
      </g>
      <defs>
        <clipPath id="clip0_8804_83905">
          <rect
            width="18.3333"
            height="20"
            fill="white"
            transform="translate(0.166748 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DinardIcon;
