import React, { Dispatch, SetStateAction } from "react";
import { Layout, Menu } from "antd";
import LocasafeLogo from "../../../Assets/SVGs/LocasafeLogo";
import SwiftLogoColored from "../../../Assets/SVGs/SwiftLogoColored";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getItem, MenuItem, sideBarMenu } from "./SideBarMenu";
import { LanguageToggle } from "../../UI";
import { userLogoutAction } from "../../../Store/actions";
import { useSWRConfig } from "swr";
import { RootReducer } from "../../../Store/reducers";
import { Users } from "../../../Types";
import { useMediaQuery } from "@mui/material";
import { Languages, LogOut } from "lucide-react";

const { Sider } = Layout;

export function SideBar({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPath = window.location.pathname;
  const { mutate } = useSWRConfig();

  const user = useSelector(
    (state: RootReducer) => state?.authentication?.user,
  ) as Users;

  const sideBarShowMenu = sideBarMenu(t, user) as MenuItem[];
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  return (
    <Layout
      className="border-r border-gray-200    "
      style={{
        maxHeight: "100vh",
        overflowX: "hidden",
        overflowY: "auto",
        minWidth: collapsed ? "" : "300px",
        maxWidth: isSmallDevice ? 0 : "",
        boxSizing: "border-box",
      }}
    >
      <Sider
        collapsible
        trigger={null}
        collapsed={collapsed}
        theme="light"
        width="300px"
        style={{
          position: "relative",
          maxHeight: "100vh",
          textTransform: "uppercase",
        }}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          if (broken) {
            setCollapsed(true);
          }
        }}
      >
        <div className="flex flex-col justify-between w-full h-full  ">
          <div
            className=" w-full sticky top-0 bg-white  mb-4 pt-2 z-30 cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            {collapsed ? (
              <SwiftLogoColored className="max-w-full   aspect-square px-5 pt-2 " />
            ) : (
              <LocasafeLogo className="max-w-full pt-3 h-20" />
            )}
          </div>
          <div className=" w-full h-full max-h-full overflow-auto  ">
            <Menu
              key="sideBarMenu"
              style={{ padding: "10px", fontSize: 12 }}
              theme="light"
              mode="inline"
              defaultSelectedKeys={[currentPath]}
              items={sideBarShowMenu}
              multiple={false}
              onClick={(e) => {
                navigate(e.key);
              }}
            />
          </div>
          <Menu
            style={{
              padding: "10px",
              position: "sticky",
              bottom: 0,
              left: 0,
              paddingBottom: "30px",
            }}
            theme="light"
            mode="inline"
            items={[
              getItem(
                <LanguageToggle className="w-full flex items-center justify-between" />,
                "LanguageToggle",
                <Languages size={16} />,
              ),
              getItem(t("logout"), "LOGOUT", <LogOut size={16} />),
            ]}
            multiple={false}
            selectable={false}
            onClick={(e) => {
              if (e.key === "LOGOUT") {
                dispatch(userLogoutAction());
                mutate(() => true, undefined, false).then((_) =>
                  navigate("/login"),
                );
              }
            }}
          />
        </div>
      </Sider>
    </Layout>
  );
}
