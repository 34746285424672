import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import { Users } from "../../../../../Types";
import moment from "moment";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";

export const CompanyUserListingColumns: (
  t: any,
  showCertificate: any,
  deleteCertificate: any,
) => (ColumnGroupType<Users> | ColumnType<Users>)[] = (
  t,
  showCertificate,
  deleteCertificate,
) => {
  return [
    {
      title: t("WithholdingTaxPeriodType"),
      dataIndex: "WithholdingTaxPeriod",
      key: "WithholdingTaxPeriod",
      render: (value, wtc) => {
        return wtc.WithholdingTaxPeriodType === "DATE"
          ? moment(value).format("DD/MM/YYYY")
          : wtc.WithholdingTaxPeriodType === "MONTH"
            ? moment(value).format("MMMM YYYY").toUpperCase()
            : wtc.WithholdingTaxPeriodType === "YEAR"
              ? moment(value).format("YYYY").toUpperCase()
              : null;
      },
    },

    {
      title: t("grossAmount"),
      dataIndex: "grossAmount",
      key: "grossAmount",
      render: (value) => Number(value).toFixed(3) + " " + t("TND"),
    },
    {
      title: t("withholdingAmount"),
      dataIndex: "withholdingAmount",
      key: "withholdingAmount",
      render: (value) => Number(value).toFixed(3) + " " + t("TND"),
    },
    {
      title: t("payableAmount"),
      dataIndex: "payableAmount",
      key: "payableAmount",
      render: (value) => Number(value).toFixed(3) + " " + t("TND"),
    },
    {
      title: t("beneficiaryIdentifier"),
      dataIndex: "beneficiaryIdentifier",
      key: "beneficiaryIdentifier",
    },
    {
      title: t("nomCommercialFr"),
      dataIndex: "beneficiaryName",
      key: "beneficiaryName",
    },
    {
      title: t("withholdingObject"),
      dataIndex: "withholdingObject",
      key: "withholdingObject",
    },
    {
      title: t("date_of_creations"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => moment(value).format("DD/MM/YYYY"),
    },

    {
      title: t("actions"),
      dataIndex: "withholdingTaxCertificate",
      key: "withholdingTaxCertificate",
      render: (value, record) => {
        return (
          <div className="w-full flex justify-center items-center gap-4">
            <EyeOutlined
              onClick={() => showCertificate(value)}
              className="cursor-pointer"
            />
            {!record.isPrinted ? (
              <DeleteOutlined
                onClick={() => deleteCertificate(value)}
                style={{ color: "red" }}
                className="cursor-pointer"
              />
            ) : null}
          </div>
        );
      },
    },
  ];
};
