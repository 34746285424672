import React, { useMemo } from "react";
import { Account, Transactions } from "../../../../../Types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";

function ExportData({ Account }: { Account: Account }) {
  const { t } = useTranslation();

  const tableHeader = useMemo(() => {
    const TransactionKeys: Array<keyof Transactions> = [
      "TransactionId",
      "amount",
      "type",
      "description",
      "createdAt",
      "updatedAt",
      "accountId",
      "UserID",
      "CompanyID",
      "AgenceID",
      "expenseId",
    ].map((key) => t(key));
    return TransactionKeys;
  }, [t]);

  const transactions = useMemo(() => {
    return Account.transactions;
  }, [Account]);

  const exportToFile = () => {
    const doc = new jsPDF();

    const tableData = transactions.map((row) =>
      Object.values(row).map((val) => {
        return typeof val !== "string" ? "-" : val;
      }),
    );

    const tableHeaders = tableHeader.map((c) => c);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,

      styles: {
        cellPadding: 0.5,
        fontSize: 8,
        overflow: "ellipsize",
        cellWidth: "wrap",
      },
      columnStyles: { text: { cellWidth: "auto" } },
    });

    doc.save("mrt-pdf-example.pdf");
  };

  const availablePeriods = {
    YESTERDAY: {
      label: t("YESTERDAY"),
      value: "YESTERDAY",
      startDate: moment().subtract(1, "day").startOf("day"),
      endDate: moment().subtract(1, "day").endOf("day"),
    },
    LAST_WEEK: {
      label: t("LAST_WEEK"),
      value: "LAST_WEEK",
      startDate: moment().subtract(1, "week").startOf("week"),
      endDate: moment().subtract(1, "week").endOf("week"),
    },
    LAST_MONTH: {
      label: t("LAST_MONTH"),
      value: "LAST_MONTH",
      startDate: moment().subtract(1, "month").startOf("month"),
      endDate: moment().subtract(1, "month").endOf("month"),
    },
    SINCE_START_OF_YEAR: {
      label: t("SINCE_START_OF_YEAR"),
      value: "SINCE_START_OF_YEAR",
      startDate: moment().startOf("year"),
      endDate: moment(),
    },
  };

  const items: MenuProps["items"] = useMemo(() => {
    return Object.values(availablePeriods).map(({ label }, index) => {
      return {
        key: index,
        label: (
          <span onClick={() => exportToFile()}>
            {`${t("transaction_history")} : ${label}`}
          </span>
        ),
      };
    });
  }, [availablePeriods, t]);

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Space>
        {t("EXPORT")}
        <DownOutlined />
      </Space>
    </Dropdown>
  );
}

export default ExportData;
