import {ColumnGroupType} from "antd/es/table";
import {ColumnType} from "antd/lib/table";
import {CarMaintenance} from "../../../../../../Types";
import moment from "moment/moment";

export const FleetMaintenanceColumns: (
  t: any,
) => (ColumnGroupType<CarMaintenance> | ColumnType<CarMaintenance>)[] = (t) => {
  return [
    {
      title: t("maintenance_date"),
      dataIndex: "maintenanceDate",
      key: "maintenanceDate",

      render: (date: Date) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: t("service_type"),
      dataIndex: "serviceType",
      key: "serviceType",
      width: 130,
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      width: 200,
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("cost"),
      dataIndex: "cost",
      key: "cost",

      render: (cost) => `${cost.toFixed(2)} ${t("TND")}`,
    },
    {
      title: t("bill_number"),
      dataIndex: "billNumber",
      key: "billNumber",
    },
    {
      title: t("kilometrage"),
      dataIndex: "kilometrage",
      key: "kilometrage",
      align: "right",
      render: (kilometrage) => `${kilometrage} ${t("KM")}`,
    },
    {
      title: t("NEXT_MAINTENANCE"),
      dataIndex: "nextKilometrage",
      key: "nextKilometrage",
      render: (kilometrage) => `${kilometrage || " - "} ${t("KM")}`,
    },
  ];
};
