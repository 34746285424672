import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { t } from "i18next";
import { RentContracts } from "../Types";
import moment from "moment";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function uniqueObjectsByProperty<T>(arr: T[], property: keyof T): T[] {
  const uniqueValues: T[] = [];
  const seenValues: Set<any> = new Set();

  for (const item of arr) {
    const value = item[property];
    if (!seenValues.has(value)) {
      seenValues.add(value);
      uniqueValues.push(item);
    }
  }

  return uniqueValues;
}

export const enumsToSelectOptions = (enums: any) => {
  return (
    (enums &&
      enums.map((enu: string[]) => {
        return { value: enu, label: t(enu) };
      })) ||
    []
  );
};

export const fileUrlHandler = (target: string | null) => {
  let ENDPOINT_PREFIX = process.env.REACT_APP_API_ENDPOINT_PREFIX;

  setInterval(() => {
    ENDPOINT_PREFIX = process.env.REACT_APP_API_ENDPOINT_PREFIX;
  }, 1000);

  return (
    (target &&
      (process.env.REACT_APP_API_ENDPOINT_BASE_URL as string) +
        (ENDPOINT_PREFIX as string) +
        "/media/" +
        target) ||
    ""
  );
};

export const nationality = [
  "AFGHANE",
  "ALBANAISE",
  "ALGERIENNE",
  "ALLEMANDE",
  "AMERICAINE",
  "ANGLAISE",
  "ANGOLAISE",
  "ARGENTINE",
  "ARMENIENNE",
  "AUSTRALIENNE",
  "AUTRICHIENNE",
  "AZERBAIDJANAISE",
  "BANGLADAISE",
  "BELGE",
  "BENINOISE",
  "BIRMANE",
  "BOLIVIENNE",
  "BOSNIAQUE",
  "BRESILIENNE",
  "BULGAIRE",
  "BURKINABE",
  "CAMBODGIENNE",
  "CAMEROUNAISE",
  "CANADIENNE",
  "CAPVERDIENNE",
  "CHILIENNE",
  "CHINOISE",
  "COLOMBIENNE",
  "CONGOLAISE",
  "COSTARICAINE",
  "CROATE",
  "CUBAINE",
  "DANOISE",
  "EGYPTIENNE",
  "EMIRATIE",
  "ESPAGNOLE",
  "ESTONIENNE",
  "ETHIOPIENNE",
  "FINLANDAISE",
  "FRANCAISE",
  "GABONAISE",
  "GHANEENNE",
  "GRECQUE",
  "GUINEENNE",
  "HAITIENNE",
  "HOLLANDAISE",
  "HONGROISE",
  "INDIENNE",
  "INDONESIENNE",
  "IRAKIENNE",
  "IRANIENNE",
  "IRLANDAISE",
  "ISLANDAISE",
  "ITALIENNE",
  "IVOIRIENNE",
  "JAMAIQUAINE",
  "JAPONAISE",
  "JORDANIENNE",
  "KAZAKHSTANAISE",
  "KENYANE",
  "KOWEITIENNE",
  "LIBANAISE",
  "LIBERIENNE",
  "LIBYENNE",
  "LITUANIENNE",
  "LUXEMBOURGEOISE",
  "MALAISIENNE",
  "MALIENNE",
  "MAROCAINE",
  "MEXICAINE",
  "MONEGASQUE",
  "MONGOLE",
  "MOZAMBICAINE",
  "NEPALAISE",
  "NIGERIENNE",
  "NIGERIANE",
  "NORVEGIENNE",
  "NEOZELANDAISE",
  "PAKISTANAISE",
  "PALESTINIENNE",
  "PANAMEENNE",
  "PERUVIENNE",
  "PHILIPPINE",
  "POLONAISE",
  "PORTUGAISE",
  "QATARI",
  "ROUMAINE",
  "RUSSE",
  "SENEGALAISE",
  "SERBE",
  "SINGAPOURIENNE",
  "SLOVAQUE",
  "SLOVENE",
  "SOMALIENNE",
  "SRILANKAISE",
  "SUEDOISE",
  "SUISSE",
  "SYRIENNE",
  "TCHADIENNE",
  "TCHEQUE",
  "THAILANDAISE",
  "TUNISIENNE",
  "TURQUE",
  "UKRAINIENNE",
  "URUGUAYENNE",
  "VENEZUELIENNE",
  "VIETNAMIENNE",
  "YEMENITE",
  "ZIMBABWEENNE",
];

export function filterContractsForCurrentMonth(contracts: RentContracts[]) {
  const currentMonth = moment().month();
  const currentYear = moment().year();

  return contracts.filter((contract) => {
    const createdAt = moment(contract.createdAt);
    return (
      createdAt.month() === currentMonth && createdAt.year() === currentYear
    );
  });
}

export function formatBytes(bytes: any, decimals: any) {
  if (bytes == 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function calculateRentalDays(
  startDate: string | Date,
  endDate: string | Date,
) {
  const start = moment(startDate);
  const end = moment(endDate);

  const totalHours = end.diff(start, "hours");
  const fullDays = Math.floor(totalHours / 24);
  const remainingHours = totalHours % 24;

  return remainingHours > 2 ? fullDays + 1 : fullDays;
}
