import React from "react";
import { Field, Form as FormikForm, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Button, Form, Input, Select } from "antd";
import { useEnumsData } from "../../../../../../Hooks";
import { useTranslation } from "react-i18next";
import { RentInitialValues } from "../index";
import { v4 as uuidv4 } from "uuid";

const initialValues = {
  Type: "",
  Amount: "",
  TRN: "",
  Bank: "",
  Deposit: "",
};

const validationSchema = Yup.object().shape({
  Type: Yup.string().required("Type is required"),
  Amount: Yup.string().required("Amount is required"),
  TRN: Yup.string().nullable(),
  Bank: Yup.string().nullable(),
  Deposit: Yup.string().required("Deposit is required"),
});

const { Option } = Select;

const AntdSelect = ({ field, form, ...props }: any) => (
  <Select
    {...field}
    {...props}
    onChange={(value) => form.setFieldValue(field.name, value)}
  >
    {props.children}
  </Select>
);

const AntdInput = ({ field, form, ...props }: any) => (
  <Input
    {...field}
    {...props}
    onChange={(e) => form.setFieldValue(field.name, e.target.value)}
  />
);

const banks = {
  STB: "STB",
  BNA: "BNA",
  BH: "BH",
  BFPME: "BFPME",
  BTS: "BTS",
  BTE: "BTE",
  BTL: "BTL",
  TSB: "TSB",
  Zitouna: "Zitouna",
  "Al Baraka": "Al Baraka",
  Wifak: "Wifak",
  Amen: "Amen",
  "Attijari Bank": "Attijari Bank",
  ATB: "ATB",
  ABC: "ABC",
  BIAT: "BIAT",
  BT: "BT",
  BTK: "BTK",
  BFT: "BFT",
  Citi: "Citi",
  QNB: "QNB",
  UBCI: "UBCI",
  UIB: "UIB",
};

function CautionAddModalContent({
  caution,
  setFieldValue,
  closeModalHandler,
}: {
  caution: RentInitialValues["caution"];
  setFieldValue: FormikHelpers<RentInitialValues>["setFieldValue"];
  closeModalHandler: any;
}) {
  const { t } = useTranslation();
  const { enums, isLoading } = useEnumsData();

  return (
    <div className="p-4 flex flex-col">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: any, { setSubmitting }) => {
          setFieldValue(
            "caution",
            (caution || []).concat({
              RentContractCautionID: uuidv4(),
              ...values,
            }),
          );
          setSubmitting(false);
          closeModalHandler();
        }}
      >
        {({ submitForm }) => (
          <FormikForm>
            <Form layout="vertical">
              <Form.Item label={t("Type")}>
                <Field name="Type" component={AntdSelect} loading={isLoading}>
                  {enums &&
                    Object.entries(enums["paymentPasserelle"]).map(
                      ([_, value]) => (
                        <Option key={value} value={value}>
                          {t(value)}
                        </Option>
                      ),
                    )}
                </Field>
              </Form.Item>
              <Form.Item label="Amount">
                <Field name="Amount" component={AntdInput} />
              </Form.Item>
              <Form.Item label={t("TRN")}>
                <Field name="TRN" component={AntdInput} />
              </Form.Item>
              <Form.Item label={t("Bank")}>
                <Field
                  name="Bank"
                  component={AntdSelect}
                  loading={isLoading}
                  defaltValue={""}
                >
                  {banks &&
                    Object.entries(banks).map(([_, value]) => (
                      <Option key={value} value={value}>
                        {t(value)}
                      </Option>
                    ))}
                </Field>
              </Form.Item>

              <Form.Item label={t("Deposit")}>
                <Field name="Deposit" component={AntdInput} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  className=" w-full  !h-10 !bg-[#333333] !hover:bg-[#585757] !text-white"
                  onClick={submitForm}
                >
                  {t("add")}
                </Button>
              </Form.Item>
            </Form>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
}

export default CautionAddModalContent;
