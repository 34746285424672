import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import { Invoices } from "../../../../../Types";
import moment from "moment";
import CopyToClipboard from "../../../../UI/CopyToClipboard";

export const CompanyListingTableColumns: (
  t: any,
  selectInvoice: any,
) => (ColumnGroupType<Invoices> | ColumnType<Invoices>)[] = (
  t,
  selectInvoice,
) => {
  return [
    {
      title: t("date_of_creations"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => moment(value).format("DD/MM/YYYY HH:MM"),
    },
    {
      title: t("copy_id"),
      dataIndex: "invoiceID",
      key: "invoiceID",
      render: (value: any) => (
        <div className="flex justify-center items-center">
          <CopyToClipboard input={value} showInput={false} showIcon={true} />
        </div>
      ),
    },
    {
      title: t("reference"),
      dataIndex: "reference",
      key: "reference",
      render: (value: any) => (
        <div className="flex justify-center items-center">
          <CopyToClipboard input={value} showIcon={false} />{" "}
        </div>
      ),
    },
    {
      title: t("amount"),
      dataIndex: "amount",
      key: "amount",
      render: (value: any) => `${value} ${t("TND")}`,
    },

    {
      title: t("validUntil"),
      dataIndex: "validUntil",
      key: "validUntil",
      render: (value: any) => moment(value).format("DD/MM/YYYY"),
    },
    {
      title: t("client_name"),
      dataIndex: "client",
      key: "rsLatin",
      render: (value: any) =>
        value?.fullName?.toUpperCase() || value?.denomination?.toUpperCase(),
    },
    {
      title: t("matricule_fiscal"),
      dataIndex: "client",
      key: "VAT",
      render: (value: any) => value.vatNumber || value.idNumber,
    },
    {
      title: t("comment"),
      dataIndex: "note",
      key: "note",
    },
    {
      title: t("created_by"),
      dataIndex: "createdBy",
      key: "createdBy",
      render: (value: any) => `${value.name} ${value.lastName}`,
    },
    {
      title: t("agenceName"),
      dataIndex: "agence",
      key: "agenceName",
      render: (value: any) => {
        return value ? value.agenceName : "-";
      },
    },
  ];
};
